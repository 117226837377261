import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";

const RegisterAffiliateFilter = () => {
  const currencyOptions = [{ id: 1, value: "AED" }];
  const [affiliateFilter, setAffiliateFilter]=useState(false);
  const renderFloatingLabel = (label, controlId, name) => (
    <FloatingLabel controlId={controlId} label={label}>
      <Form.Control type="text" placeholder="" autoComplete="off" name={name} />
    </FloatingLabel>
  );

  const moreFilter=()=>{
    setAffiliateFilter (!affiliateFilter)
  }

  return (
    <div className="voucher-filter">
      <div className="filter-section">
        {renderFloatingLabel("User ID", "userId", "userId")}
        {renderFloatingLabel("User Name", "userName", "userName")}
        {renderFloatingLabel("Email", "email", "email")}
        {renderFloatingLabel("First Name", "firstName", "firstName")}
        {affiliateFilter && renderFloatingLabel("Last Name", "lastName", "lastName")}
        {affiliateFilter && renderFloatingLabel("Mobile", "mobile", "mobile")}
        {affiliateFilter && <SelectInput defaultOption="Country" listArray={currencyOptions} />}
        {affiliateFilter && renderFloatingLabel("City", "city", "city")}
        {affiliateFilter && renderFloatingLabel("Address", "address", "address")}
        {affiliateFilter && renderFloatingLabel("Pin", "pin", "pin")}
        {affiliateFilter && <SelectInput defaultOption="Currency" listArray={currencyOptions} />}
        {affiliateFilter && <SelectInput
          defaultOption="Affiliate Status"
          listArray={currencyOptions}
        />}
        {affiliateFilter && renderFloatingLabel("From Balance", "fromBalance", "fromBalance")}
        { affiliateFilter && renderFloatingLabel("To Balance", "toBalance", "toBalance")}
        {affiliateFilter && <SelectInput
          defaultOption="Registration Status"
          listArray={currencyOptions}
        />}

        <div className="filter-section register-affiliate-btn">
          <ButtonWrapper text="Search" clValue="search-filter-btn" />
          <ButtonWrapper text="Clear All" clValue="clear-btn" />
          <ButtonWrapper text="More filter" clValue="clear-btn" handleClick={moreFilter}/>
        </div>
      </div>
    </div>
  );
};

export default RegisterAffiliateFilter;
