import { FloatingLabel, Form, Modal, Tab, Tabs } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SwitchBox from "../global/switchBox";
import SelectInput from "../global/selectInput";
import DatePickerInput from "../global/datePickerInput";
import GlobalEditor from "../global/globalEditor";
import { addUpdateNotification, templatesandusers } from "../../utils/Apis";
import toast from "react-hot-toast";
import { useMyContect } from "../../context/myContext";
import { formatDateTime2 } from "../../utils/dateTimeFromater";

const SendNotification = ({ show, handleClose, setRefreshNoti, notiData }) => {
  const [toggle, settoggle] = useState("");
  const [toggleSwitch, setToggleSwitch] = useState(true);

  useEffect(() => {
    if (notiData?.type === "Manual") {
      settoggle("Manually");
    } else {
      settoggle("Automaticaly");
    }
  }, [notiData?.type, show]);

  const handleToggle = (value) => {
    settoggle(value);
  };
  const editorRef = useRef(null);
  const [toggleFlag, setToggleFlag] = useState(false);
  const radioHandleToggle = (e) => {
    setToggleFlag(!toggleFlag);
    setToggleSwitch(!toggleSwitch);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup  add-popup-content ${
        toggle === "Manually" ? "Manually-popup " : ""
      }`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Send Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <Tabs
            activeKey={toggle}
            id={toggle}
            className="mb-3"
            onSelect={(key) => handleToggle(key)}
          >
            <Tab eventKey="Automaticaly" title="Automaticaly">
              <AutomaticalyTab
                handleToggle={radioHandleToggle}
                toggleFlag={toggleSwitch}
                handleClose={handleClose}
                setRefreshNoti={setRefreshNoti}
                notiData={notiData}
              />
            </Tab>
            <Tab eventKey="Manually" title="Manually">
              <ManuallyTab
                editorRef={editorRef}
                handleToggle={radioHandleToggle}
                toggleFlag={toggleFlag}
                handleClose={handleClose}
                setRefreshNoti={setRefreshNoti}
                notiData={notiData}
              />
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SendNotification;

const AutomaticalyTab = ({
  handleToggle,
  toggleFlag,
  handleClose,
  setRefreshNoti,
  notiData,
}) => {
  const { user } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    id: "",
    type: "Automatic",
    userGroup: "",
    userId: "",
    creatorName: user?.firstName,
    creatorId: user?.id,
    status: "",
  });
  const handleChange = (e) => {
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    console.log("fields==s==ss=====>>", fields);

    await addUpdateNotification({
      body: fields,
      notificationId: fields?.id || "",
    })
      .then((result) => {
        if (result?.data?.status === 200) {
          setRefreshNoti(Math.random());
          toast.success(result?.data?.message);
          handleClose();
        } else {
          toast.error(result?.data?.message || result?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (notiData) {
      setFields(notiData);
      setFields((prevState) => ({
        ...prevState,
        dateFrom: formatDateTime2(notiData?.dateFrom),
        dateEnd: formatDateTime2(notiData?.dateEnd),
      }));
    }
  }, [notiData]);

  const [tempUsers, setTempUsers] = useState("");

  useLayoutEffect(() => {
    templatesandusers().then((result) => {
      setTempUsers(result?.data?.data);
    });
  }, []);

  return (
    <div className="automaticaly-content">
      <div className="auto-switch">
        <SwitchBox
          label={"All Users"}
          handleToggle={handleToggle}
          toggleFlag={!toggleFlag}
        />
        <SwitchBox
          label={"Filter By"}
          handleToggle={handleToggle}
          toggleFlag={toggleFlag}
        />
      </div>
      {toggleFlag && (
        <div className="auto-options auto-manual">
          <SelectInput
            defaultOption="User Groups"
            name="userGroup"
            value={fields?.userGroup}
            handleSelectOption={handleChange}
            listArray={tempUsers?.usergroups}
          />
          <FloatingLabel controlId="floatingInput" label="User ID">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="userId"
              value={fields?.userId}
              onChange={handleChange}
            />
          </FloatingLabel>
        </div>
      )}
      <div className="auto-options1 automaticaly-options">
        <SelectInput
          defaultOption={"Template Name"}
          name="templateId"
          value={fields?.templateId}
          handleSelectOption={handleChange}
          listArray={tempUsers?.templates}
        />
        <div className="wd-date">
          <DatePickerInput
            label="Date From:"
            date={fields?.dateFrom}
            setDate={(item) => {
              setFields((prevState) => ({
                ...prevState,
                dateFrom: item,
              }));
            }}
          />
        </div>
        <div className="wd-date">
          <DatePickerInput
            label="End Date"
            date={fields?.dateEnd}
            setDate={(item) => {
              setFields((prevState) => ({
                ...prevState,
                dateEnd: item,
              }));
            }}
          />
        </div>
      </div>
      <div className="auto-btn">
        <ButtonWrapper
          loading={loading}
          handleClick={handleSubmit}
          text="Send"
        />
      </div>
    </div>
  );
};

const ManuallyTab = ({
  editorRef,
  handleToggle,
  toggleFlag,
  handleClose,
  setRefreshNoti,
  notiData,
}) => {
  const { user } = useMyContect();
  const listArray = [{ text: "test", value: 1 }];
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    id: "",
    type: "Manual",
    userGroup: "",
    userId: "",
    message: "",
    title: "",
    creatorName: user?.firstName,
    creatorId: user?.id,
    status: "",
  });
  const handleChange = (e) => {
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    await addUpdateNotification({
      body: fields,
      notificationId: fields?.id || "",
    })
      .then((result) => {
        console.log("result==========>>>>", result);
        if (result?.data?.status === 200) {
          setRefreshNoti(Math.random());
          toast.success(result?.data?.message);
          handleClose();
        } else {
          toast.error(result?.data?.message || result?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (notiData) {
      setFields(notiData);
      setFields((prevState) => ({
        ...prevState,
        dateFrom: formatDateTime2(notiData?.dateFrom),
        dateEnd: formatDateTime2(notiData?.dateEnd),
      }));
    }
  }, [notiData]);

  return (
    <div className="automaticaly-content manual-content">
      <div className="auto-switch">
        <SwitchBox
          label={"All Users"}
          handleToggle={handleToggle}
          toggleFlag={!toggleFlag}
        />
        <SwitchBox
          label={"Filter By"}
          handleToggle={handleToggle}
          toggleFlag={toggleFlag}
        />
      </div>
      {toggleFlag && (
        <div className="auto-options auto-manual">
          <SelectInput
            defaultOption="User Groups"
            name="userGroup"
            value={fields?.userGroup}
            handleSelectOption={handleChange}
            listArray={listArray}
          />
          <FloatingLabel controlId="floatingInput" label="User ID">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="userId"
              value={fields?.userId}
              onChange={handleChange}
            />
          </FloatingLabel>
        </div>
      )}
      <div className="auto-manual-date auto-manual">
        <div className="date-picker-manual">
          <DatePickerInput
            label="Date From:"
            date={fields?.dateFrom}
            setDate={(item) => {
              setFields((prevState) => ({
                ...prevState,
                dateFrom: item,
              }));
            }}
          />
        </div>
        <div className="date-picker-manual">
          <DatePickerInput
            label="End Date"
            date={fields?.dateEnd}
            setDate={(item) => {
              setFields((prevState) => ({
                ...prevState,
                dateEnd: item,
              }));
            }}
          />
        </div>
      </div>
      <div className="auto-options  auto-manual">
        <FloatingLabel controlId="floatingInput" label="Notification Title">
          <Form.Control
            type="text"
            placeholder="name@example.com"
            name="title"
            value={fields?.title}
            onChange={handleChange}
          />
        </FloatingLabel>
      </div>
      <div className="editor-section">
        <GlobalEditor
          editorRef={editorRef}
          textValue={fields?.message}
          handleEditorChange={(item) => {
            setFields((prevState) => ({
              ...prevState,
              message: item,
            }));
          }}
        />
      </div>
      <div className="auto-btn">
        <ButtonWrapper
          loading={loading}
          handleClick={handleSubmit}
          text="Send"
        />
      </div>
    </div>
  );
};
