import { useState } from "react";
import GlobalTable from '../../global/globalTable';

const PerformanceStatisticsTable = () => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "Year" },
      { field: "Month" },
      { field: "Total Deposit" },
      { field: "Total Withdraw" },
      { field: "System Diff" },
      { field: "Total Bet" },
      { field: "Total Win" },
      { field: "Profit" },
      { field: "Unique Players" },
      { field: "Authorized Players" },
      { field: "Unique Players" },
      { field: "Registered Player Count" },
      { field: "Currency" },
    ]);
    return (
      <div className="deposit-table">
      <h2>Performance Report</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default PerformanceStatisticsTable