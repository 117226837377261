import { useState } from "react";
import Layout from "../../layout/layout.jsx";
import DatePicker from "../../global/datePicker.jsx";
import Filter from "./filter.jsx";
import PaymentTable from "./paymentTable.jsx";
import HorizontalTabs from "../../global/horizontalTab.jsx";

const tabs = ["Today", "Yesterday", "Past Week", "Current Month", "Last Month"];

const PaymentStatistics = () => {
  const [activeTab, setActiveTab] = useState("Today");
  const handleTabs = (value) => {
    setActiveTab(value);
  };
  return (
    <Layout title="PAYMENT STATISTICS">
      <div className="notification-page users-page cashdesk-page report-pages performance-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            <div className="date-filter">
            <DatePicker />
            </div>
          </div>
        </div>
        <div className="cashdesk-section">
          <Filter />
          {/* <CashdeskTable /> */}
          <PaymentTable />
        </div>
      </div>
    </Layout>
  );
};
export default PaymentStatistics;
