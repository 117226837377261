import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

const StatisticsTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "ID",width:"100px" },
    { field: "Game Vendor",width:"150px"},
    { field: "Bet Amount" ,width:"150px", sortable:true, sort: 'desc'},
    { field: "Win Amount",width:"150px", sortable:true, sort: 'desc' },
    { field: "Profit" ,width:"100px", sortable:true, sort: 'desc'},
    { field: "Bet Count",width:"150px", sortable:true, sort: 'desc' },
    { field: "Win Count",width:"150px",sortable:true, sort: 'desc' },
    { field: "User Count",width:"150px", sortable:true, sort: 'desc' },
    { field: "Probability" ,width:"150px", sortable:true, sort: 'desc'},
  ]);

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Vendor Statistics</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption={"10"} />
          <TableColumns tableData={columnDefs}/> 
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default StatisticsTable;
