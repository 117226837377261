import React, { useState } from "react";

import { Form } from "react-bootstrap";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VerifyInfoPopup from "../profile/verifyInfoPopup";
import Layout from "../layout/layout";
import SelectInput from "../global/selectInput";
import ToggleSwitch from "../global/toggleSwitch";
import ButtonWrapper from "../global/button";
import { timeZoneData } from "../../utils/timeZoneData";
import { SiteSchema } from "../validationSchema/authSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { createSiteContentData, updateSiteContentData } from "../../utils/Apis";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMyContect } from "../../context/myContext";
import { useNavigate } from "react-router-dom";
let payload = {};
// import { useMyContect } from "./context/myContext";
const AddSiteContentBox = () => {
  const { updateSiteData, setupdateSiteData } = useMyContect();
  const navigate = useNavigate();
  // console.log("updated data====>", updateSiteData.domainName);
  payload = {
    domainName: updateSiteData?.domainName,
    userName: updateSiteData?.userName,
    password: updateSiteData?.password,
    creatorName: updateSiteData?.creatorName,
    phoneNumber: updateSiteData?.phoneNumber,
    email: updateSiteData?.email,
    dbName: updateSiteData?.dbName,
    dbHost: updateSiteData?.dbHost,
    dbUserName: updateSiteData?.dbUserName,
    dbPassword: updateSiteData?.dbPassword,
    dbPort: updateSiteData?.dbPort,
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(SiteSchema),
    defaultValues: payload,
  });

  const [siteContent, setSiteContent] = useState({
    trader: updateSiteData?.trader,
    muteNotification: updateSiteData?.muteNotification,
    timeZone: updateSiteData?.timeZone,
    isSiteActive: false,
    phoneNumber: updateSiteData?.phoneNumber,
  });
  const [flag, setFlag] = useState("");
  const handleVerifyEmail = (value) => {
    if (flag === "") {
      setFlag(value);
    } else {
      setFlag("");
    }
  };
  const createSiteContent = async (value) => {
    const payloadData = { ...value, ...siteContent };
    console.log("updateSiteData ----->", updateSiteData);

    if (updateSiteData) {
      const response = await updateSiteContentData({
        ...payloadData,
        id: updateSiteData.id,
      });
      console.log("response----> data", response);
      if (response?.status === 200) {
        reset();
        setSiteContent({});
        setupdateSiteData(null);
        toast.success("Upadated data successfully");
        navigate("/addSiteContent");
      }
    } else {
      const response = await createSiteContentData(payloadData);
      console.log("response", response);
      if (response?.data?.status === 200) {
        setSiteContent({});
        reset();
        toast.success("New website create successfully");
      }
    }
  };

  const handlePhoneChange = (e) => {
    console.log("event Time X", e);
    setValue("phoneNumber", e);
    setError("phoneNumber", "customError");
    setSiteContent({ ...siteContent, phoneNumber: e });
  };
  const handleToggle = (field) => {
    setSiteContent({ ...siteContent, [field]: !siteContent[field] });
  };

  const handleSelectOption = (event) => {
    console.log("event Time X", event.target.value);
    setSiteContent({ ...siteContent, timeZone: event.target.value });
  };

  return (
    <Layout title='Add ADMIN'>
      <div className='profile-page admin-profile-page'>
        <div className='profile-section admin-profile'>
          <p className='profile-title'>Information</p>
          <Form
            className='profile-list admin-profile-list'
            onSubmit={handleSubmit(createSiteContent)}>
            <div>
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Domain</Form.Label>
                <Form.Control
                  defaultValue={payload?.domainName}
                  className='val'
                  type='text'
                  name='domainName'
                  placeholder='Enter Domain'
                  {...register("domainName")}
                />
              </Form.Group>
              {errors?.domainName ? (
                <p className='error-text text-danger'>
                  {errors.domainName.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>User Name</Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='userName'
                  placeholder='Enter User Name '
                  {...register("userName")}
                />
              </Form.Group>
              {errors?.userName ? (
                <p className='error-text text-danger'>
                  {errors.userName.message}
                </p>
              ) : (
                ""
              )}

              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Login Password</Form.Label>
                <Form.Control
                  className='val'
                  type='password'
                  name='password'
                  placeholder='Enter Login Password'
                  {...register("password")}
                />
              </Form.Group>
              {errors?.password ? (
                <p className='error-text text-danger'>
                  {errors.password.message}
                </p>
              ) : (
                ""
              )}

              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Creator Name</Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='creatorName'
                  placeholder='Enter Creator Name '
                  {...register("creatorName")}
                />
              </Form.Group>
              {errors?.creatorName ? (
                <p className='error-text text-danger'>
                  {errors.creatorName.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Email</Form.Label>
                <Form.Control
                  className='val'
                  type='email'
                  name='email'
                  placeholder='Enter Email Name '
                  {...register("email")}
                />
              </Form.Group>
              {errors?.email ? (
                <p className='error-text text-danger'>{errors.email.message}</p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Mobile</Form.Label>
                <div className="phone_outer">
                <div className='Mobile_Number'>
                  <PhoneInput
                    country={"us"}
                    onChange={handlePhoneChange}
                    inputProps={{
                      name: "phoneNumber",
                    }}
                    // inputProps={{
                    //   name: "countryCode",
                    //   "data-test": "Address-phone.countryCode",
                    // }}
                    value={siteContent?.phoneNumber}
                    className={errors.phoneNumber ? "error-field" : ""}
                  />
                </div>
                </div>
              </Form.Group>
              {errors?.phoneNumber ? (
                <p className='error-text text-danger'>
                  {errors.phoneNumber.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Trader</Form.Label>
                <div className='profile-mute-section'>
                  <ToggleSwitch
                    toggleFlag={siteContent["trader"]}
                    handleToggle={() => handleToggle("trader")}
                  />
                </div>
              </Form.Group>
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Mute Notification</Form.Label>
                <div className='profile-mute-section'>
                  <ToggleSwitch
                    toggleFlag={siteContent["muteNotification"]}
                    handleToggle={() => handleToggle("muteNotification")}
                  />
                  <span
                    className={`audio-${
                      siteContent["muteNotification"] ? "mute" : "on"
                    }`}>
                    {siteContent["muteNotification"] ? (
                      <VolumeOffIcon />
                    ) : (
                      <VolumeUpIcon />
                    )}
                  </span>
                </div>
              </Form.Group>
              <Form.Group className='abc flex-nowrap'>
                <Form.Label className='desc mb-0'>Time Zone</Form.Label>
                <SelectInput
                  listArray={timeZoneData}
                  selectedValue={siteContent.timeZone}
                  handleSelectOption={handleSelectOption}
                />
              </Form.Group>
            </div>
            <div>
              {/* Data base detail  add form  */}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Database Name </Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='dbName'
                  placeholder='Enter Database Name '
                  {...register("dbName")}
                />
              </Form.Group>
              {errors?.dbName ? (
                <p className='error-text text-danger'>
                  {errors.dbName.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Database Host </Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='dbHost'
                  placeholder='Enter Database Host '
                  {...register("dbHost")}
                />
              </Form.Group>
              {errors?.dbHost ? (
                <p className='error-text text-danger'>
                  {errors.dbHost.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>
                  Database User Name{" "}
                </Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='dbUserName'
                  placeholder='Enter Database User Name '
                  {...register("dbUserName")}
                />
              </Form.Group>
              {errors?.dbUserName ? (
                <p className='error-text text-danger'>
                  {errors.dbUserName.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>
                  Database Password{" "}
                </Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='dbPassword'
                  placeholder='Enter Database Password '
                  {...register("dbPassword")}
                />
              </Form.Group>
              {errors?.dbPassword ? (
                <p className='error-text text-danger'>
                  {errors.dbPassword.message}
                </p>
              ) : (
                ""
              )}
              <Form.Group className='abc'>
                <Form.Label className='desc mb-0'>Database Port </Form.Label>
                <Form.Control
                  className='val'
                  type='text'
                  name='dbPort'
                  placeholder='Enter Database Port '
                  {...register("dbPort")}
                />
              </Form.Group>
              {errors?.dbPort ? (
                <p className='error-text text-danger'>
                  {errors.dbPort.message}
                </p>
              ) : (
                ""
              )}
              <div className='auto-btn filter-section justify-content-start'>
                {updateSiteData ? (
                  <ButtonWrapper text='Update' type='submit' />
                ) : (
                  <ButtonWrapper text='Submit' type='submit' />
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
      <VerifyInfoPopup
        show={flag === "ENABLE"}
        handleClose={handleVerifyEmail}
      />
      {/* <VerifyPopup show={verifyPopup} handleClose={popupClose} /> */}
    </Layout>
  );
};

export default AddSiteContentBox;

// const renderEmailVerify = () => (
//   <div className="profile-verification">
//     <div className="profile-verification-data">
//       <p>Email verification code is sent to your email address</p>
//       <div className="filter-section">
//         <input placeholder="Code" type="number" />
//         <Button>Verify</Button>
//       </div>
//     </div>
//   </div>
// );

// const renderEditType = () => (
//   <div className="profile-verification profile-verification_authorization">
//     <div className="profile-edit ">
//       <span>Type</span>
//       <SelectInput defaultOption="Select Channel" />
//     </div>
//     <div className="profile-edit profile-edit_bottom">
//       <Button className="btn-red">Cancel</Button>
//       <Button className="btn-green">Change</Button>
//     </div>
//   </div>
// );
