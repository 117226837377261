import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../global/globalTable";

const GameStats = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "Game Vendor",width:"120px" },
    { field: "Bet",width:"100px"  },
    { field: "Win",width:"100px"  },
    { field: "Profit",width:"100px"  },
    { field: "Bet Count",width:"120px"  },
    { field: "Win count",width:"120px"  },
    { field: "User Count",width:"120px"  },
  ]);

  return (
    <div className="deposit-table ">
      <h2>Game Stats</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
export default GameStats;
