import { useState } from "react";
import GlobalTable from "../global/globalTable";
import Layout from "../layout/layout";
import RegisterAffiliateFilter from "./registerAffiliateFilter";
import "./affiliate.css";
import TableColumns from "../global/tableColumns";

const RegisterAffiliate = () => {
  return (
    <Layout title="REGISTER AFFILIATES">
      <div className="affiliate-list-page media-template-page register-affiliate-page users-page">
        <RegisterAffiliateFilter />
        <RegisterAffiliateTable />
      </div>
    </Layout>
  );
};

export default RegisterAffiliate;

const RegisterAffiliateTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "Brand", width:"150px"},
    { field: "User ID", width:"150px",  sortable: true,
    sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "User Name", width:"150px" },
    { field: "Pin",width:"150px",},
    { field: "Balance",width:"150px",  sortable: true,
    sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "Registration Date",width:"200px",  sortable: true,
    sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "Register as Affiliate",width:"150px" },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Register Affiliates</h6>
        <div className="right-area">
          <TableColumns tableData={columnDefs}/>
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
