import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";

const Filter = ({ activeTab }) => {
  const currencyOptions = [{ id: 1, value: "AED" }];

  const renderInput = (label, name, disabled = false) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type="text"
        placeholder="name@example.com"
        autoComplete="off"
        name={name}
        disabled={disabled}
      />
    </FloatingLabel>
  );

  return (
    <div
      className={`datepicker filter-Tab  ${
        activeTab === "Notifications" ? "" : "active-tab-noti"
      }`}
    >
      <div className="filter-section filter-pd mb-0 ">
        <SelectInput defaultOption="Type" listArray={currencyOptions} />
        {renderInput("User ID", "userId")}
        <SelectInput defaultOption="User Group" listArray={currencyOptions} />
        {renderInput("Affiliate ID", "affiliateID")}
        <SelectInput
          defaultOption="Default Currency"
          listArray={currencyOptions}
        />
        {renderInput("Choose Currency", "chooseCurrency", true)}
        <ButtonWrapper text="filter" />
      </div>
    </div>
  );
};

export default Filter;
