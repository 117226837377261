import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import TableColumns from "../../global/tableColumns";

const BonusTemplateTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Name",width:"150px", },
    { field: "Local Amount Accrual",width:"200px", },
    { field: "Brand Amount Accrual" ,width:"200px",},
    { field: "System Amount Accrual",width:"200px", },
    { field: "Local Amount Cancel",width:"200px", },
    { field: "Brand Amount Cancel",width:"200px", },
    { field: "System Amount Cancel",width:"150px", },
    { field: "Accrual Count",width:"150px", },
    { field: "Cancel Count",width:"150px", },
  ]);

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Bonus Template Report</h6>
        <div className="right-area">
          <ExportExcel />
          <TableColumns tableData={columnDefs}/>
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default BonusTemplateTable;
