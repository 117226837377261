import { FloatingLabel, Form } from "react-bootstrap";
import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";

const DepositFilter = () => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <div className="voucher-filter">
      <div className="filter-section">
        <FloatingLabel controlId="floatingInput" label="From Deposit">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="To Deposit">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="User ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <SelectInput defaultOption={"KYC Status"} listArray={Currencyoptions} />
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </div>
  );
};
export default DepositFilter;
