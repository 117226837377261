const HorizontalTabs = ({ tabs, activeTab, handleTabs }) => {
  return (
    <ul>
      {tabs?.map((tab, index) => (
        <li
          key={index}
          onClick={() => handleTabs(tab)}
          className={`${activeTab === tab ? "active-tab" : ""}`}
          role="presentation"
        >
          {tab}
        </li>
      ))}
    </ul>
  );
};

export default HorizontalTabs;
