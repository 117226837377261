import { Button , Spinner} from "react-bootstrap";
import "./global.css";
import { ClearAll } from "@mui/icons-material";

const ButtonWrapper = ({ text, loading, handleClick, clValue, icon, svgIcon, type }) => {
  return (
    <Button
      className={`global-btn ${clValue} pd-x`}
      onClick={handleClick}
      type={type}
      disabled={loading}
    >
      {icon && icon} {text === "Clear All" && <ClearAll />} {svgIcon && svgIcon}{" "}
      {loading? <Spinner animation="border" />: text}
    </Button>
  );
};
export default ButtonWrapper;
