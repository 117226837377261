import { Publish } from "@mui/icons-material";
import toast from "react-hot-toast";

const ExportExcel = ({ rowData, tableData }) => {
  const handleExport = async () => {
    var selected = tableData?.reduce(
      (obj, item) => Object.assign(obj, { [item.field]: item.checked }),
      {}
    );

    // const allFalse =
    //   selected && Object.values(selected).every((value) => value === false);

    // If all values are false, set them all to true
    // if (allFalse) {
    //   toast.error("Please Select The Column")
    //   return
    //   // for (const key in selected) {
    //   //   selected[key] = true;
    //   // }
    // }

    const headers = Object.entries(selected || {})
      .filter(([field, selected]) => selected)
      .map(([field]) => field);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rowData
        ?.map((entry) => headers?.map((field) => entry[field] ?? "").join(","))
        .join("\n");

    // Create a temporary link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
    toast.success("Data Exported Successfully.");
  };
  return (
    <div className="export-to-excel" onClick={handleExport}>
      <h4>
        {/* <CSVLink data={rowData} >Export to Excel</CSVLink> <Publish />{" "} */}
        <p data={rowData}>Export to Excel</p> <Publish />{" "}
      </h4>
    </div>
  );
};
export default ExportExcel;
