import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/layout";
import ToggleSwitch from "../global/toggleSwitch.jsx";
import { Button } from "react-bootstrap";
import AddBoxIcon from "@mui/icons-material/AddBox";
// import CreateSiteContentPopup from "./createSiteContentPopup";
import AdminSitetable from "./adminSiteTable";
import { getAllSiteContent } from "../../utils/Apis.js";
import { useMyContect } from "../../context/myContext.js";
// import { useMyContect } from "./context/myContext";
const AdminSiteContent = () => {
  const { setupdateSiteData } = useMyContect();
  const navigate = useNavigate();
  
  const [siteListData, setSiteListData] = useState([]);
  const handlePoppup = (type, editableData) => {
    console.log("heollll....0000.....", type, editableData);
    if (type === "createBtn") {
      setupdateSiteData(null)
      return navigate("/create-site");
    }
  
    if (editableData &&type !== "createBtn") {
      setupdateSiteData(editableData);
      return navigate("/create-site");
    }
  };

  // eslint-disable-next-line

  const [columnDefs] = useState([
    {
      field: "S.No.",
      width: "150px",
      cellStyle: { left: "15px" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.node.rowIndex + 1;
        return i;
      },
    },
    {
      field: "Domain Name",
      width: "150px",
      cellStyle: { "text-align": "center" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.data.domainName;
        return i;
      },
    },
    {
      field: "User Name",
      width: "150px",
      cellStyle: { "text-align": "center" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.data.userName;
        return i;
      },
    },
    {
      field: "Creator Name",
      width: "150px",
      cellStyle: { "text-align": "center" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.data.creatorName;
        return i;
      },
    },
    {
      field: "Email",
      width: "150px",
      cellStyle: { "text-align": "center" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.data.email;
        return i;
      },
    },
    {
      field: "Mobile",
      width: "150px",

      cellStyle: { "text-align": "center" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.data.phoneNumber;
        return i;
      },
    },
    {
      field: "Time Zone",
      width: "150px",
      cellStyle: { "text-align": "center" },
      cellRenderer: (params) => {
        console.log(params);
        let i = params.data.timeZone;
        return i;
      },
    },
    //
    // {
    //   field: "Database Name",
    //   width: "150px",
    //   cellStyle: { "text-align": "center" },
    //   cellRenderer: (params) => {
    //     console.log(params);
    //     let i = params.data.dbName;
    //     return i;
    //   },
    // },
    // {
    //   field: "Databse User Name",
    //   width: "150px",
    //   cellStyle: { "text-align": "center" },
    //   cellRenderer: (params) => {
    //     console.log(params);
    //     let i = params.data.dbUserName;
    //     return i;
    //   },
    // },
    // {
    //   field: "Databse Host",
    //   width: "150px",
    //   cellStyle: { "text-align": "center" },
    //   cellRenderer: (params) => {
    //     console.log(params);
    //     let i = params.data.dbHost;
    //     return i;
    //   },
    // },
    // {
    //   field: "Databse Port",
    //   width: "150px",
    //   cellStyle: { "text-align": "center" },
    //   cellRenderer: (params) => {
    //     console.log(params);
    //     let i = params.data.dbPort;
    //     return i;
    //   },
    // },
    // {
    //   field: "Database Password",
    //   width: "150px",
    //   cellStyle: { "text-align": "center" },
    //   cellRenderer: (params) => {
    //     console.log(params);
    //     let i = params.data.dbPassword;
    //     return i;
    //   },
    // },
    //
    {
      field: " Mute Notification",
      width: "100px",
      cellRenderer: (data) => (
        <Actions check={data?.data["muteNotification"]} />
      ),
      cellStyle: { "text-align": "center" },
    },
    {
      field: " Trader",
      width: "100px",
      cellRenderer: (data) => <Actions check={data?.data["trader"]} />,
      cellStyle: { "text-align": "center" },
    },
    {
      field: " Site Active",
      width: "100px",
      cellRenderer: (data) => <Actions check={data?.data["isSiteActive"]} />,
      cellStyle: { "text-align": "center" },
    },
    {
      field: "",
      width: "150px",
      cellRenderer: (params) => (
        <EditActions handlePoppup={handlePoppup} editableData={params.data} />
      ),
      cellStyle: { "text-align": "center" },
    },
  ]);
  const getSite = async () => {
    const response = await getAllSiteContent();
    if (response?.data?.status === 200) {
      setSiteListData(response?.data?.siteData);
    }
    console.log("responseresponse--->>", response);
  };
  useEffect(() => {
    console.log("djsdksd---");
    getSite();
    // await getAllSiteContent();
  }, []);
  console.log("columnDefs----c>>", columnDefs);
  return (
    <Layout title="ADMIN LIST">
      <div className="notification-page payment-method-page content-gap-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs notification-first global-limit"></div>
        </div>
        {/* <Filter activeTab={activeTab} /> */}
        <Button
          className="send-notificaton"
          onClick={() => handlePoppup("createBtn")}
        >
          <span className="add-icon">
            <AddBoxIcon />
          </span>{" "}
           Add Admin
        </Button>

        <AdminSitetable columnDefs={columnDefs} rowData={siteListData} />
      </div>
      {/* <CreateSiteContentPopup show={sitePopup} handleClose={handlePoppup} /> */}
    </Layout>
  );
};
export default AdminSiteContent;
const Actions = ({ check }) => {
  console.log("check", check);
  return (
    <>
      <div className="actions-outer1">
        <div className="toggle-switch1">
          <ToggleSwitch toggleFlag={check} />
        </div>
      </div>
    </>
  );
};

const EditActions = ({ handlePoppup, editableData }) => {
  return (
    <>
      <div className="actions-outer">
        <span
          className="action ms-4"
          onClick={() => handlePoppup("edit", editableData)}
        >
          <EditIcon />
        </span>
      </div>
    </>
  );
};
