import { useState } from "react";
import GlobalTable from '../../global/globalTable';
import ExportExcel from "../../global/exportExcel";

const TopUserTable = ({title}) => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "#" },
      { field: "User Name" },
      { field: "AMOUNT" },
    ]);
    return (
      <div className="deposit-table">
      <div className="top-area">
         <h6>{title}</h6>
       <div className="right-area">
       <ExportExcel />
       </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default TopUserTable