import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../../global/button";

const Filter = () => {
  return (
    <div className="voucher-filter">
      <div className="filter-section notification-popup filter-pd">
        <FloatingLabel controlId="floatingInput" label="User ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </div>
  );
};
export default Filter;
