import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import FilterInput from "../../global/filterInput";
import FilterDatePicker from "../../global/filterDatePicker";

const LoginLogFilter = () => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
  ];
  return (
    <div className="voucher-filter login-filter">
      <div className="filter-section mb-filter">
        <FilterInput label="User ID" name="UserID" />
        <FilterInput label="User Name" name="UserName" />
        <FilterInput label="IP" name="IP" />
        <FilterInput label="Browser" name="Browser" />
        <FilterInput label="User Name" name="UserName" />
      
        </div><div className="filter-section mb-filter">
        <FilterInput label="OS" name="OS" />
        <FilterDatePicker label="Date From:" name="DateFrom:"/>
        <FilterDatePicker label="Date To:" name="DateTo:"/>
        <SelectInput
          defaultOption={"Device Type"}
          listArray={Currencyoptions}
        />
        <div className="filter-section mb-0 ">
          <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
          <ButtonWrapper text="Clear All" clValue="clear-btn" />
        </div>
      </div>
    </div>
  );
};
export default LoginLogFilter;
