import React from 'react'
import SelectInput from '../../global/selectInput';

import ButtonWrapper from "../../global/button";
import FilterInput from '../../global/filterInput';
import FilterDatePicker from '../../global/filterDatePicker';
const KycVerificationFilter = () => {
    let Currencyoptions = [
        {
          id: 1,
          value: "AED",
        },
      ];
  return (
    <div className="filter-section">
    
        <SelectInput defaultOption={"Search By Questionnaire"} listArray={Currencyoptions} />
        <SelectInput defaultOption={"Forms"} listArray={Currencyoptions} />
        <SelectInput defaultOption={"Pending"} listArray={Currencyoptions} />
          <FilterDatePicker label="Send Date From" name={"SendDateFrom"}/>
          <FilterDatePicker label="Send Date To" name={"SendDateTo"}/>
          <FilterInput label="User ID" name={"UserID"}/>
          <FilterInput label="User Name" name={"UserName"}/>
          <div className="payment-btn pb-2">
            <ButtonWrapper text="Search" />
        </div>
        </div>
  )
}

export default KycVerificationFilter