import ButtonWrapper from "../global/button";
import Layout from "../layout/layout";
import Filter from "./filter";
import VoucherTable from "./voucherTable";
import "./voucher.css";
import AddVoucherPopup from "./addVoucher";
import { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
const Voucher = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    amount: "",
    currency: "",
    createdAt: null,
    endDate: null,
    creatorId: "",
    couponcode: "",
    status: "",
    redempDateFrom:null,
    redempDateTo:null
    
  });

  

  const handlePopup = () => {
    setShowPopup(!showPopup);
  };
 
  return (
    <Layout title={"VOUCHERS"}>
      <div className="voucher-page users-page">
        <div className="add-voucher-btn add-top-btn">
          <ButtonWrapper
            text={"Add Voucher"}
            icon={
              <span className="add-icon">
                <AddBoxIcon />
              </span>
            }
            handleClick={handlePopup}
          />
        </div>
        <Filter
          filter={filter}
          setFilter={setFilter}
          recall={recall}
          setRecall={setRecall}
       
        />
        <VoucherTable recall={recall} filter={filter} />
        <AddVoucherPopup
          show={showPopup}
          handleClose={handlePopup}
          recall={recall}
          setRecall={setRecall}
        />
      </div>
    </Layout>
  );
};
export default Voucher;
