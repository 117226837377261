import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Filter from "./filter.jsx";
import Layout from "../layout/layout.jsx";
import BonusTable from "./bonusTable.jsx";
import BonusPopup from "./bonusPopup.jsx";
import AddBoxIcon from '@mui/icons-material/AddBox';
import "./bonus.css";

const Bonus = () => {
  const [activeTab, setActiveTab] = useState("Bonus Templates");
  const [columnDefs, setColumnDefs] = useState([
    { field: "ID" },
    { field: "Name" },
    { field: "Date Created" },
    { field: "Creator" },
    { field: "Start Date" },
    { field: "To Date" },
    { field: "Usable Multiple Times" },
    { field: "Restricted To Users" },
    { field: "Needs Claiming" },
    { field: "Manual" },
    { field: "Affiliate ID" },
    { field: "Status" },
  ]);
  const navigate = useNavigate();
  const handleTabs = (value) => {
    setActiveTab(value.title);
    setColumnDefs(value.tblData);
  };

  const [sitePopup, setSitePopup] = useState();

  const handlePopup = (value) => {
    if (value !== " ") {
      setSitePopup(value);
    } else {
      setSitePopup(" ");
    }
  };
  return (
    <Layout title="BONUSES">
      <div className="notification-page payment-method-page">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs bonus_tabs">
            <ul className="">
              <div className="bonus_tabs_scroll">
                <li
                  onClick={() =>
                    handleTabs({
                      title: "Bonus Templates",
                      tblData: [
                        { field: "ID" },
                        { field: "Name" },
                        { field: "Date Created" },
                        { field: "Creator" },
                        { field: "Start Date" },
                        { field: "To Date" },
                        { field: "Usable Multiple Times" },
                        { field: "Restricted To Users" },
                        { field: "Needs Claiming" },
                        { field: "Manual" },
                        { field: "Affiliate ID" },
                        { field: "Status" },
                      ],
                    })
                  }
                  className={`${
                    activeTab === "Bonus Templates" ? "active-tab" : ""
                  }`}
                  role="presentation"
                >
                  Bonus Templates
                </li>
                <li
                  onClick={() =>
                    handleTabs({
                      title: "Bonus",
                      tblData: [
                        { field: "ID" },
                        { field: "Wager ID" },
                        { field: "User ID" },
                        { field: "Username" },
                        { field: "Bonus Name" },
                        { field: "Activation Amount" },
                        { field: "Blocked Amount" },
                        { field: "Bonus Amount" },
                        { field: "Current Balance" },
                        { field: "Current Rollover" },
                        { field: "Realization Rollover" },
                        { field: "Create Date" },
                        { field: "Active Till" },
                        { field: "Status" },
                      ],
                    })
                  }
                  className={`${activeTab === "Bonus" ? "active-tab" : ""}`}
                  role="presentation"
                >
                  Report
                </li>
                <li
                  onClick={() =>
                    handleTabs({
                      title: "Free Spin Bonus",
                      tblData: [
                        { field: "ID" },
                        { field: "Name" },
                        { field: "Creator" },
                        { field: "Preferred Bet Mode" },
                        { field: "Amount" },
                        { field: "Create Date" },
                        { field: "Start Date" },
                        { field: "To Date" },
                        { field: "Usability Hours" },
                        { field: "Single Use" },
                        { field: "Promo Code" },
                        { field: "Active" },
                        { field: "Max Activation Count" },
                        { field: "Affiliate ID" },
                      ],
                    })
                  }
                  className={`${
                    activeTab === "Free Spin Bonus" ? "active-tab" : ""
                  }`}
                  role="presentation"
                >
                  Free Spin Bonus
                </li>
                <li
                  onClick={() =>
                    handleTabs({
                      title: "Freespin Promo Report",
                      tblData: [
                        { field: "ID" },
                        { field: "Name" },
                        { field: "Creator" },
                        { field: "Preferred Bet Mode" },
                        { field: "Amount" },
                        { field: "Create Date" },
                        { field: "Start Date" },
                        { field: "To Date" },
                        { field: "Usability Hours" },
                        { field: "Single Use" },
                        { field: "Promo Code" },
                        { field: "Active" },
                        { field: "Max Activation Count" },
                        { field: "Affiliate ID" },
                      ],
                    })
                  }
                  className={`${
                    activeTab === "Freespin Promo Report" ? "active-tab" : ""
                  }`}
                  role="presentation"
                >
                  Freespin Promo Report
                </li>
                <li
                  onClick={() =>
                    handleTabs({
                      title: "Freespins",
                      tblData: [
                        { field: "ID" },
                        { field: "Template Name" },
                        { field: "Creator" },
                        { field: "Create Date" },
                        { field: "Date From" },
                        { field: "To Date" },
                        { field: "Games" },
                        { field: "Amount" },
                        { field: "Active" },
                      ],
                    })
                  }
                  className={`${activeTab === "Freespins" ? "active-tab" : ""}`}
                  role="presentation"
                >
                  Upload Free Spins
                </li>
              </div>
            </ul>

            {activeTab === "Bonus Templates" ? (
              <Button
                className="send-notificaton"
                onClick={() => {
                  navigate("/createBonus");
                }}
              >
                 <span className="add-icon"><AddBoxIcon /></span>Create New Template
              </Button>
            ) : activeTab === "Free Spin Bonus" ? (
              <Button
                className="send-notificaton"
                onClick={() => {
                  handlePopup("addNews");
                }}
              >
                <span className="add-icon"><AddBoxIcon /></span>Create Free Spin Bonus
              </Button>
            ) : activeTab === "Freespins" ? (
              <Button
                className="send-notificaton"
                onClick={() => {
                  handlePopup("addFreeSpins");
                }}
              >
                 <span className="add-icon"><AddBoxIcon /></span>Add Free Spins
              </Button>
            ) : (
              " "
            )}
          </div>
        </div>
        <Filter activeTab={activeTab} />
        <BonusTable activeTab={activeTab} columnDefs={columnDefs} />
        <BonusPopup
          show={sitePopup}
          handleClose={handlePopup}
          activeTab={activeTab}
        />
      </div>
    </Layout>
  );
};
export default Bonus;
