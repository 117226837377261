import { useState } from "react";
import GlobalTable from '../../global/globalTable';
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const ActivityTable = () => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "User ID" ,width:"100px"},
      { field: "User Name" ,width:"100px"},
      { field: "Registration time",width:"150px" },
      { field: "Country",width:"100px" },
      { field: "FDT",width:"100px" },
      { field: "GGR",width:"100px" },
      { field: "First Deposit Date",width:"150px" },
      { field: "Deposit Amount",width:"150px" },
      { field: "Deposit Count",width:"150px" },
      { field: "Average Deposit Amount",width:"200px" },
      { field: "Last Deposit Date",width:"200px" },
      { field: "Withdraw Amount",width:"150px" },
      { field: "Withdraw Count",width:"150px" },
      { field: "Average Withdraw Amount",width:"150px" },
      { field: "Bonus Amount",width:"150px"},
      { field: "Bonus Count",width:"150px"},
    ]);
    
    let options = [
  
        {
          id: 1,
          value: 50,
        },
        {
          id: 1,
          value: 100,
        },
      ];
    return (
      <div className="deposit-table">
      <div className="top-area">
         <h6>ACTIVITY</h6>
       <div className="right-area">
       <ExportExcel />
        <SelectInput listArray={options} defaultOption="20" />
        <TableColumns tableData={columnDefs}/>
       </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default ActivityTable