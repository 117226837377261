import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
// import SwitchBox from "../global/switchBox";
import FilterInput from "../global/filterInput";
import FilterDatePicker from "../global/filterDatePicker";

const Filter = ({ filter, setFilter, setRecall }) => {
  // let Currencyoptions = [
  //   {
  //     id: 1,
  //     value: "AED",
  //   },
  //   {
  //     id: 1,
  //     value: "AFN",
  //   },
  //   {
  //     id: 1,
  //     value: "ALL",
  //   },
  //   {
  //     id: 1,
  //     value: "AMD",
  //   },
  //   {
  //     id: 1,
  //     value: "ANG",
  //   },
  //   {
  //     id: 1,
  //     value: "AOA",
  //   },
  //   {
  //     id: 1,
  //     value: "ARS",
  //   },
  //   {
  //     id: 1,
  //     value: "AUD",
  //   },
  // ];
  let operationType = [
    {
      id: 1,
      value: "deposit",
    },
    {
      id: 1,
      value: "withdraw",
    },
  ];

  let transactionStatus = [
    {
      id: 1,
      value: "complete",
    },
    {
      id: 1,
      value: "incomplete",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(filter);
  };
  const handleDateChange = (name, date) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };
  return (
    <div className="voucher-filter">
      <div className="filter-section mb-filter">
        {/* all commented code need to update in future when landing data update. */}
        {/* <FilterInput
          label={"Payment Providers"}
          name={"PaymentProviders"}
          flag={true}
        />
        <FilterInput
          label={"Payment Channels"}
          name={"PaymentChannels"}
          flag={true}
        /> */}
       
        <SelectInput
          defaultOption={"Transaction Status"}
          listArray={transactionStatus}
          name={"status"}
          selectedValue={filter?.status}
          handleSelectOption={handleChange}
        />
        <SelectInput
          defaultOption={"Operation Type"}
          listArray={operationType}
          name={"transactionType"}
          selectedValue={filter?.transactionType}
          handleSelectOption={handleChange}
        />
        <FilterInput
          label={"Transaction ID"}
          name={"id"}
          type={"number"}
          value={filter?.id}
          handleChange={handleChange}
        />
      </div>
      <div className="filter-section mb-filter">
        <FilterInput
          label={"User ID"}
          name={"userId"}
          type={"number"}
          value={filter?.userId}
          handleChange={handleChange}
        />
        {/* <FilterInput label={"User Name"} name={"UserName"} /> */}
        <FilterDatePicker
          label={"Date From"}
          name={"fromDate"}
          flag={true}
          value={filter?.fromDate}
          handleDateChange={handleDateChange}
        />
        <FilterDatePicker
          label={"Date To"}
          name={"toDate"}
          flag={true}
          value={filter?.toDate}
          handleDateChange={handleDateChange}
        />
        {/* <FilterInput
          label={"Remote Transaction ID"}
          name={"RemoteTransactionID"}
        /> */}
      </div>
      {/* <div className="filter-section mb-filter">
        <FilterInput label={"Admin Name"} name={"AdminName"} />
        <FilterInput label={"Card PAN"} name={"CardPAN"} />
        <FilterInput label={"Payment Address"} name={"PaymentAddress"} />
        <FilterInput label={"Destination Tag"} name={"DestinationTag"} />
        <SelectInput defaultOption={"Country"} listArray={Currencyoptions} />
      </div> */}
      <div className="filter-section">
        <FilterInput
          label={"Amount From"}
          name={"fromAmount"}
          type={"number"}
          value={filter?.fromAmount}
          handleChange={handleChange}
        />
        <FilterInput
          label={"Amount To"}
          name={"toAmount"}
          type={"number"}
          value={filter?.toAmount}
          handleChange={handleChange}
        />
        {/* <FilterInput label={"Transaction GUID"} name={"TransactionGUID"} />
        <SwitchBox label={"Manual Confirm"} />
        <SwitchBox label={"Admin Control Passed"} /> */}

        <ButtonWrapper
          text={"Search"}
          clValue="search-filter-btn"
          handleClick={() => {
            setRecall(Math.random());
          }}
        />
        <ButtonWrapper
          text={"Clear All"}
          clValue="clear-btn"
          handleClick={() => {
            setFilter({
              id: "",
              userId: "",
              transactionType: "",
              fromAmount: "",
              toAmount: "",
              fromDate: "",
              toDate: "",
              status: "",
            });
            setRecall(Math.random())
          }}
        />
      </div>
    </div>
  );
};
export default Filter;
