import { useState, useEffect } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import {
  createComments,
  updateComments,
  deleteComments,
} from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";
const CommentPopup = ({ show, handleClose, socialPopup }) => {
  const { data, setIsLoading, isLoading, user } = useMyContect();
  const [comments, setComments] = useState({
    tagName: "",
    creatorName: "",
  });

  const [error, seterror] = useState({
    tagName: false,
  });

  const handleCreate = async () => {
    const newerror = {
      tagName: comments.tagName === "",
    };
    seterror(newerror);
    if (Object.values(newerror).some((error) => error)) {
      return;
    }
    try {
      const token = localStorage.getItem("secretDomainKey");
      setIsLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body:
            data && socialPopup === "edit"
              ? { ...comments, id: data.id }
              : comments,
        };
        const res =
          data && socialPopup === "edit"
            ? await updateComments(config)
            : await createComments(config);
        if (res?.data?.status === 200) {
          if (socialPopup === "edit") {
            toast.success("You Have Updated successfully Testimonial News", {
              toastId: "update",
            });
          } else {
            toast.success("You Have Created successfully Testimonial News", {
              toastId: "create",
            });
          }
          setIsLoading(false);
          handleClose();
        } else {
          toast.error("Something went Wrong Plase try Again", {
            toastId: "tryAgain",
          });
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
          body: data,
        };

        const res = await deleteComments(config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted Testimonial", {
            toastId: "toats",
          });
          setIsLoading(false);
          handleClose();
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setComments({ ...comments, [name]: value, creatorName: user?.firstName });
  };

  useEffect(() => {
    if (socialPopup?.includes("edit")) {
      setComments({
        tagName: data?.TagName,
        creatorName: data?.Creator,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup add-social-popup comment-popup`}>
      <Modal.Header closeButton>
        {show !== "Delete" && <Modal.Title>Add / Edit Tag</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section ">
          <div className="comment-filter">
            {show !== "Delete" ? (
              <>
                <FloatingLabel controlId="floatingInput" label="Tag Name">
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="tagName"
                    value={comments?.tagName}
                    onChange={(e) => handleChange(e)}
                  />
                  {error.tagName && (
                    <div className="text-danger">*Tag Name is required</div>
                  )}
                </FloatingLabel>
              </>
            ) : (
              <div className="ms-2">
                <p>Are you sure you want to delete this?</p>
              </div>
            )}
            {data && show === "edit" ? (
              <ButtonWrapper
                text={`${isLoading ? "Updating..." : "Update"}`}
                handleClick={handleCreate}
              />
            ) : show === "Delete" ? (
              <ButtonWrapper
                text={`${isLoading ? "Deleting..." : "Delete"}`}
                handleClick={handleDelete}
              />
            ) : (
              <ButtonWrapper
                text={`${isLoading ? "Creating..." : "Submit"}`}
                handleClick={handleCreate}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CommentPopup;
