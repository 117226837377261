import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import SwitchBox from "../global/switchBox";
import ButtonWrapper from "../global/button";
import { useEffect, useState } from "react";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import { addPaymentMethod, deletePaymentMethod } from "../../utils/Apis";

const AddPaymentPopup = ({
  show,
  handleClose,
  addPaymentType,
  setRecall,
  recall,
}) => {
  const { data } = useMyContect();

  const [smallImageName, setSmallImageName] = useState("Small Image");
  const [largeImageName, setLargeImageName] = useState("Large Image");
  const [loading, setLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState({
    name: "",
    orderNumber: "",
    userGroup: null,
    paymentProvider: "",
    paymentChannel: "",
    paymentMethodType: "",
    enabled: false,
    withEnabled: false,
    depEnabled: false,
    showForLimited: false,
    smallImage: null,
    largeImage: null,
  });

  const [error, setError] = useState({
    name: false,
    orderNumber: false,
    userGroup: false,
    paymentProvider: false,
    paymentChannel: false,
    paymentMethodType: false,
    // enabled: false,
    // withEnabled: false,
    // depEnabled: false,
    // showForLimited: false,
    smallImage: false,
    largeImage: false,
  });

  let paymentProvider = [
    {
      id: 1,
      value: "coinpayments(CoinPayments)",
    },
  ];
  let paymentChannel = [
    {
      id: 1,
      value: "bitcoin",
    },
    {
      id: 1,
      value: "Etherium",
    },
    {
      id: 1,
      value: "Tether USD (ERC20)",
    },
    {
      id: 1,
      value: "Tether USD (Tron/TRC20)",
    },
    {
      id: 1,
      value: "Tether USD (BSC Chain)",
    },
    {
      id: 1,
      value: "Bitcoin/BTCB Token (BC Chain)",
    },
    {
      id: 1,
      value: "Bitcoin/BTCB Token (BSC Chain)",
    },
  ];
  let userGroup = [
    { id: 1, value: "First Group" },
    { id: 2, value: "Second Group" },
  ];

  let paymentMethodType = [
    {
      id: 1,
      value: "Wallet",
    },
    {
      id: 1,
      value: "Bank",
    },
    {
      id: 1,
      value: "Crypto Currency",
    },
  ];

  useEffect(() => {
    if (addPaymentType === "Edit" && data) {
      setPaymentMethod({
        name: data?.name,
        orderNumber: data?.orderNumber,
        userGroup: data?.userGroup,
        paymentProvider: data?.paymentProvider,
        paymentChannel: data?.paymentChannel,
        paymentMethodType: data?.paymentMethodType,
        enabled: data?.enabled === "Yes",
        withEnabled: data?.withEnabled === "Yes",
        depEnabled: data?.depEnabled === "Yes",
        showForLimited: data?.showForLimited === "Yes",
        smallImage: data?.smallImage,
        largeImage: data?.largeImage,
      });
      let smallName = data?.smallImage;
      let largeName = data?.largeImage;

      if (smallName?.length > 15) {
        smallName = smallName.slice(0, 15) + "...";
        // console.log(name);
      }
      if (largeName?.length > 15) {
        largeName = largeName.slice(0, 15) + "...";
      }
      smallName = smallName?.replace(/_\d+/g, "");
      largeName = largeName?.replace(/_\d+/g, "");
      setLargeImageName(largeName);
      setError({
        ...error,
        largeImage: false,
        smallImage: false,
      });

      setSmallImageName(smallName);
    } else {
      setPaymentMethod({
        name: "",
        orderNumber: "",
        userGroup: null,
        paymentProvider: "",
        paymentChannel: "",
        paymentMethodType: "",
        enabled: false,
        withEnabled: false,
        depEnabled: false,
        showForLimited: false,
        smallImage: null,
        largeImage: null,
      });
    }
    // eslint-disable-next-line
  }, [data, addPaymentType]);
  // const handleChange = (e, selectFieldName) => {

  //   const { name, value } = e.target;

  //   if (selectFieldName) {
  //     setPaymentMethod({
  //       ...paymentMethod,
  //       [selectFieldName]: value,
  //     });
  //   } else {
  //     setPaymentMethod({
  //       ...paymentMethod,
  //       [name]: value,
  //     });
  //   }

  //   console.log("new",paymentMethod)

  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentMethod((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError({
      ...error,
      [name]: false,
    });
  };

  const handleSmallImageUpload = (file) => {
    console.log(">>>", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setError({
        ...error,
        smallImage: "*Small Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, smallImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          smallImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          smallImage: false,
        });
        let name = file.name;
        if (name?.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name?.replace(/_\d+/g, "");

        setSmallImageName(name);
        setPaymentMethod((prevState) => ({
          ...prevState,
          smallImage: file,
        }));
      }
    }
  };

  const handleLargeImageUpload = (file) => {
    console.log(">>>", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setError({
        ...error,
        largeImage: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, largeImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          largeImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          largeImage: false,
        });
        let name = file.name;
        if (name?.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name?.replace(/_\d+/g, "");

        setLargeImageName(name);
        setPaymentMethod((prevState) => ({
          ...prevState,
          largeImage: file,
        }));
      }
    }
  };
  const handleClear = () => {
    setPaymentMethod({
      name: "",
      orderNumber: "",
      userGroup: null,
      paymentProvider: "",
      paymentChannel: "",
      paymentMethodType: "",
      enabled: false,
      withEnabled: false,
      depEnabled: false,
      showForLimited: false,
      smallImage: null,
      largeImage: null,
    });
    setError({
      name: false,
      orderNumber: false,
      userGroup: false,
      paymentProvider: false,
      paymentChannel: false,
      paymentMethodType: false,
      smallImage: false,
      largeImage: false,
    });
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      formData.append("name", paymentMethod.name);
      formData.append("orderNumber", paymentMethod.orderNumber);
      formData.append("userGroup", paymentMethod.userGroup);
      formData.append("paymentProvider", paymentMethod.paymentProvider);
      formData.append("paymentChannel", paymentMethod.paymentChannel);
      formData.append("paymentMethodType", paymentMethod.paymentMethodType);
      formData.append("enabled", paymentMethod.enabled ? "Yes" : "No");
      formData.append("withEnabled", paymentMethod.enabled ? "Yes" : "No");
      formData.append("depEnabled", paymentMethod.withEnabled ? "Yes" : "No");
      formData.append(
        "showForLimited",
        paymentMethod.showForLimited ? "Yes" : "No"
      );
      formData.append("smallImage", paymentMethod.smallImage);
      formData.append("largeImage", paymentMethod.largeImage);
      const token = localStorage.getItem("secretDomainKey");
      const checkError = {
        name: paymentMethod.name === "",
        orderNumber: paymentMethod.orderNumber === "",
        userGroup: paymentMethod.userGroup === null,
        paymentProvider: paymentMethod.paymentProvider === "",
        paymentChannel: paymentMethod.paymentChannel === "",
        paymentMethodType: paymentMethod.paymentMethodType === "",
        smallImage:
          paymentMethod.smallImage === null
            ? "*Small image is required"
            : error?.smallImage,
        largeImage:
          paymentMethod.largeImage === null
            ? "Large Image is required"
            : error?.largeImage,
      };
      setError(checkError);
      if (Object.values(checkError).some((error) => error)) {
        return;
      }
      if (token) {
        setLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
        };
        console.log(config);

        await addPaymentMethod(formData, data?.id)
          .then((res) => {
            setLoading(false);
            toast.success(res.message || res.data.message);
            setRecall(recall + 1);
            handleClose();
          })
          .catch((err) => {
            toast.error(err.message || err.data.message);
          });
      } else {
        toast.error("Token Not Found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("secretDomainKey");
    try {
      if (token) {
        setLoading(true);
        const res = await deletePaymentMethod(data?.id);

        if (res) {
          toast.success(res.data.message);
          setLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      toast.error(error.message);
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const renderInput = (label, name, value, type) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type={type ? type : "text"}
        placeholder="name@example.com"
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FloatingLabel>
  );
  return (
    <Modal
      show={!!show}
      onHide={() => {
        handleClear();
        handleClose();
      }}
      centered
      className={`notification-popup add-payment-popup add-popup-content`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{addPaymentType} Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addPaymentType === "Delete" ? (
          <>
            <div className="">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="auto-btn">
              <ButtonWrapper
                loading={loading}
                text={loading ? "Deleting..." : "Delete"}
                disable={loading}
                handleClick={handleDelete}
              />
            </div>
          </>
        ) : (
          <div className="tabs-section row">
            <div className="col-6  my-2">
              {renderInput("Name", "name", paymentMethod?.name)}
              {error.name && (
                <p className="text-danger">Provider Name is required</p>
              )}
            </div>
            <div className="col-6 my-2">
              {renderInput(
                "Order Number",
                "orderNumber",
                paymentMethod?.orderNumber,
                "number"
              )}
              {error.orderNumber && (
                <div className="text-danger">Order Number is required</div>
              )}
            </div>
            <div className="col-6 my-2">
              <SelectInput
                defaultOption={"User Group"}
                name="userGroup"
                listArray={userGroup}
                handleSelectOption={handleChange}
                selectedValue={paymentMethod?.userGroup}
              />
              {error.userGroup && (
                <div className="text-danger">User Group is required</div>
              )}
            </div>
            <div className="col-6 my-2">
              <SelectInput
                defaultOption={"Payment Provider"}
                listArray={paymentProvider}
                name="paymentProvider"
                selectedValue={paymentMethod?.paymentProvider}
                handleSelectOption={handleChange}
              />
              {error.paymentProvider && (
                <div className="text-danger">Provider Provider is required</div>
              )}
            </div>
            <div className="col-6 my-2">
              <SelectInput
                defaultOption={"Payment Channel"}
                name="paymentChannel"
                selectedValue={paymentMethod?.paymentChannel}
                listArray={paymentChannel}
                handleSelectOption={handleChange}
              />
              {error.paymentChannel && (
                <div className="text-danger">Provider Channel is required</div>
              )}
            </div>
            <div className="col-6 my-2">
              <SelectInput
                defaultOption={"Payment Method Type"}
                name="paymentMethodType"
                listArray={paymentMethodType}
                handleSelectOption={handleChange}
                selectedValue={paymentMethod?.paymentMethodType}
              />
              {error.paymentMethodType && (
                <div className="text-danger">
                  Payment Method Type is required
                </div>
              )}
            </div>
            <div className="row-one gap-2 ">
              <SwitchBox
                label={"Enabled"}
                name="enable"
                handleToggle={(item) => {
                  setPaymentMethod((prev) => ({
                    ...prev,
                    enabled: item === true ? "Yes" : "No",
                  }));
                }}
                toggleFlag={paymentMethod.enabled}
              />
              <SwitchBox
                label={"With Enabled"}
                name="withEnabled"
                handleToggle={(item) => {
                  setPaymentMethod((prev) => ({
                    ...prev,
                    withEnabled: item === true ? "Yes" : "No",
                  }));
                }}
                toggleFlag={paymentMethod.withEnabled}
              />
              <SwitchBox
                label={"Dep.Enabled"}
                name="depEnabled"
                handleToggle={(item) => {
                  setPaymentMethod((prev) => ({
                    ...prev,
                    depEnabled: item === true ? "Yes" : "No",
                  }));
                }}
                toggleFlag={paymentMethod.depEnabled}
              />
              <SwitchBox
                label={"Show For Limited..."}
                name="showForLimited"
                handleToggle={(item) => {
                  setPaymentMethod((prev) => ({
                    ...prev,
                    showForLimited: item === true ? "Yes" : "No",
                  }));
                }}
                toggleFlag={paymentMethod.showForLimited}
              />
            </div>

            <div className="mt-4 mb-4 popup-filter">
              <div>
                <div className="upload-image">
                  {paymentMethod.smallImage ? (
                    <label htmlFor="small-upload" className="upload-image-box">
                      {smallImageName}
                      <span className="uplaod-icon">
                        <ImageIcon />
                      </span>
                    </label>
                  ) : (
                    <label htmlFor="small-upload" className="upload-image-box">
                      Small Image
                      <span className="cloud-icon">
                        <CloudUploadIcon />
                      </span>
                    </label>
                  )}
                  <input
                    type="file"
                    id="small-upload"
                    accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                  image/gif,
                  image/bmp,"
                    onChange={(event) =>
                      handleSmallImageUpload(event.target.files[0])
                    }
                  />
                </div>
                {!!error.smallImage && (
                  <div className="text-danger">{error.smallImage}</div>
                )}
              </div>
              <div>
                <div className="upload-image">
                  {paymentMethod.largeImage ? (
                    <label htmlFor="large-upload" className="upload-image-box">
                      {largeImageName}
                      <span className="uplaod-icon">
                        <ImageIcon />
                      </span>
                    </label>
                  ) : (
                    <label htmlFor="large-upload" className="upload-image-box">
                      Large Image
                      <span className="cloud-icon">
                        <CloudUploadIcon />
                      </span>
                    </label>
                  )}
                  <input
                    type="file"
                    accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                  image/gif,
                  image/bmp,"
                    id="large-upload"
                    onChange={(e) => handleLargeImageUpload(e.target.files[0])}
                  />
                </div>
                {!!error.largeImage && (
                  <div className="text-danger">{error?.largeImage}</div>
                )}
              </div>
            </div>
            {/* <div className="col-6 my-2">
              
              <UploadImage
                label={smallImageName}
                id="smallImage"
                acceptFile={".jpg, .jpeg, .png, .gif, .bmp, .svg"}
                onImageUpload={handleSmallImageUpload}
              />
              {error.smallImage && (
                <div className="text-danger">Small Image is required</div>
              )}
            </div>
            <div className="col-6 my-2">
              <UploadImage
                label={largeImageName}
                id="largeImage"
                acceptFile={".jpg, .jpeg, .png, .gif, .bmp, .svg"}
                onImageUpload={handleLargeImageUpload}
              />
              {error.largeImage && (
                <div className="text-danger">Large Image is required</div>
              )}
            </div> */}
            <div className="payment-btn">
              <ButtonWrapper
                loading={loading}
                text={
                  addPaymentType === "Edit"
                    ? loading
                      ? "Updating..."
                      : "Update"
                    : loading
                    ? "Creating..."
                    : "Create"
                }
                handleClick={handleSubmit}
              />
              <ButtonWrapper
                text="Clear All"
                clValue="clear-btn"
                handleClick={handleClear}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AddPaymentPopup;
