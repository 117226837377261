import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";

const Filter = ({ activeTab }) => {
  const currencyOptions = [{ id: 1, value: "AED" }];

  const renderFloatingLabelInput = (label, name, disabled = false) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type="text"
        placeholder="name@example.com"
        autoComplete="off"
        name={name}
        disabled={disabled}
      />
    </FloatingLabel>
  );

  return (
    <div
      className={`datepicker filter-Tab ${
        activeTab === "Notifications" ? "" : "active-tab-noti"
      }`}
    >
      <div className="filter-section filter-pd mb-0">
        {renderFloatingLabelInput("User ID", "userId")}
        {renderFloatingLabelInput("Affiliate ID", "affiliateID")}
        <SelectInput defaultOption="User Group" listArray={currencyOptions} />
        <SelectInput
          defaultOption="Default Currency"
          listArray={currencyOptions}
        />
        {renderFloatingLabelInput("Choose Currency", "chooseCurrency", true)}
        {renderFloatingLabelInput("First Name", "firstName")}
        {renderFloatingLabelInput("Last Name", "lastName")}
        {renderFloatingLabelInput("Registration From", "registrationFrom")}
        {renderFloatingLabelInput("Registration To", "registrationTo")}
        {renderFloatingLabelInput("Birthdate From", "birthdateFrom")}
        {renderFloatingLabelInput("Birthdate To", "birthdateTo")}
        <ButtonWrapper text="Search" />
      </div>
    </div>
  );
};

export default Filter;
