import { useState } from "react";
import Layout from "../../layout/layout";
import DatePicker from "../../global/datePicker";
import RegistrationTable from "./registrationTable";
import PlayerCount from "./playerCount";
import HorizontalTabs from "../../global/horizontalTab";
import "./registration.css";

const tabs = ["Today", "Yesterday", "Past Week", "Current Month", "Last Month"];

const Registration = () => {
  const [activeTab, setActiveTab] = useState("Today");

  const handleTabs = (value) => {
    setActiveTab(value);
  };
  return (
    <Layout title="REGISTRATION STATISTICS">
      <div className="notification-page registration-page report-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            <div className="date-filter">
            <DatePicker />
            </div>
          </div>
        </div>
        <div className="registration-section">
          <RegistrationTable />
          <PlayerCount />
        </div>
      </div>
    </Layout>
  );
};
export default Registration;
