import { FloatingLabel, Form } from "react-bootstrap";
import ToggleSwitch from "./toggleSwitch";

const SwitchBox = ({ label, type, name, handleToggle, toggleFlag }) => {
  return (
    <div className="switch-box">
      {label === "Email" ? (
        <FloatingLabel
          controlId="floatingInput"
          label={label}
          className="togglemail"
        >
          <Form.Control
            type="text"a
            placeholder=""
            autoComplete="off"
            name={label}
            readOnly
          />
        </FloatingLabel>
      ) : (
        <p className="pb-0 mb-0 text-nowrap">{label}</p>
      )}
      <ToggleSwitch name={name} handleToggle={handleToggle} toggleFlag={toggleFlag} />
    </div>
  );
};
export default SwitchBox;