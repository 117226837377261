import Layout from "../../layout/layout";
import Filter from "./filter";
import "./pushNotification.css"
const PushNotification = () => {
  return ( 
    <Layout title="
    PUSH NOTIFICATIONS"> 
      <div className="notification-page payment-method-page">
       <Filter />
      </div>
    </Layout>
  );
};
export default PushNotification;