import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";

const CashdeskTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "Date" },
    { field: "Deposit" },
    { field: "Correction" },
    { field: "Free Money" },
    { field: "Withdraw" },
    { field: "Currency" },
  ]);

  const [columnDefs1] = useState([
    { field: "Time" },
    { field: "User ID" },
    { field: "User Name" },
    { field: "Amount" },
    { field: "Transaction ID" },
    { field: "Type" },
    { field: "Admin" },
    { field: "Comment" },
  ]);

  return (
    <div className="cashdesk-table-section">
      <div className="deposit-table">
        <GlobalTable columnDefs={columnDefs} rowData={rowData} />
      </div>
      <div className="deposit-table">
        <GlobalTable columnDefs={columnDefs1} rowData={rowData} />
      </div>
    </div>
  );
};
export default CashdeskTable;
