import { useState } from "react";
import Layout from "../../layout/layout";
import Filter from "./filter";
import TranslationTable from "./translationTable";
import TranslationTableRight from "./translationTableRight";
import HorizontalTabs from "../../global/horizontalTab.jsx";
import "./translation.css";
import ButtonWrapper from "../../global/button.jsx";

const tabs = ["Front", "Backoffice", "Affiliate", "Shops"];

const Translation = () => {
  const [activeTab, setActiveTab] = useState("Edit");
  const [activeTab2, setActiveTab2] = useState("Front");
  const handleTabs = (value) => {
    setActiveTab(value);
  };

  const handleTabs2 = (value) => {
    setActiveTab2(value);
    setActiveTab("Edit");
  };

  const [rowData] = useState([
    {
      Groups: "MENU",
    },
    {
      Groups: "FOOTER",
    },
    {
      Groups: "LOGIN",
    },
    {
      Groups: "F_PASSWORD",
    },
    {
      Groups: "SIGNUP",
    },
    {
      Groups: "MAIN_PAGE",
    },
    {
      Groups: "SPORTSBOOK",
    },
    {
      Groups: "MENU",
    },
    {
      Groups: "FOOTER",
    },
    {
      Groups: "LOGIN",
    },
    {
      Groups: "F_PASSWORD",
    },
    {
      Groups: "SIGNUP",
    },
    {
      Groups: "MAIN_PAGE",
    },
    {
      Groups: "SPORTSBOOK",
    },
  ]);
  const [rowData1] = useState([
    { Keys: "SPORT" },
    { Keys: "CASINO" },
    { Keys: "LIVE_CASINO" },
    { Keys: "LIVE" },
    { Keys: "VIRTUAL" },
    { Keys: "FISH_CASINO" },
    { Keys: "MINI_GAME" },
  ]);
  const [columnDefs] = useState([{ field: "Groups" }]);
  const [columnDefs2] = useState([{ field: "Keys" }]);
  return (
    <Layout title="TRANSLATIONS">
      <div className="notification-page payment-method-page translation-page content-gap-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs">
            <ul className="horizontal-left-tabs">
              <li
                className={`${activeTab === "Edit" ? "active-tab" : ""}`}
                onClick={() => {
                  handleTabs("Edit");
                }}
                role="presentation"
              >
                Edit
              </li>
              <li
                className={`${activeTab === "Add" ? "active-tab" : ""}`}
                onClick={() => {
                  handleTabs("Add");
                }}
                role="presentation"
              >
                Add
              </li>
            </ul>
            <HorizontalTabs
              activeTab={activeTab2}
              handleTabs={handleTabs2}
              tabs={tabs}
            />
          </div>
        </div>
        <div>
          <Filter activeTab={activeTab} />
          <div className="translation-table">
            <div className="translation-table-left">
              <TranslationTable
                rowData={rowData}
                columnDefs={columnDefs}
                pageCss="translation-page"
              />
              <TranslationTable rowData={rowData1} columnDefs={columnDefs2} />
            </div>

            <div className="translation-table-right">
              <TranslationTableRight />
            </div>
          </div>
          <div className="auto-btn filter-section btn-wrapper justify-content-center pt-4">
            <ButtonWrapper text="Add" />
            {/* <ButtonWrapper text={"Clear All"} clValue="clear-btn" /> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Translation;
