import { useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";

const RegistrationTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "Date" },
    { field: "Count" },
  ]);

  return (
    <div className="deposit-table mt-0">
      <h2>Registration Report</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
export default RegistrationTable;
