import { useState } from "react";
import GlobalTable from "../global/globalTable";
const BonusTable = ({ activeTab, columnDefs }) => {
  const [rowData] = useState([]);

  return (
    <div className="deposit-table config-table">
      <div className="top-area">
        <h6>
          {activeTab === "Create Promotions Class"
            ? "Promotions Class"
            : activeTab}
        </h6>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default BonusTable;
