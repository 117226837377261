import { FloatingLabel, Form } from "react-bootstrap";
import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";
import { useState } from "react";
import toast from "react-hot-toast";
import { createPromotionClass } from "../../../utils/Apis";

const Filter = ({ activeTab, setRecall }) => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setName(e.target.value);
    setError("");
  };
  const handleSubmit = async () => {
    try {
      if (!name) {
        setError("Please Enter the Name");
      } else {
        const token = localStorage.getItem("secretDomainKey");
        if (token) {
          const config = {
            headers: {
              DomainToken: `${token}`,
            },
            // params: {
            //   page: page,
            //   pageSize: pageSize,
            // },
            body: {
              name,
            },
          };
          setLoading(true);
          const res = await createPromotionClass(config);
          // console.log(res);
          if (res) {
            setLoading(true);
            setName("");
            setError("");
            setRecall(Math.random());

            toast.success("Promotions Class Added Successfully.");
          }
        } else {
          console.log("No token found");
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="voucher-filter">
      <div className="filter-section no tification-popup">
        {activeTab === "Create Promotions Class" ? (
          <FloatingLabel controlId="floatingInput" label="Name">
            <Form.Control
              type="text"
              placeholder=""
              autoComplete="off"
              name="name"
              value={name}
              onChange={handleChange}
            />
          </FloatingLabel>
        ) : (
          <SelectInput defaultOption={"en-US"} listArray={Currencyoptions} />
        )}
        {!!error && <div className="text-danger">*Name is required</div>}
        {activeTab === "Create Promotions Class" ? (
          <ButtonWrapper
            text={"Add"}
            clValue="search-filter-btn"
            handleClick={handleSubmit}
            loading={loading}
          />
        ) : (
          <ButtonWrapper
            text={activeTab === "Create Promotions Class" ? "Add" : "Search"}
            clValue="search-filter-btn"
          />
        )}
      </div>
    </div>
  );
};
export default Filter;
