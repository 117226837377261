import { AssignmentReturned, AttachMoney, Mood, MoodBad } from "@mui/icons-material";
import Skeleton from '@mui/material/Skeleton';
const WinnerLosers = ({isLoading}) => {
  return (
    <div className="winner-loser-section">
      <div className="winner-box top-winners">
      {isLoading?<Skeleton animation="wave"/>:<h6><span className="winner-icon">
          <Mood />
          </span> 
          Top Winners</h6>}
      </div>
      <div className="winner-box top-losers">
      {isLoading?<Skeleton animation="wave"/>:<h6><MoodBad /> Top Losers</h6>}
      </div>
      <div className="winner-box top-losers">
      {isLoading?<Skeleton animation="wave"/>:<h6><AttachMoney /> Top Deposit</h6>}
      </div>
      <div className="winner-box top-winners">
      {isLoading?<Skeleton animation="wave"/>: <h6><span className="winner-icon"><AssignmentReturned /></span> Top Withdraw</h6>}
      </div>
    </div>
  );
};
export default WinnerLosers;
