import { Form } from "react-bootstrap";

const GlobalInput = ({label, placeholder, type,  value}) => {
  return (
    <Form.Group controlId="validationCustom01">
        {label && (
      <Form.Label>{label}</Form.Label>
        )}
      <Form.Control
        required
        type={type}
        placeholder={placeholder}
        defaultValue={value ? value : ''}
      />
    </Form.Group>
  );
};
export default GlobalInput;
