import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const navigate = useNavigate();
  const path = useLocation();

  const handlePageChange = (page) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pageParam = params?.get("page");
    if (pageParam) {
      onPageChange(Number(pageParam));
    }
  }, [onPageChange]);

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    const maxPaginationNumbers = 5; // Maximum number of pagination numbers to display

    // Calculate the range of pagination numbers to display
    let start = Math.max(1, currentPage - Math.floor(maxPaginationNumbers / 2));
    let end = Math.min(totalPages, start + maxPaginationNumbers - 1);

    if (end - start < maxPaginationNumbers - 1) {
      start = Math.max(1, end - maxPaginationNumbers + 1);
    }

    for (let page = start; page <= end; page++) {
      paginationNumbers.push(
        <button
          key={page}
          onClick={() => {
            handlePageChange(page);
            navigate(path?.pathname + "?page=" + page);
          }}
          className={currentPage === page ? "active" : ""}>
          {page}
        </button>
      );
    }

    return paginationNumbers;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
      navigate(path?.pathname + "?page=" + (parseInt(currentPage) - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
      navigate(path?.pathname + "?page=" + (parseInt(currentPage) + 1));
    }
  };

  return (
    totalPages > 1 && (
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>

        {renderPaginationNumbers()}

        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    )
  );
};

export default Pagination;
