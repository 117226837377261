import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";

const AddKeyPopup = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-provider-popup add-key`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Data Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <div className="row-one ">
            <FloatingLabel controlId="floatingInput" label="Provider Name">
              <Form.Control
                type="text"
                placeholder="name@example.com"
                autoComplete="off"
                name="username"
              />
            </FloatingLabel>
            <div className="payment-btn">
              <ButtonWrapper text="Add" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddKeyPopup;
