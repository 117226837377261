import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";

const Filter = ({ activeTab }) => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <div
      className={`datepicker filter-Tab filter-tab-pb ${
        activeTab === "Notifications" ? "" : "active-tab-noti"
      }`}
    >
      <SelectInput defaultOption={"en-US"} listArray={Currencyoptions} />
      <ButtonWrapper text="Search" />
    </div>
  );
};
export default Filter;
