import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
const VerifyInfoPopup = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup add-social-popup comment-popup authenticator-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Set Google Authenticator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section ">
          <div className="comment-filter authenticator-filter">
            <SelectInput defaultOption={"SMS"} />
            <ButtonWrapper text="SEND" />
          </div>
          <div className="comment-filter authenticator-filter verify-code">
            <FloatingLabel controlId="floatingInput" label="Code">
              <Form.Control
                type="number"
                placeholder="name@example.com"
                autoComplete="off"
                name="username"
                disabled
              />
            </FloatingLabel>
            <ButtonWrapper text="SUBMIT" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default VerifyInfoPopup;
