import { useState } from "react";
import LoginLogFilter from "./loginLogFilter";
import Layout from "../../layout/layout";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const LoginLog = () => {
  return (
    <Layout title="LOGIN LOG">
      <div className="affiliate-list-page media-template-page register-affiliate-page users-page">
        <LoginLogFilter />
        <LoginLogTable />
      </div>
    </Layout>
  );
};
export default LoginLog;

const LoginLogTable = () => {
  const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "User ID" },
    { field: "User Name" },
    { field: "Balance" },
    { field: "Currency" },
    { field: "Hot Address" },
    { field: "Browser" },
    { field: "OS" },
    { field: "Date" },
    { field: "Device Type" },
    { field: "Brand" },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Authorization Log</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};
