import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";

const Filter = ({ activeTab }) => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <div className="voucher-filter">
      <div className="filter-section notification-popup">
        <SelectInput
          defaultOption={"Choose Language"}
          listArray={Currencyoptions}
        />
        <ButtonWrapper
          text={activeTab === "Create Promotions Class" ? "Add" : "Search"}
          clValue="search-filter-btn"
        />
      </div>
    </div>
  );
};
export default Filter;
