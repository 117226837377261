import { serverPath } from "./keys.js";
import axios from "axios";

export const authInstance = () =>
  axios.create({
    baseURL: `${serverPath}`,
  });

export const adminInstance = () =>
  axios.create({
    baseURL: `${serverPath}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accToken")}`,
      DomainToken: localStorage.getItem("secretDomainKey")
    },
  });
