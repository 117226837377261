// import GlobalTable from "../../global/globalTable";

import GlobalTable from "../global/globalTable";

const AdminSitetable = ({ columnDefs, rowData }) => {
  // const [rowData] = useState([
  //   {
  //     Position: "1",
  //     "Testimonial Name": "hgfghf",
  //     Lang: "gh",
  //     Company: "sdfsdf",
  //     "Testimonial Text": "kjnkj",
  //   },
  // ]);

  return (
    <div className="deposit-table config-table">
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default AdminSitetable;
