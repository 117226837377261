import { useState } from "react";
import Layout from "../../layout/layout";
import DatePicker from "../../global/datePicker";
import Filter from "./fliter";
import TopUserTable from "./topUserTable.jsx";
import HorizontalTabs from "../../global/horizontalTab.jsx";
import "./topUserReport.css";

const tabs = ["Today", "Yesterday", "Past Week", "Current Month", "Last Month"];

const TopUserReport = () => {
  const [activeTab, setActiveTab] = useState("Today");
  const handleTabs = (value) => {
    setActiveTab(value);
  };
  return (
    <Layout title="TOP USERS REPORT">
      <div className="notification-page users-page cashdesk-page performance-pages report-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            <div className="date-filter">
              <DatePicker />
            </div>
          </div>
        </div>
        <div className="cashdesk-section">
          <Filter />
        </div>
        <div className="registration-section1 user-report-winner">
          <TopUserTable title="Top Winners" />
          <TopUserTable title="Top Loosers" />
          <TopUserTable title="Top Depositors" />
          <TopUserTable title="Top Withdrawals" />
        </div>
      </div>
    </Layout>
  );
};
export default TopUserReport;
