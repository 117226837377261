import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import { useLocation } from "react-router-dom";
import FilterDatePicker from "../global/filterDatePicker";

const AffiliateFilter = () => {
  const location = useLocation();
  const page = location.pathname.split("/")?.[1];

  const currencyOptions = [
    { id: 1, value: "AED" },
    { id: 2, value: "AFN" },
    { id: 3, value: "ALL" },
    { id: 4, value: "AMD" },
    { id: 5, value: "ANG" },
    { id: 6, value: "AOA" },
    { id: 7, value: "ARS" },
    { id: 8, value: "AUD" },
  ];

  const renderFloatingLabel = (controlId, label, placeholder = "") => (
    <FloatingLabel controlId={controlId} label={label}>
      <Form.Control
        type="text"
        placeholder={placeholder}
        autoComplete="off"
        name={controlId}
      />
    </FloatingLabel>
  );

  return (
    <div className="voucher-filter">
      <div className={`filter-section ${page}`}>
        {renderFloatingLabel("userId", "User ID")}
        {renderFloatingLabel("username", "User Name")}
        {renderFloatingLabel("firstName", "First Name", "First Name")}
        {renderFloatingLabel("lastName", "Last Name", "Last Name")}
        <SelectInput defaultOption={"Country"} listArray={currencyOptions} />
        <FilterDatePicker label={"Create Time From"} name={"createTimeFrom"} />
        <FilterDatePicker label={"Create Time To"} name={"createTimeTo"} />
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
        <ButtonWrapper text="Clear All" clValue="clear-btn" />
      </div>
    </div>
  );
};

export default AffiliateFilter;
