import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SwitchBox from "../global/switchBox";
const BonusStepContent = () => {
  return (
    <div className="step-content">
      <div>
        <h2>Bonus Template</h2>
        <div>
          <div className="filter-section-user">
            <div className="filter-section">
              <FloatingLabel controlId="floatingInput" label="Template Name">
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  name="userid"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Start Date">
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  name="username"
                />
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="End Date">
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  name="firstname"
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Min Days from last"
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  name="firstname"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Bonus Codes">
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  name="firstname"
                />
              </FloatingLabel>
              <div className="filter-section m-0">
                <ButtonWrapper
                  text={"Bonus Codes "}
                  clValue="search-filter-btn"
                />
              </div>
            </div>
            <div className="create-bonus-currencies">
              <span className="text">Currencies</span>
                <div className="filter-section kyc_mx">
                  <SwitchBox label={"Active"} />
                </div>
            </div>
            <div className="bonus-checkbox">
              <div className="filter-section">
                <SwitchBox label={"Active"} />
                <SwitchBox label={"KYC Required"} />
                <SwitchBox label={"Activate Automatically"} />
                <SwitchBox label={"Usable Multiple Times"} />
              </div>
            </div>
            <div>
              <h2>KYC FORMS</h2>
              <div className="filter-section kyc_mx filter-bottom-box">
                <SwitchBox label={"Active"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusStepContent;
