import { useState } from "react";
import GlobalTable from "../global/globalTable";
import Layout from "../layout/layout";
import AffiliateRequestsFilter from "./affiliateRequestsFilter";
import "./affiliate.css";

const AffiliateRequests = () => {
  return (
    <Layout title="REQUESTS">
      <div className="affiliate-list-page">
        <AffiliateRequestsFilter />
        <AffiliateRequestsTable />
      </div>
    </Layout>
  );
};

const AffiliateRequestsTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "ID" },
    { field: "User ID" },
    { field: "User Name" },
    { field: "First Name" },
    { field: "Last Name" },
    { field: "Request Date" },
  ];

  return (
    <div className="deposit-table">
      <h2>Affiliate Requests</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default AffiliateRequests;
