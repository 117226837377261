import ButtonWrapper from "../../global/button";
import { FloatingLabel, Form } from "react-bootstrap";
import UploadImage from "../../global/uploadImage";
const Filter = ({ activeTab }) => {
  return (
    <div
      className={`datepicker filter-Tab ${
        activeTab === "Notifications" ? "" : "active-tab-noti"
      }`}
    >
      <div className="automaticaly-content manual-content add-payment-popup notification-popup push-notification-outer ">
        <div className="popup-filter">
          <FloatingLabel controlId="floatingInput" label="Title *">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              autoComplete="off"
              name="username"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Redirect URL *">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              autoComplete="off"
              name="username"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="User ID">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              autoComplete="off"
              name="username"
            />
          </FloatingLabel>
          <UploadImage label={"ICON"} />
        </div>
        <Form.Control
          as="textarea"
          type="textarea"
          className="notificationDescription"
          rows={3}
          placeholder="Notification Text*"
        />

        <div className="auto-btn p-0 pt-3">
          <ButtonWrapper text="Send Notification" />
        </div>
      </div>
    </div>
  );
};
export default Filter;
