import { Button, FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import TabSection from "./tabSection";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
const Filter = ({ activeTab }) => {
  return (
    <>
      <div className="voucher-filter1 bonus-filter">
        {activeTab === "Bonus" && <ReportFilter />}
        {activeTab === "Free Spin Bonus" && <Freespin />}
        {activeTab === "Freespin Promo Report" && <FreespinPromoReport />}
        {activeTab === "Freespins" && (
          <div className="d-flex justify-content-between align-items-center">
            <Freespins />{" "}
            <div>
              {" "}
              <Button className="d-flex justify-content-between align-items-center btn-spins">
                <p className="mb-0 pb-0 "> Download Templates</p>{" "}
                <FileDownloadIcon />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Filter;

const ReportFilter = () => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <>
      <div className="filter-section no tification-popup">
        <FloatingLabel controlId="floatingInput" label="Bonus ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="User ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="User Name">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <SelectInput defaultOption={"Status"} listArray={Currencyoptions} />
        <FloatingLabel controlId="floatingInput" label="Create Date From">
          <Form.Control
            type="date"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Create Date To">
          <Form.Control
            type="date"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>

        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </>
  );
};

const Freespin = () => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <>
      <div className="filter-section no tification-popup">
        <FloatingLabel controlId="floatingInput" label="Game ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <SelectInput
          defaultOption={"Game Vendor"}
          listArray={Currencyoptions}
        />
        <FloatingLabel controlId="floatingInput" label="Promo Code">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Affiliate ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>

        <FloatingLabel controlId="floatingInput" label="Creator Admin Name">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <SelectInput
          defaultOption={"Promo Code Status"}
          listArray={Currencyoptions}
        />
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </>
  );
};

const FreespinPromoReport = () => {
  return (
    <>
      <div className="">
        <TabSection />
      </div>
      <div className="filter-section notification-popup">
        <FloatingLabel controlId="floatingInput" label="User ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="User Name">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </>
  );
};

const Freespins = () => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <>
      <div className="filter-section notification-popup freespins-filter">
        <FloatingLabel controlId="floatingInput" label="User ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="User Name">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Template Name">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>
        <SelectInput
          defaultOption={"Game Vendor"}
          listArray={Currencyoptions}
        />
        <ButtonWrapper text={"Filter"} clValue="search-filter-btn" />
      </div>
    </>
  );
};
