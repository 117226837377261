import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import { useMyContect } from "../../context/myContext";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addUpdatePaymentRestrictionUser } from "../../utils/Apis";

const RestrictedPopup = ({
  show,
  handleClose,
  userData,
  setRefreshUser,
  cpu,
}) => {
  const { user } = useMyContect();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    deposit: "",
    paymentProvider: "",
    paymentChannel: "",
    userProfileGroup: "",
    creatorName: "",
    creatorId: "",
    status: "Active",
  });
  const handleChange = (e) => {
    setFields((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    await addUpdatePaymentRestrictionUser({
      body: fields,
      restUserId: fields?.id || "",
    })
      .then((result) => {
        if (result?.data?.status === 200) {
          setRefreshUser(Math.random());
          toast.success(result?.data?.message);
          handleClose();
        } else {
          toast.error(result?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (userData) {
      setFields((prevState) => ({
        ...prevState,
        id: userData?.ID,
        userId: userData["User Profile ID"],
        deposit: userData?.Direction,
        paymentProvider: userData["Payment Provider Name"],
        userProfileGroup: userData["Group Name"],
      }));
    } else {
      setFields((prevState) => ({
        ...prevState,
        id: "",
        deposit: "",
        paymentProvider: "",
        userProfileGroup: "",
        userId: "",
      }));
    }
  }, [userData]);
  useEffect(() => {
    setFields((prevState) => ({
      ...prevState,
      creatorName: user?.firstName,
      creatorId: user?.id,
    }));
  }, [user]);

  console.log(userData);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup group-allowed-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>User Restricted Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <div className="row-one">
            <SelectInput
              defaultOption={"Deposit"}
              listArray={[{ value: "Widthdraw" }]}
              name={"deposit"}
              selectedValue={fields?.deposit}
              handleSelectOption={handleChange}
            />
            <SelectInput
              defaultOption={"Payment Provider"}
              listArray={cpu?.providers}
              name={"paymentProvider"}
              selectedValue={fields?.paymentProvider}
              handleSelectOption={handleChange}
            />
            {/* <SelectInput
              defaultOption={"Payment Channel"}
              listArray={Currencyoptions}
              name={'deposit'}
              selectedValue={fields.deposit}
              handleSelectOption={handleChange}
            /> */}
            <FloatingLabel controlId="floatingInput" label="User Profile ID">
              <Form.Control
                type="text"
                placeholder="name@example.com"
                autoComplete="off"
                name="userId"
                value={fields?.userId}
                onChange={handleChange}
              />
            </FloatingLabel>
          </div>

          <div className="payment-btn">
            <ButtonWrapper
              text="Save"
              loading={loading}
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RestrictedPopup;
