import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import { useEffect, useRef, useState } from "react";
import SwitchBox from "../global/switchBox";
import GlobalEditor from "../global/globalEditor";
import { addUpdateNotificationTemplate } from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";

const AddNotificationPopup = ({ show, handleClose, setRefreshTemp, tempData }) => {
  const editorRef = useRef(null);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup add-popup-content `}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Notification Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <ManuallyTab
            editorRef={editorRef}
            setRefreshTemp={setRefreshTemp}
            handleClose={handleClose}
            tempData={tempData}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddNotificationPopup;

const ManuallyTab = ({ editorRef, setRefreshTemp, handleClose, tempData }) => {
  const { user } = useMyContect();
  const [fields, setFields] = useState({
    "id": "",
    "templateName": "",
    "language": "en-US",
    "notificationTitle": "",
    "showinOrders": "No",
    "showinKyc": "No",
    "autoSendMail": "No",
    "creatorName": user?.firstName,
    "creatorId": user?.id,
    "status": "",
    "message": ""
  })
  const [loading, setLoading] = useState(false)
  const handleChange = (e) => {
    setFields(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
  const handleSubmit = async () => {
    setLoading(true)
    await addUpdateNotificationTemplate({
      body: fields,
      templateId: fields?.id
    }).then(result => {
      if (result?.data?.status === 200) {
        setRefreshTemp(Math.random())
        toast.success(result?.data?.message);
        handleClose()
      } else {
        toast.error(result?.data?.message);
      }
    })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    if (tempData) {
      setFields(tempData)
    }
  }, [tempData])

  return (
    <div className="automaticaly-content manual-content manual-content-popup">

      <div className="auto-options ">
        <FloatingLabel controlId="floatingInput" label="Template Name">
          <Form.Control
            type="text"
            placeholder="Template Name"
            name="templateName"
            value={fields?.templateName}
            onChange={handleChange}
          />
        </FloatingLabel>
      </div>
      <div className="add-languages">
        <div className="lang-box">
          <p>en-US</p>
        </div>
        <ButtonWrapper
          text="Add Language"
          icon={<i className="las la-plus-square"></i>}
        />
      </div>
      <div className="auto-options auto-options-align">
        <FloatingLabel controlId="floatingInput" label="Notification Title">
          <Form.Control
            type="text"
            placeholder="Notification Title"
            name="notificationTitle"
            value={fields?.notificationTitle}
            onChange={handleChange}
          />
        </FloatingLabel>
        <SwitchBox
          label={"Showin Orders"}
          name={'showinOrders'}
          toggleFlag={fields?.showinOrders === "Yes" ? true : false}
          handleToggle={item => {
            setFields(prevState => ({
              ...prevState,
              showinOrders: item === true ? 'Yes' : 'No'
            }))
          }}
        />
        <SwitchBox
          label={"Show in KYC"}
          toggleFlag={fields?.showinKyc === "Yes" ? true : false}
          handleToggle={item => {
            setFields(prevState => ({
              ...prevState,
              showinKyc: item === true ? 'Yes' : 'No'
            }))
          }}
        />
        <SwitchBox
          label={"Auto Send (Email)"}
          toggleFlag={fields?.autoSendMail === "Yes" ? true : false}
          handleToggle={item => {
            setFields(prevState => ({
              ...prevState,
              autoSendMail: item === true ? 'Yes' : 'No'
            }))
          }}
        />
      </div>
      <div className="editor-section notification-editor">
        <GlobalEditor
          editorRef={editorRef}
          textValue={fields?.message}
          handleEditorChange={item => {
            setFields(prevState => ({
              ...prevState,
              message: item
            }))
          }}

        />
      </div>
      <div className="auto-btn">
        <ButtonWrapper loading={loading} text={tempData ? "Update" : "Save"} handleClick={handleSubmit} />
      </div>
    </div>
  );
};
