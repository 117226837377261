
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import { useLocation } from "react-router-dom";
import FilterInput from "../global/filterInput";
import FilterDatePicker from "../global/filterDatePicker";

const AffiliateRequestsFilter = () => {
  const location = useLocation();
  const page = location.pathname.split("/")?.[1];

  const currencyOptions = [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
  ].map((value, id) => ({ id, value }));

  return (
    <div className="voucher-filter">
      <div className={`filter-section ${page}`}>     
        <FilterInput label={"User ID"} name={"UserID"} />
        <FilterDatePicker label={"Request Date From"} name={"RequestDateFrom"} />
        <FilterDatePicker label={"Request Date To"} name={"RequestDateTo"} />
        <SelectInput defaultOption={"Pending"} listArray={currencyOptions} />
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </div>
  );
};

export default AffiliateRequestsFilter;
