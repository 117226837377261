import { useState } from "react";
import GlobalTable from '../../global/globalTable';


const MainPageTable = () => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "ID" },
      { field: "Position" },
      { field: "Lang" },
      { field: "	Banner ALT" },
      { field: "Banner Device" },
      { field: "Image" },
      { field: "Banner Title" },
      { field: "Opens in new tab" },
      { field: "Url" },
    ]);
    return (
      <div className="deposit-table">
      <div className="top-area">
         <h6>Main Page Banners</h6>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

export default MainPageTable