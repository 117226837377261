import * as yup from "yup";

export const SiteSchema = yup.object({
  userName: yup.string().required("*User Name is required"),
  password: yup.string().required("*Password is required"),
  creatorName: yup.string().required("*User Name is required"),
  domainName: yup.string().required("*Domain Name is required"),
  email: yup
    .string()
    .email("*Please enter valid email")
    .required("*Email is required"),
  phoneNumber: yup.string().required("*Mobile number is required"),
  dbName: yup.string().required("*Database Name is required"),
  dbHost: yup.string().required("*Database Host is required"),
  dbPassword: yup.string().required("*Databse Password is required"),
  dbUserName: yup.string().required("*Databse User Name is required"),
  dbPort: yup.string().required("*Databse Port is required"),
});

export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
        "Password must be alpa numeric"
      ),
  })
  .required();

export const ResetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const RegitrationSchema = yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z ]*$/, "Please Enter Valid Name"),
  lastName: yup.string().matches(/^[A-Za-z]*$/, "Please Enter Valid Name"),
  // .required("Last Name is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  verifyPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
      "Password must be alpa numeric"
    ),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const ResetPasswordCheckSchema = yup.object({
  verifyPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password is invalid."
    ),
});
