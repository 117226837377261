export const countries = [
  { value: "Afghanistan", name: "AF" },
  { value: "Åland Islands", name: "AX" },
  { value: "Albania", name: "AL" },
  { value: "Algeria", name: "DZ" },
  { value: "American Samoa", name: "AS" },
  { value: "AndorrA", name: "AD" },
  { value: "Angola", name: "AO" },
  { value: "Anguilla", name: "AI" },
  { value: "Antarctica", name: "AQ" },
  { value: "Antigua and Barbuda", name: "AG" },
  { value: "Argentina", name: "AR" },
  { value: "Armenia", name: "AM" },
  { value: "Aruba", name: "AW" },
  { value: "Australia", name: "AU" },
  { value: "Austria", name: "AT" },
  { value: "Azerbaijan", name: "AZ" },
  { value: "Bahamas", name: "BS" },
  { value: "Bahrain", name: "BH" },
  { value: "Bangladesh", name: "BD" },
  { value: "Barbados", name: "BB" },
  { value: "Belarus", name: "BY" },
  { value: "Belgium", name: "BE" },
  { value: "Belize", name: "BZ" },
  { value: "Benin", name: "BJ" },
  { value: "Bermuda", name: "BM" },
  { value: "Bhutan", name: "BT" },
  { value: "Bolivia", name: "BO" },
  { value: "Bosnia and Herzegovina", name: "BA" },
  { value: "Botswana", name: "BW" },
  { value: "Bouvet Island", name: "BV" },
  { value: "Brazil", name: "BR" },
  { value: "British Indian Ocean Territory", name: "IO" },
  { value: "Brunei Darussalam", name: "BN" },
  { value: "Bulgaria", name: "BG" },
  { value: "Burkina Faso", name: "BF" },
  { value: "Burundi", name: "BI" },
  { value: "Cambodia", name: "KH" },
  { value: "Cameroon", name: "CM" },
  { value: "Canada", name: "CA" },
  { value: "Cape Verde", name: "CV" },
  { value: "Cayman Islands", name: "KY" },
  { value: "Central African Republic", name: "CF" },
  { value: "Chad", name: "TD" },
  { value: "Chile", name: "CL" },
  { value: "China", name: "CN" },
  { value: "Christmas Island", name: "CX" },
  { value: "Cocos (Keeling) Islands", name: "CC" },
  { value: "Colombia", name: "CO" },
  { value: "Comoros", name: "KM" },
  { value: "Congo", name: "CG" },
  { value: "Congo, The Democratic Republic of the", name: "CD" },
  { value: "Cook Islands", name: "CK" },
  { value: "Costa Rica", name: "CR" },
  { value: "Cote D'Ivoire", name: "CI" },
  { value: "Croatia", name: "HR" },
  { value: "Cuba", name: "CU" },
  { value: "Cyprus", name: "CY" },
  { value: "Czech Republic", name: "CZ" },
  { value: "Denmark", name: "DK" },
  { value: "Djibouti", name: "DJ" },
  { value: "Dominica", name: "DM" },
  { value: "Dominican Republic", name: "DO" },
  { value: "Ecuador", name: "EC" },
  { value: "Egypt", name: "EG" },
  { value: "El Salvador", name: "SV" },
  { value: "Equatorial Guinea", name: "GQ" },
  { value: "Eritrea", name: "ER" },
  { value: "Estonia", name: "EE" },
  { value: "Ethiopia", name: "ET" },
  { value: "Falkland Islands (Malvinas)", name: "FK" },
  { value: "Faroe Islands", name: "FO" },
  { value: "Fiji", name: "FJ" },
  { value: "Finland", name: "FI" },
  { value: "France", name: "FR" },
  { value: "French Guiana", name: "GF" },
  { value: "French Polynesia", name: "PF" },
  { value: "French Southern Territories", name: "TF" },
  { value: "Gabon", name: "GA" },
  { value: "Gambia", name: "GM" },
  { value: "Georgia", name: "GE" },
  { value: "Germany", name: "DE" },
  { value: "Ghana", name: "GH" },
  { value: "Gibraltar", name: "GI" },
  { value: "Greece", name: "GR" },
  { value: "Greenland", name: "GL" },
  { value: "Grenada", name: "GD" },
  { value: "Guadeloupe", name: "GP" },
  { value: "Guam", name: "GU" },
  { value: "Guatemala", name: "GT" },
  { value: "Guernsey", name: "GG" },
  { value: "Guinea", name: "GN" },
  { value: "Guinea-Bissau", name: "GW" },
  { value: "Guyana", name: "GY" },
  { value: "Haiti", name: "HT" },
  { value: "Heard Island and Mcdonald Islands", name: "HM" },
  { value: "Holy See (Vatican City State)", name: "VA" },
  { value: "Honduras", name: "HN" },
  { value: "Hong Kong", name: "HK" },
  { value: "Hungary", name: "HU" },
  { value: "Iceland", name: "IS" },
  { value: "India", name: "IN" },
  { value: "Indonesia", name: "ID" },
  { value: "Iran, Islamic Republic Of", name: "IR" },
  { value: "Iraq", name: "IQ" },
  { value: "Ireland", name: "IE" },
  { value: "Isle of Man", name: "IM" },
  { value: "Israel", name: "IL" },
  { value: "Italy", name: "IT" },
  { value: "Jamaica", name: "JM" },
  { value: "Japan", name: "JP" },
  { value: "Jersey", name: "JE" },
  { value: "Jordan", name: "JO" },
  { value: "Kazakhstan", name: "KZ" },
  { value: "Kenya", name: "KE" },
  { value: "Kiribati", name: "KI" },
  { value: "Korea, Democratic People'S Republic of", name: "KP" },
  { value: "Korea, Republic of", name: "KR" },
  { value: "Kuwait", name: "KW" },
  { value: "Kyrgyzstan", name: "KG" },
  { value: "Lao People'S Democratic Republic", name: "LA" },
  { value: "Latvia", name: "LV" },
  { value: "Lebanon", name: "LB" },
  { value: "Lesotho", name: "LS" },
  { value: "Liberia", name: "LR" },
  { value: "Libyan Arab Jamahiriya", name: "LY" },
  { value: "Liechtenstein", name: "LI" },
  { value: "Lithuania", name: "LT" },
  { value: "Luxembourg", name: "LU" },
  { value: "Macao", name: "MO" },
  { value: "Macedonia, The Former Yugoslav Republic of", name: "MK" },
  { value: "Madagascar", name: "MG" },
  { value: "Malawi", name: "MW" },
  { value: "Malaysia", name: "MY" },
  { value: "Maldives", name: "MV" },
  { value: "Mali", name: "ML" },
  { value: "Malta", name: "MT" },
  { value: "Marshall Islands", name: "MH" },
  { value: "Martinique", name: "MQ" },
  { value: "Mauritania", name: "MR" },
  { value: "Mauritius", name: "MU" },
  { value: "Mayotte", name: "YT" },
  { value: "Mexico", name: "MX" },
  { value: "Micronesia, Federated States of", name: "FM" },
  { value: "Moldova, Republic of", name: "MD" },
  { value: "Monaco", name: "MC" },
  { value: "Mongolia", name: "MN" },
  { value: "Montserrat", name: "MS" },
  { value: "Morocco", name: "MA" },
  { value: "Mozambique", name: "MZ" },
  { value: "Myanmar", name: "MM" },
  { value: "Namibia", name: "NA" },
  { value: "Nauru", name: "NR" },
  { value: "Nepal", name: "NP" },
  { value: "Netherlands", name: "NL" },
  { value: "Netherlands Antilles", name: "AN" },
  { value: "New Caledonia", name: "NC" },
  { value: "New Zealand", name: "NZ" },
  { value: "Nicaragua", name: "NI" },
  { value: "Niger", name: "NE" },
  { value: "Nigeria", name: "NG" },
  { value: "Niue", name: "NU" },
  { value: "Norfolk Island", name: "NF" },
  { value: "Northern Mariana Islands", name: "MP" },
  { value: "Norway", name: "NO" },
  { value: "Oman", name: "OM" },
  { value: "Pakistan", name: "PK" },
  { value: "Palau", name: "PW" },
  { value: "Palestinian Territory, Occupied", name: "PS" },
  { value: "Panama", name: "PA" },
  { value: "Papua New Guinea", name: "PG" },
  { value: "Paraguay", name: "PY" },
  { value: "Peru", name: "PE" },
  { value: "Philippines", name: "PH" },
  { value: "Pitcairn", name: "PN" },
  { value: "Poland", name: "PL" },
  { value: "Portugal", name: "PT" },
  { value: "Puerto Rico", name: "PR" },
  { value: "Qatar", name: "QA" },
  { value: "Reunion", name: "RE" },
  { value: "Romania", name: "RO" },
  { value: "Russian Federation", name: "RU" },
  { value: "RWANDA", name: "RW" },
  { value: "Saint Helena", name: "SH" },
  { value: "Saint Kitts and Nevis", name: "KN" },
  { value: "Saint Lucia", name: "LC" },
  { value: "Saint Pierre and Miquelon", name: "PM" },
  { value: "Saint Vincent and the Grenadines", name: "VC" },
  { value: "Samoa", name: "WS" },
  { value: "San Marino", name: "SM" },
  { value: "Sao Tome and Principe", name: "ST" },
  { value: "Saudi Arabia", name: "SA" },
  { value: "Senegal", name: "SN" },
  { value: "Serbia and Montenegro", name: "CS" },
  { value: "Seychelles", name: "SC" },
  { value: "Sierra Leone", name: "SL" },
  { value: "Singapore", name: "SG" },
  { value: "Slovakia", name: "SK" },
  { value: "Slovenia", name: "SI" },
  { value: "Solomon Islands", name: "SB" },
  { value: "Somalia", name: "SO" },
  { value: "South Africa", name: "ZA" },
  { value: "South Georgia and the South Sandwich Islands", name: "GS" },
  { value: "Spain", name: "ES" },
  { value: "Sri Lanka", name: "LK" },
  { value: "Sudan", name: "SD" },
  { value: "Surivalue", name: "SR" },
  { value: "Svalbard and Jan Mayen", name: "SJ" },
  { value: "Swaziland", name: "SZ" },
  { value: "Sweden", name: "SE" },
  { value: "Switzerland", name: "CH" },
  { value: "Syrian Arab Republic", name: "SY" },
  { value: "Taiwan, Province of China", name: "TW" },
  { value: "Tajikistan", name: "TJ" },
  { value: "Tanzania, United Republic of", name: "TZ" },
  { value: "Thailand", name: "TH" },
  { value: "Timor-Leste", name: "TL" },
  { value: "Togo", name: "TG" },
  { value: "Tokelau", name: "TK" },
  { value: "Tonga", name: "TO" },
  { value: "Trinidad and Tobago", name: "TT" },
  { value: "Tunisia", name: "TN" },
  { value: "Turkey", name: "TR" },
  { value: "Turkmenistan", name: "TM" },
  { value: "Turks and Caicos Islands", name: "TC" },
  { value: "Tuvalu", name: "TV" },
  { value: "Uganda", name: "UG" },
  { value: "Ukraine", name: "UA" },
  { value: "United Arab Emirates", name: "AE" },
  { value: "United Kingdom", name: "GB" },
  { value: "United States", name: "US" },
  { value: "United States Minor Outlying Islands", name: "UM" },
  { value: "Uruguay", name: "UY" },
  { value: "Uzbekistan", name: "UZ" },
  { value: "Vanuatu", name: "VU" },
  { value: "Venezuela", name: "VE" },
  { value: "Viet Nam", name: "VN" },
  { value: "Virgin Islands, British", name: "VG" },
  { value: "Virgin Islands, U.S.", name: "VI" },
  { value: "Wallis and Futuna", name: "WF" },
  { value: "Western Sahara", name: "EH" },
  { value: "Yemen", name: "YE" },
  { value: "Zambia", name: "ZM" },
  { value: "Zimbabwe", name: "ZW" },
];
