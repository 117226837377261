import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import { getPlayerData } from "../../utils/Apis";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";

const UserTable = ({ recall, filter }) => {
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [pageSize, setPageSize] = useState("20");
  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
  ];
  const [rowData, setRowData] = useState([]);
  const getAllPlayerData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        };
       
        setLoading(true);
        const res = await getPlayerData(config);
        setLoading(false);

        if (res) {
          const transformData = res.data?.playerData.map((item) => ({
            ...item,
            id: parseInt(item?.id),
            iskycCompleted: item?.iskycCompleted ? "Verified" : "Not Verified",
            brand: item?.brand ? item?.brand : "---",
            firstName: item?.firstName ? item?.firstName : "---",
            lastName: item?.lastName ? item?.lastName : "---",
            mobile: item?.mobile ? item?.mobile : "---",
            pin: item?.pin ? item?.pin : "---",
            wallet: item?.wallet ? item?.wallet : "---",
            USD: item?.USD ? item?.USD : "---",
            dob: item?.dob ? item?.dob : "---",
            country: item?.country ? item?.country : "---",
            parentUser: item?.parentUser ? item?.parentUser : "---",
            lastLoginIP: item?.lastLoginIP ? item?.lastLoginIP : "---",
            regDate: item?.regDate ? item?.regDate : "---",
            lastLogin: item?.lastLogin ? item?.lastLogin : "---",
          }));
          setRowData(transformData);
          setPages(res?.data?.pages);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPlayerData(page, pageSize);
    // eslint-disable-next-line
  }, [pageSize, page, recall, isLoading]);

  // const [columnDefstable] = useState([
  //   { field: "Brand", width: "100px" },
  //   {
  //     field: "User ID",
  //     width: "120px",
  //     sortable: true,
  //     sortingOrder: ["asc", "desc"],
  //     sort: "desc",
  //   },
  //   { field: "User", width: "100px" },
  //   { field: "First Name", width: "120px" },
  //   { field: "Last Name", width: "100px" },
  //   { field: "Email", width: "100px" },
  //   { field: "Mobile", width: "100px" },
  //   { field: "Pin", width: "100px" },
  //   { field: "Balance", width: "100px" },
  //   { field: "Balance (USD)", width: "130px" },
  //   { field: "KYC Status", width: "120px" },
  //   {
  //     field: "Reg Date",
  //     width: "150px",
  //     sortable: true,
  //     sortingOrder: ["asc", "desc"],
  //     sort: "desc",
  //   },
  //   { field: "Birthdate", width: "100px" },
  //   { field: "Country", width: "100px" },
  //   {
  //     field: "Parent User Name",
  //     width: "150px",
  //     sortable: true,
  //     sortingOrder: ["asc", "desc"],
  //     sort: "desc",
  //   },
  //   {
  //     field: "Last Login",
  //     width: "100px",
  //     sortable: true,
  //     sortingOrder: ["asc", "desc"],
  //     sort: "desc",
  //   },
  //   { field: "Last Login Address", width: "150px" },
  // ]);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Brand",
      field: "brand",
      width: "120px",
      cellStyle: { color: "grey", left: "15px" },
    },
    {
      headerName: "User ID",
      field: "id",
      width: "120px",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "asc",
      enableRowGroup: true,
      enablePivot: true,
      cellStyle: { color: "#4caf50", "text-align": "center" },
    },
    {
      headerName: "User",
      field: "userName",

      width: "200px",
      cellStyle: { color: "#4caf50", "text-align": "left" },
    },
    {
      headerName: "First Name",
      field: "firstName",
      width: "110px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Last Name",
      field: "lastName",
      width: "110px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Email",
      field: "email",

      width: "280px",
      cellStyle: { "text-align": "left" },
    },
    {
      headerName: "Mobile",
      field: "mobile",
      width: "150px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Pin",
      field: "pin",
      width: "100px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Balance",
      field: "wallet",
      width: "120px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Balance (USD)",
      field: "USD",
      width: "150px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "KYC Status",
      field: "iskycCompleted",
      width: "120px",
      cellStyle: { color: "red", cellStyle: { "text-align": "center" } },
    },
    {
      headerName: "Reg Date",
      field: "regDate",
      width: "150px",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Birthdate",
      field: "dob",
      width: "150px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Country",
      field: "country",
      width: "150px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Parent User Name",
      field: "parentUser",
      width: "250px",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Last Login",
      field: "lastLogin",
      width: "150px",
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Last Login Ip Address",
      field: "lastLoginIP",
      width: "250px",
      cellStyle: { "text-align": "center" },
    },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Users</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : "20");
            }}
          />

          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
export default UserTable;
