import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";

const Filter = () => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];
  return (
    <div className="voucher-filter">
      <div className="filter-section filter-pd">
        <SelectInput defaultOption={"User Group"} listArray={Currencyoptions} />
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </div>
  );
};
export default Filter;
