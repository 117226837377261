import { Button } from "react-bootstrap";
import Layout from "../layout/layout.jsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BonusStepHeader from "./bonusStepHeader.jsx";
import BonusStepContent from "./bonusStepContent.jsx";
import "./bonus.css";
const CreateBonus = () => {
  return (
    <Layout title="CREATE BONUS">
      <div className="Bonus-template-page">
        <div className="d-flex">
          <Button className="bonus-btn">
            <ArrowBackIcon />
            <span className="bonus-btn-text"> Back To Templates</span>
          </Button>
          <BonusStepHeader />
        </div>
        <BonusStepContent />
      </div>
      <div className="step-footer">
        <Button className="bonus-btn">
          <ArrowBackIcon />
          <span className="bonus-btn-text"> Back</span>
        </Button>
        <Button className="bonus-btn-next">NEXT</Button>
      </div>
    </Layout>
  );
};
export default CreateBonus;
