import { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import GlobalTable from "../../global/globalTable";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import "./userGroup.css";
import { addUpdateRiskUserGroup, getAllRiskControlUserGroups } from "../../../utils/Apis";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserGroupPopup from "./userPopup";
import { useMyContect } from "../../../context/myContext";

const UserGroup = () => {
  const { isLoading,} = useMyContect();
  const [filter, setFilter] = useState({
      "id":"",
      "name": "",
      "brand":localStorage.getItem("brand"),
      "color":"#0f0b0b",
  })
  const [ showPopup , setShowPopup] = useState("");
  const [loading, setLoading] = useState(false);
  const[isClick,setIsClick]=useState(false);
  
  const handleChange = (e) => {
    const value = e.target.value;
    setFilter(prevState => ({
      ...prevState,
      [e.target.name]: value
    }));
  }

  const handleSubmit = async () => {
    setLoading(true);
    setIsClick(true);
    try {
      const result = await addUpdateRiskUserGroup({
        body: filter,
        groupId: filter?.id
      });
      if (result?.data?.status === 200) {
        await getData(); 
        toast.success(result?.data?.message);
        // Call getData() only when status is 200
      } else {
        toast.error(result?.data?.message);
      }
    } catch (error) {
      console.error('Error:', error);

    } finally {
      setFilter({ ...filter, name: "" });
      setLoading(false);
      setIsClick(false);
    }
  }
  

  const handleClose=()=>{
    setShowPopup("");
  }

  const [data, setData] = useState([])
  // eslint-disable-next-line
  const [page, setPage] = useState(1)
  // eslint-disable-next-line
  const [pages, setPages] = useState('');
  
  const getData = async () => {
    console.log("jgfg");
    setLoading(true);
    try {
      setData([])
      const result = await getAllRiskControlUserGroups({ page });

  
      setData(result?.data?.data);
      setPages(result?.data?.pages);
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  
 useEffect(()=>{
  getData()
// eslint-disable-next-line
 },[isLoading])
  return (
    <Layout title={"USER GROUP"}>
      <div className="affiliate-list-page user-group-page">
        <GroupFilter 
        handleChange={handleChange}
          filter={filter}
          handleSubmit={handleSubmit}
          loading={loading}
          isClick={isClick}
          />
          <UserGroupPopup socialPopup={showPopup} handleClose={handleClose} getData={getData}/>
        <GroupTable data={data} loading={loading} setShowPopup={setShowPopup} isLoading={isLoading}/>
      </div>
    </Layout>
  );
};
export default UserGroup;

const GroupFilter = ({handleChange,filter,handleSubmit,isClick}) => {
  return (
    <div className="voucher-filter group-filter">
      <div className="filter-section">
        <FloatingLabel controlId="floatingInput" label="Name">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="name"
            onChange={handleChange}
            value={filter?.name}
          />
          <div className="color-picker">
            <input type="color" name="color" onChange={handleChange} value={filter?.color}/>
          </div>
        </FloatingLabel>
        <ButtonWrapper text={"Add"} clValue="search-filter-btn"  loading={isClick}  handleClick={handleSubmit}/>
      </div>
    </div>
  );
};

const GroupTable = ({data,loading, setShowPopup,isLoading}) => {
  const [columnDefs] = useState([
    { field: "ID",valueGetter: params => params.data['id'] },
    { field: " Name",valueGetter: params => params.data['name']  },
    { field: "Brand" ,valueGetter: params => params.data['brand'] },
    { field: "Color", valueGetter: params => params.data['color']  },
    {
      field: "Action",
      headerName: "Action",
      cellRenderer: () => <Actions setShowPopup={setShowPopup} />,
    },
  ]);

  return (
    <div className="deposit-table">
      <h2>Groups</h2>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : data?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
           <GlobalTable columnDefs={columnDefs} rowData={data} />
        </>
      )}
      
    </div>
  );
};

const Actions = ({ setShowPopup }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = (value) => {
    setFlag(!flag);
    setShowPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action ms" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
