import React from "react";
import UploadImage from "../../global/uploadImage";
import CreateIcon from "@mui/icons-material/Create";
import ButtonWrapper from "../../global/button";
import { FloatingLabel, Form } from "react-bootstrap";
const GeneralData = () => {
  let leftListData = [
    {
      id: 1,
      value: "LOGO",
    },
    {
      id: 1,
      value: "LOGO 2X",
    },
    {
      id: 1,
      value: "LOGO FOR MOBILE",
    },
    {
      id: 1,
      value: "FAV ICON",
    },
    {
      id: 1,
      value: "LOADER",
    },
    {
      id: 1,
      value: "ICON FOR SHORTCUTS",
    },
  ];
  let rightListData = [
    {
      id: 1,
      value: "TITLE",
    },
    {
      id: 1,
      value: "DESCRIPTION",
    },
    {
      id: 1,
      value: "KEYWORDS",
    },
    {
      id: 1,
      value: "COPYRIGHT",
    },
    {
      id: 1,
      value: "EMAIL",
    },
    {
      id: 1,
      value: "PHONE NUMBER",
    },
    {
      id: 1,
      value: "WORKING HOURS",
    },
    {
      id: 1,
      value: "ADDRESS",
    },
  ];
  return (
    <div className="content-data">
      <div className="content-item">
        <div className="content-item-left">
          <ul className="list">
            {leftListData.map((item) => {
              return (
                <li>
                  <div className="list-text">{item.value}</div>
                  <div className="d-flex align-items-center ms-auto content-width">
                    <UploadImage label="No Image" />
                    <CreateIcon />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="content-item-right">
          <ul className="list">
            {rightListData.map((item) => {
              return (
                <li>
                  <div className="list-text">{item.value}</div>
                  <div className="d-flex align-items ms-auto  mb-0 content-width ">
                    <FloatingLabel controlId="floatingInput" label=" ">
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="userid"
                      />
                    </FloatingLabel>
                    <ButtonWrapper text="Save" clValue="search-filter-btn" />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GeneralData;
