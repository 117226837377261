import { useState } from "react";
import GlobalTable from '../../global/globalTable';

const StatisticsTable = () => {
    const [rowData] = useState([]);
  
    const columnDefs = [
      { field: "ID", },
      { field: "User Name" },
      { field: "Parent Affiliate Name" },
      { field: "First Name" },
      { field: "Last Name" },
      { field: "Email" },
      { field: "Phone" },
      { field: "Currency" },
      { field: "Bet Amount" },
      { field: "Win Amount" },
      { field: "Profit" },
      { field: "Bet Count" },
      { field: "Win Count" },
      { field: "Probability" },
    ];
  
    return (
      <div className="deposit-table">
      <h2>User Game Statistics</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default StatisticsTable