import { useState } from "react";
import GlobalTable from '../../global/globalTable';

const StatisticsTable = () => {
    const [rowData] = useState([]);
  
    const columnDefs = [
      { field: "User ID",width:"100px" },
      { field: "User Name",width:"100px", },
      { field: "Currency" },
      { field: "Balance",width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Deposit",width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Withdraw",width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Payment Diff",width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Bet",sortable:true,width:"100px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Win",sortable:true,width:"100px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Total Profit",width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "NGR",sortable:true,width:"100px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Casino Bet",width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Casino Win",sortable:true, width:"150px",sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Casino Profit",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "LiveCasino Bet",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "LiveCasino Win",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "LiveCasino Profit",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Sport Bet",sortable:true,width:"120px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Sport Win", width:"150px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Sport Profit",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Other Bet",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Other Win",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
      { field: "Other Profit",sortable:true,width:"150px", sortingOrder: ["asc", "desc"],
      sort: "desc" },
    ];
  
    return (
      <div className="deposit-table">
      <h2>Vendor Statistics</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default StatisticsTable