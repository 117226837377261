import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";
import FilterInput from "../../global/filterInput";
import FilterDatePicker from "../../global/filterDatePicker";

const TransactionFilter = ({ setFilter, filter, setRecall }) => {
  let optionsType = [
    {
      id: 1,
      value: "bet",
    },
    {
      id: 1,
      value: "win",
    },
    {
      id: 1,
      value: "loss",
    },
  ];

  // let Currencyoptions = [
  //   {
  //     id: 1,
  //     value: "USD",
  //   },
  //   {
  //     id: 1,
  //     value: "AED",
  //   },
  //   {
  //     id: 1,
  //     value: "AFN",
  //   },
  //   {
  //     id: 1,
  //     value: "ALL",
  //   },
  //   {
  //     id: 1,
  //     value: "AMD",
  //   },
  //   {
  //     id: 1,
  //     value: "ANG",
  //   },
  //   {
  //     id: 1,
  //     value: "AOA",
  //   },
  //   {
  //     id: 1,
  //     value: "ARS",
  //   },
  //   {
  //     id: 1,
  //     value: "AUD",
  //   },
  // ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(filter);
  };

  const handleDateChange = (name, date) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  return (
    <div className="filter-section-user translation-page">
      <div className="filter-section mb-filter">
        <FilterInput
          label="User ID"
          name="userId"
          handleChange={handleChange}
          value={filter?.userId}
        />
        <FilterInput
          label="Transaction ID"
          name="id"
          value={filter?.id}
          handleChange={handleChange}
        />
        <SelectInput
          defaultOption={"Operation Type"}
          listArray={optionsType}
          handleSelectOption={handleChange}
          name={"actionType"}
          selectedValue={filter?.actionType}
        />
        {/* <SelectInput
          defaultOption={"Game Vendor"}
          listArray={Currencyoptions}
        /> */}
        {/* <SelectInput defaultOption={"Game Type"} listArray={Currencyoptions} /> */}
      </div>
      <div className="filter-section mb-filter">
        <FilterInput
          label="Game ID"
          name="gameId"
          handleChange={handleChange}
        />
        <FilterDatePicker
          label="Start Date"
          name="fromDate"
          flag={true}
          value={filter?.fromDate}
          handleDateChange={handleDateChange}
        />
        <FilterDatePicker
          label="End Date"
          name="toDate"
          flag={true}
          value={filter?.toDate}
          handleDateChange={handleDateChange}
        />
        {/* <SelectInput
          defaultOption={"System Currency"}
          listArray={Currencyoptions}
        /> */}
        <FilterInput
          label="Amount From"
          name="fromAmount"
          value={filter?.fromAmount}
          handleChange={handleChange}
        />
        <FilterInput
          label="Amount To"
          name="toAmount"
          handleChange={handleChange}
          value={filter?.toAmount}
        />
      </div>
      <div className="filter-section justify-content-end gap-3 pt-2 mb-filter">
        <ButtonWrapper
          text={"Search"}
          clValue="search-filter-btn"
          handleClick={() => {
            setRecall(Math.random());
          }}
        />
        <ButtonWrapper
          text={"Clear All"}
          clValue="clear-btn"
          handleClick={() => {
            setFilter({
              userId: "",
              transactionId: "",
              gameId: "",
              actionType: "",
              fromAmount: "",
              toAmount: "",
              fromDate: "",
              toDate: "",
            });
            setRecall(Math.random());
          }}
        />
      </div>
    </div>
  );
};
export default TransactionFilter;
