import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
const DatePickerInput = ({ label, date, setDate }) => {
  const [startDate, setStartDate] = useState();
  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };
  const formatDateTime = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-');
    const formattedTime = dateObject.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit'
    });
    return `${formattedDate} ${formattedTime}`;
  };
  const handleChange = (data) => {
    const formattedDateTime = formatDateTime(data);
    setStartDate(formattedDateTime);
    setDate(formattedDateTime)
  };
  const range = (start, end, step) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };
  const getYear = (date) => date.getFullYear();
  const years = range(1990, getYear(new Date()) + 1, 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonth = (date) => date.getMonth();

  useEffect(()=>{
    if(date){
      setStartDate(date)
    }
  }, [date])
  return (
    <DatePicker
      className="form-control datePickerInput"
      popperClassName="some-custom-class"
      // popperPlacement="top-end"
      popperModifiers={[
        {
          name: "offset",
          options: {
            offset: [5, 10],
          },
        },
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "viewport",
            tether: false,
            altAxis: true,
          },
        },
      ]}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            marginTop: 5,
            marginBottom: 5,
            display: "flex",
            justifyContent: "space-between",
          }}
          className="datepicker-filter">
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            <ArrowLeftIcon />
          </button>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            className="month-select">
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className="month-select"
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}>
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            <ArrowRightIcon />
          </button>
        </div>
      )}
      showTimeSelect
      timeFormat="HH:mm"
      // showTimeInput
      onChange={handleChange}
      timeClassName={handleColor}
      customInput={
        <FloatingLabel controlId="floatingInput" label={label}>
          <Form.Control
            type="text"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
            value={startDate ? startDate : ""}
          />
        </FloatingLabel>
      }
    />
  );
};

export default DatePickerInput;
