import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadImage = ({ label, onImageUpload, id, acceptFile }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const acceptedTypes = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".svg"];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName
        .substring(fileName.lastIndexOf("."))
        .toLowerCase();
      if (!acceptedTypes.includes(fileExtension)) {
        alert(
          "Please select a file with one of the following extensions: .jpg, .jpeg, .png, .gif, .bmp, .svg"
        );
        onImageUpload(null);
      } else {
        onImageUpload(file);
      }
    }
  };
  return (
    <div className="upload-image">
      <label htmlFor={id} className="upload-image-box">
        {label}
        {label === "No Image" ? (
          ""
        ) : (
          <span className="cloud-icon">
            <CloudUploadIcon />
          </span>
        )}
      </label>
      <input
        type="file"
        id={id}
        onChange={handleFileChange}
        accept={acceptFile}
      />
    </div>
  );
};

export default UploadImage;
