import React, {  useEffect, useState } from "react";
import Layout from "../layout/layout";
import { Button } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import EditIcon from "@mui/icons-material/Edit";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ToggleSwitch from "../global/toggleSwitch";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VerifyInfoPopup from "./verifyInfoPopup";
import VerifyPopup from "./verifyPopup";
import { useMyContect } from "../../context/myContext";
import { emailVerified, verifyEmail } from "../../utils/Apis";
import toast from "react-hot-toast";
import "./profile.css";
const Profile = () => {
  const { user } = useMyContect();
  const [dataArr, setDataArr] = useState([
    { field: "User Name", value: user?.userName, event: " " },
    { field: "First Name", value: user?.name, event: " " },
    { field: "Last Name", value: user?.lastName, event: " " },
    { field: "Creator Name", value: "asd", event: " " },
    { field: "Email", value: user?.email, event: "VERIFY" },
    { field: "Mobile", value: user?.mobile, event: " " },
    { field: "QR Authorization", value: " ", event: "ENABLE" },
    { field: "Authorization Type", value: " ", event: "Edit" },
    { field: "Trader", value: "No", event: " " },
    { field: "Blocked", value: "No", event: " " },
    { field: "last time online", value: " ", event: " " },
    { field: "Time Zone", value: "Time Zone", event: " " },
    { field: "Mute Notifications", value: "Mute Notifications", event: "" },
  ]);

  useEffect(() => {
    setDataArr((prevDataArr) =>
      prevDataArr.map((item) => {
        switch (item.field) {
          case "User Name":
            return { ...item, value: user?.userName };
          case "Email":
            return { ...item, value: user?.email };
          case "First Name":
            return { ...item, value: user?.firstName };
          case "Last Name" :
            return  {...item, value: user?.lastName};
          case "Mobile" :
            return  {...item, value: user?.mobile};
          default:
            return item;
        }
      })
    );
  }, [user]);
  const [passwordArr] = useState([
    { field: "Current Password", value: "Current Password", event: " " },
    { field: "New Password", value: "New Password", event: "Edit" },
    { field: "Repeat Password", value: "Repeat Password", event: "Edit" },
  ]);

  const [brandArr] = useState([
    "ChangeAffiliatePercent (ID: 974343)",
    "DashBoardRegistrations (ID: 974344)",
    "DashBoardDeposits (ID: 974345)",
    "Customers (ID: 974346)",
    "AffiliateManager (ID: 974347)",
    "ContentManager (ID: 974348)",
    "Translations (ID: 974349)",
    "FinancialManager (ID: 974350)",
    "Dashboard (ID: 974351)",
    "Reports (ID: 974352)",
    "PaymentManager (ID: 974353)",
    "KYC Form Manager (ID: 974354)",
    "ViewUserSensitiveData (ID: 974355)",
    "BonusTemplateManagement (ID: 974356)",
    "RiskControl (ID: 974357)",
    "Notifications (ID: 974358)",
    "SystemTransactions (ID: 974359)",
    "export (ID: 974361)",
    "ViewEmail (ID: 974360)",
  ]);
  const [flag, setFlag] = useState("");
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [sendCode,setSendCode]=useState(false);
  const handleVerifyEmail = async(value) => {
    if(sendCode) return; 
    if (flag === "") {
      setFlag(value);
    } else {
      setFlag("");
    }

    if(value==="VERIFY" && !user.isEmailVerify){
    try{
      setSendCode(true);
      const response = await verifyEmail({email:user.email});
      if(response?.data?.status===200 ){
        toast.success(response?.data?.message)
        setSendCode(false);
       }
    }catch(err){
      console.log(err);
    }
    }
  };
    useEffect(() => {
      if(user?.isEmailVerify===false){
        setVerifyPopup(true);
      } else{
        setVerifyPopup(false);
      }
      // eslint-disable-next-line
   }, []);
 
  const popupClose = () => {
    setVerifyPopup(false);
  };
  return (
    <Layout title="Profile">
      <div className="profile-page">
        <div className="profile-section">
          <p className="profile-title">Information</p>
          <ul className="profile-list">
            {dataArr.map((item) => {
              return (
                <li>
                  <div
                    className={` desc ${
                      item.field === "Mute Notifications" ? "mute-left" : ""
                    }`}
                  >
                    {item.field}
                  </div>
                  <div
                    className={` val ${
                      item.field === "Mute Notifications" ? "mute-right" : ""
                    }`}
                  >
                    {item.value === "Time Zone" ? (
                      <SelectInput defaultOption="GMT +7:00" />
                    ) : item.value === "Mute Notifications" ? (
                      <div className="profile-mute-section">
                        <span className="audio-on">
                          <VolumeUpIcon />
                        </span>
                        <ToggleSwitch />
                        <span className="audio-mute">
                          <VolumeOffIcon />
                        </span>
                      </div>
                    ) : (
                      item.value
                    )}
                    <span
                     className={`${
                      item.event === "Edit" ? "edit-icon" : ""
                    }${
                      item.event === "VERIFY"  && user?.isEmailVerify===true ? "verify-icon" : ""
                    }
                    ${
                      item.event === "VERIFY" && user?.isEmailVerify===false ? "not-verify-icon" : ""
                    }`}
                    
                      onClick={() => handleVerifyEmail(item.event)}
                    >
                      {item.event === "Edit" && <EditIcon /> }
                      {item.event === "VERIFY" && user?.isEmailVerify===true &&  "Verified"}
                      {item.event === "VERIFY" && user?.isEmailVerify===false &&  item.event}


                    </span>
                  </div>
                  {flag === "VERIFY" &&
                    item.field === "Email" &&
                    user.isEmailVerify===false &&
                    <RenderEmailVerify />}
                  {flag === "Edit" &&
                    item.field === "Authorization Type" &&
                    renderEditType()}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="profile-section">
          <p className="profile-title">Change Password</p>
          <ul className="profile-list">
            {passwordArr.map((item) => {
              return (
                <li>
                  <div className="desc">{item.field}</div>
                  <div className="val ">
                    <input type="password" placeholder="***************" />
                    <span
                      className={`${
                        item.event === "Edit" ? "edit-icon" : "verification"
                      } `}
                    >
                      {item.event === "Edit" ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        item.event
                      )}
                    </span>
                  </div>
                  {false && item.field === "Email" && <RenderEmailVerify />}
                  {false &&
                    item.field === "Authorization Type" &&
                    renderEditType()}
                </li>
              );
            })}
            <div className="password-btn">
              <div className="filter-section">
                <Button>Save</Button>
              </div>
            </div>
          </ul>
        </div>
        <div className="profile-section profile-brand-section">
          <p className="profile-title">Brand</p>
          <ul className="profile-list">
            <li
              onClick={() => {
                handleVerifyEmail("Metabet777");
              }}
              className={`${flag === "Metabet777" ? "active" : ""}`}
            >
              <div className="desc profile-brand">ID: 81</div>
              <div className="val ">Metabet777</div>
              {flag === "Metabet777" && (
                <div className="roles">
                  {brandArr.map((item) => {
                    return <p>{item}</p>;
                  })}
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
      {!user?.isEmailVerify &&  <VerifyInfoPopup
        show={flag === "ENABLE"}
        handleClose={handleVerifyEmail}
      />}
     {!user?.isEmailVerify && <VerifyPopup show={verifyPopup} handleClose={popupClose} /> }
    </Layout>
  ); 
};
export default Profile;
const RenderEmailVerify = () => {
  const [verifyinput,setVerifyInput]=useState("");

 const inputHandler=(e)=>{
  setVerifyInput(e.target.value);
 }
 const verifyEmailCode=async()=>{
   try{
    const response = await emailVerified({emailVerificationCode:verifyinput});
     if(response?.data?.status===200){
      toast.success(response?.data?.message)
     }
   }catch(err){
     console.log(err);
   }
 }
  return (
    <div className="profile-verification">
      <div className="profile-verification-data">
        <p>Email verification code is sent to your email address</p>
        <div className="filter-section">
          <input placeholder="Code" type="number"  onChange={(e)=>{inputHandler(e)}} />
          <Button onClick={verifyEmailCode}>Verify</Button> {/* Call handleButtonClick on button click */}
        </div>
      </div> 
    </div>
  );
};

const renderEditType = () => (
  <div className="profile-verification profile-verification_authorization">
    <div className="profile-edit ">
      <span>Type</span>
      <SelectInput defaultOption="Select Channel" />
    </div>
    <div className="profile-edit profile-edit_bottom">
      <Button className="btn-red">Cancel</Button>
      <Button className="btn-green">Change</Button>
    </div>
  </div>
);
