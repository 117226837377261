import { FloatingLabel, Form, Tab, Tabs } from "react-bootstrap";
import Layout from "../layout/layout";
import ButtonWrapper from "../global/button";
import { useEffect, useLayoutEffect, useState } from "react";
import GlobalTable from "../global/globalTable";
import SelectInput from "../global/selectInput";
import GroupAllowedPopup from "./groupAllowed";
import RestrictedPopup from "./restrictedPopup";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "./payment.css";
import {
  getAllPaymentRestrictionGroups,
  getAllPaymentRestrictionUsers,
  listOfCPU,
} from "../../utils/Apis";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";

const PaymentRestrction = () => {
  const { data, setData } = useMyContect();
  const [showPopup, setShowPopup] = useState("");

  const handlePopup = (value) => {
    setShowPopup(value);
    if (!value) {
      setData("");
    }
  };

  const [refreshGroup, setRefreshGroup] = useState("");
  const [refreshUser, setRefreshUser] = useState("");
  const [tab, setTab] = useState("Group Allowed Payment");

  useEffect(() => {
    if (data && tab === "Group Allowed Payment") {
      handlePopup("groupPayment");
    }
    if (data && tab === "User Restricted Payment") {
      handlePopup("restrictedPayment");
    }
    /* eslint-disable */
  }, [data, tab]);

  const [cpu, setCUP] = useState([]);

  useLayoutEffect(() => {
    listOfCPU().then((result) => {
      if (result?.status === 200) {
        setCUP(result?.data?.data);
      }
    });
  }, []);
  // console.log("cpu", cpu);
  return (
    <Layout title="PAYMENT RESTRICTIONS">
      <div className="payment-method-page payment-restriction">
        <div className="tabs-section">
          <Tabs
            defaultActiveKey="Group Allowed Payment"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(key) => {
              setTab(key);
              setData("");
            }}
          >
            <Tab eventKey="Group Allowed Payment" title="Group Allowed Payment">
              <GroupAllowedPayment
                handlePopup={handlePopup}
                refreshGroup={refreshGroup}
                cpu={cpu}
              />
            </Tab>
            <Tab
              eventKey="User Restricted Payment"
              title="User Restricted Payment"
            >
              <UserRestrictedPayment
                handlePopup={handlePopup}
                refreshUser={refreshUser}
                cpu={cpu}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
      <GroupAllowedPopup
        show={showPopup === "groupPayment"}
        handleClose={handlePopup}
        setRefreshGroup={setRefreshGroup}
        groupdata={data}
        cpu={cpu}
      />
      <RestrictedPopup
        show={showPopup === "restrictedPayment"}
        handleClose={handlePopup}
        setRefreshUser={setRefreshUser}
        userData={data}
        cpu={cpu}
      />
    </Layout>
  );
};
export default PaymentRestrction;

const GroupAllowedPayment = ({ handlePopup, refreshGroup, cpu }) => {
  // const [rowData] = useState([
  //   {
  //     ID: "Toyota",
  //     "Payment Provider Name": "Celica",
  //     "Payment Channel Name	": 35000,
  //     Direction: 0,
  //     "Group Name": 5,
  //     "Creator Name": 0,
  //     "	Create Date": 4,
  //   },
  // ]);

  const [columnDefs] = useState([
    { field: "ID", width: 100 },
    { field: "Payment Provider Name", width: 200 },
    // { field: "Payment Channel Name	" },
    { field: "Direction", width: 200 },
    { field: "Group Name", width: 200 },
    { field: "Creator Name", width: 200 },
    { field: "Create Date", width: 200 },
  ]);
  const { isLoading } = useMyContect();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    await getAllPaymentRestrictionGroups({
      page,
      body: filter,
    })
      .then((result) => {
        let tableData = result?.data?.data?.map((item) => ({
          ID: item.id,
          "Payment Provider Name": item?.paymentProvider || "---",
          "Payment Channel Name	": item.paymentChannel || "---",
          Direction: item.deposit || "---",
          "Group Name": item.userProfileGroup || "---",
          "Creator Name": item.creatorName || "---",
          "Create Date": item.createdAt || "---",
        }));
        setData(tableData);
        setPages(result?.data?.pages);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    /*eslint-disable */
  }, [page, refreshGroup, isLoading]);

  const handleChange = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="group-allowed-payment">
      <div className="add-paymentgroup-btn">
        <ButtonWrapper
          text={"Add Group Allowed Payments"}
          handleClick={() => handlePopup("groupPayment")}
          icon={
            <span className="add-icon">
              <AddBoxIcon />
            </span>
          }
        />
      </div>
      <div className="filter-section provider-inp">
        <SelectInput
          defaultOption={"Payment Provider Name"}
          listArray={cpu?.providers}
          name={"paymentProvider"}
          selectedValue={filter?.paymentProvider}
          handleSelectOption={handleChange}
        />
        <SelectInput
          defaultOption={"User Profile Group"}
          listArray={cpu?.usergroups}
          name={"userProfileGroup"}
          selectedValue={filter?.userProfileGroup}
          handleSelectOption={handleChange}
        />

        <ButtonWrapper
          text={"Search"}
          clValue="search-filter-btn"
          handleClick={getData}
        />
      </div>
      <div className="deposit-table">
        <h2>Group Allowed Payments</h2>
        {loading ? (
          <div className="spinner my-5"></div>
        ) : data?.length === 0 ? (
          <h4 className="text-center my-5">No Data Found</h4>
        ) : (
          <GlobalTable
            columnDefs={columnDefs}
            rowData={data}
            pageCss={"aghader"}
          />
        )}
        <Pagination
          currentPage={page}
          totalPages={pages}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
};

const UserRestrictedPayment = ({ handlePopup, refreshUser, cpu }) => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
  ];
  // const [rowData] = useState([
  //   {
  //     ID: "Toyota",
  //     "Payment Provider Name": "Celica",
  //     "Payment Channel Name	": 35000,
  //     Direction: 0,
  //     UserName: 5,
  //     "User Profile ID": 0,
  //     "Creator Name": 4,
  //     "Create Date": "",
  //   },
  // ]);

  const [columnDefs] = useState([
    { field: "ID" },
    { field: "Payment Provider Name" },
    // { field: "Payment Channel Name	" },
    { field: "Direction" },
    { field: "UserName" },
    { field: "User Profile ID" },
    { field: "Creator Name" },
    { field: "Create Date" },
  ]);
  const { isLoading } = useMyContect();
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    await getAllPaymentRestrictionUsers({
      page,
      body: filter,
    })
      .then((result) => {
        let tableData = result?.data?.data?.map((item) => ({
          ID: item.id,
          "Payment Provider Name": item.paymentProvider || "---",
          // "Payment Channel Name	": item.paymentChannel,
          Direction: item.deposit || "---",
          UserName: item.userName || "---",
          "User Profile ID": item.userId || "---",
          "Creator Name": item.creatorName || "---",
          "Create Date": item.createdAt || "---",
        }));
        setData(tableData);
        setPages(result?.data?.pages);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    /*eslint-disable */
  }, [page, refreshUser, isLoading]);

  const handleChange = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="user-restricted-payment">
      <div className="add-paymentgroup-btn">
        <ButtonWrapper
          handleClick={() => {
            handlePopup("restrictedPayment");
            // setData("");
          }}
          text={"Add User Restricted Payment"}
          icon={
            <span className="add-icon">
              <AddBoxIcon />
            </span>
          }
        />
      </div>
      <div className="filter-section">
        <SelectInput
          defaultOption={"Payment Provider Name"}
          listArray={cpu?.providers}
          name={"paymentProvider"}
          selectedValue={filter?.paymentProvider}
          handleSelectOption={handleChange}
        />
        <FloatingLabel controlId="floatingInput" label="User Profile ID">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userId"
            value={filter?.userId}
            onChange={handleChange}
          />
        </FloatingLabel>
        <ButtonWrapper
          text={"Search"}
          clValue="search-filter-btn"
          handleClick={getData}
        />
      </div>
      <div className="deposit-table">
        <h2>User Restricted Payment</h2>
        {loading ? (
          <div className="spinner my-5"></div>
        ) : data?.length === 0 ? (
          <h4 className="text-center my-5">No Data Found</h4>
        ) : (
          <GlobalTable columnDefs={columnDefs} rowData={data} />
        )}
        <Pagination
          currentPage={page}
          totalPages={pages}
          onPageChange={setPage}
        />
      </div>
    </div>
  );
};
