import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SwitchBox from "../global/switchBox";
import ButtonWrapper from "../global/button";
import { createPaymentChannel, deletePaymentChannel } from "../../utils/Apis";
import { useEffect, useState } from "react";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";

const AddChannelPopup = ({
  show,
  handleClose,
  addPaymentType,
  recall,
  setRecall,
}) => {
  const { data, setIsLoading, isLoading } = useMyContect();
  const [paymentChannel, setPaymentChannel] = useState({
    name: "",
    enabled: false
  })
  const [error , setError] = useState({
    name: false,
    enabled: false
  })

  useEffect(()=>{
    if (addPaymentType === "Edit" && data){
      setPaymentChannel({
        name: data.name,
        enabled: data.enabled === "Yes"
      })
    }else{
      setPaymentChannel({
        name: "",
        enabled: false
      })
    }
  }, [data, addPaymentType])
  const handleChange = (e) => {
    setPaymentChannel({
      ...paymentChannel,
      name: e.target.value,
    });
    console.log(paymentChannel);
  };

  const handleToggle = () => {
    setPaymentChannel({
      ...paymentChannel,
      enabled: !paymentChannel.enabled,
    });
    console.log(paymentChannel);
  };

  const handleDelete = async() => {
    const token = localStorage.getItem("secretDomainKey");
    try {
      if (token) {
        setIsLoading(true);
        const res = await deletePaymentChannel(data?.id)
        console.log(res);
        if (res) {
          toast.success(res.data.message)
          setIsLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
      toast.error(error.message)
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePaymentChannel = async () => {
    try {
      let channelData = paymentChannel;
      channelData = {
        ...channelData,
        enabled: channelData.enabled ? "Yes" : "No",
      };

      const checkError = {
        name: paymentChannel.name ===""
       };
       setError(checkError);
       if (Object.values(checkError).some((error) => error)) {
         return;
       }

      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        setIsLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body:
            data && addPaymentType === "Edit"
              ? {
                  ...channelData,
                  id: data.id,
                }
              : channelData,
        };
        console.log(config);

        const res = await createPaymentChannel(config);
        console.log(res);
        if (res) {
          toast.success(res.data.message)
          setIsLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error(error.message)
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-provider-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {addPaymentType === "Delete"
            ? "Delete"
            : addPaymentType === "Edit"
            ? "Update"
            : "Create"}{" "}
          Channel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addPaymentType === "Delete" ? (
          <div className="ms-2">
            <p>Are you sure you want to delete? </p>
            <div className="auto-btn">
            <ButtonWrapper
              handleClick={handleDelete}
              text={`${isLoading ? "Deleting..." : "Delete"}`}
            />
            </div>
           
          </div>
        ) : (
          <div className="tabs-section">
            <div className="row-one">
              <FloatingLabel controlId="floatingInput" label="Provider Name">
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="name"
                  value={paymentChannel.name}
                  onChange={handleChange}
                />
                {error.name &&  <div className="text-danger">Provider Name is required</div> }
              </FloatingLabel>


              <SwitchBox
                label={"Enabled"}
                handleToggle={handleToggle}
                toggleFlag={paymentChannel.enabled}
              />
            </div>
            <div className="payment-btn">
              <ButtonWrapper
                text={`${
                  addPaymentType === "Edit"
                    ? isLoading
                      ? "Updating..."
                      : "Update"
                    : isLoading
                    ? "Creating..."
                    : "Create"
                }`}
                handleClick={handleCreatePaymentChannel}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AddChannelPopup;
