import { AttachMoney, GetApp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const DepositWithdraw = () => {
  const navigate=useNavigate();
  const handleNavigate =()=>{
    navigate("/orders");
  }
  return (
    <div className="deposit-withdraw-section">
      <div className="deposit-request" onClick={handleNavigate}>
        <AttachMoney />
        <span>5</span>
        <h6>Deposit Requests</h6>
      </div>
      <div className="deposit-request withdraw-request" onClick={handleNavigate}>
      <GetApp />
        <span>0</span>
        <h6>Withdraw Request</h6>
      </div>
    </div>
  );
};
export default DepositWithdraw;
