import { useState } from "react";
import Layout from "../../layout/layout";
import "./siteContent.css";
import Filter from "./filter.jsx";
import { Button } from "react-bootstrap";
import SiteContenttable from "./siteContenttable.jsx";
import SiteContentPopup from "./siteContentPopup.jsx";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


const SiteContent = () => {
  const [activeTab, setActiveTab] = useState("Testimonials");
  const [recall, setRecall] = useState(0);
  const [columnDefs, setColumnDefs] = useState([
    { field: "Position", width: 150 },
    {
      field: "Testimonial Name",
      width: 180,
    },
    {
      field: "Language",
      width: 180,
    },
    { field: "Company", width: 180 },
    {
      field: "Testimonial Text",
      width: 180,
    },
    {
      field: "Action",
      cellRenderer: () => <Actions setSitepopup={setSitepopup} />,
    },
  ]);
  const handleTabs = (value) => {
    setActiveTab(value.title);
    setColumnDefs(value.tblData);
  };

  const [sitePopup, setSitepopup] = useState();

  const handlePopup = (value) => {
    if (value !== " ") {
      setSitepopup(value);
    } else {
      setSitepopup(" ");
    }
  };

  return (
    <Layout title="SITE CONTENT">
      <div className="notification-page payment-method-page content-gap-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs notification-first global-limit">
            <ul>
              <li
                onClick={() =>
                  handleTabs({
                    title: "Testimonials",
                    tblData: [
                      { field: "Position", width: 180 },
                      { field: "Testimonial Name", width: 180 },
                      { field: "Language", width: 180 },
                      { field: "Company", width: 180 },
                      { field: "Testimonial Text", width: 180 },
                      {
                        field: "Actions",
                        cellRenderer: () => (
                          <Actions setSitepopup={setSitepopup} />
                        ),
                      },
                    ],
                  })
                }
                className={`${
                  activeTab === "Testimonials" ? "active-tab" : ""
                }`}
                role="presentation"
              >
                Testimonials
              </li>
              <li
                onClick={() =>
                  handleTabs({
                    title: "News",
                    tblData: [
                      { field: "Title", width: 180 },
                      { field: "Slug", width: 180 },
                      { field: "Seo Title", width: 180 },
                      { field: "Meta Description", width: 180 },
                      { field: "Small Image", width: 180 },
                      { field: "Large Image", width: 180 },
                      { field: "Short Description", width: 180 },
                      { field: "Detailed Text", width: 180 },

                      {
                        field: "Actions",
                        cellRenderer: () => (
                          <Actions setSitepopup={setSitepopup} />
                        ),
                      },
                    ],
                  })
                }
                className={`${activeTab === "News" ? "active-tab" : ""}`}
                role="presentation"
              >
                News
              </li>
              <li
                onClick={() =>
                  handleTabs({
                    title: "Promotions",
                    tblData: [
                      { field: "language" },
                      { field: "Class" },
                      { field: "position" },
                      {
                        field: "name",
                        width: "150px",
                        cellStyle: { "text-align": "center" },
                      },
                      { field: "Small Image" },
                      { field: "Large Image" },
                      {
                        field: "bannerAlt",
                        width: "150px",
                        cellStyle: { "text-align": "center" },
                      },
                      { field: "title" },
                      {
                        field: "fullTitle",
                        width: "150px",
                        cellStyle: { "text-align": "center" },
                      },
                      { field: "detailedText" },
                      {
                        field: "Actions",
                        cellRenderer: () => (
                          <Actions setSitepopup={setSitepopup} />
                        ),
                      },
                    ],
                  })
                }
                className={`${activeTab === "Promotions" ? "active-tab" : ""}`}
                role="presentation"
              >
                Promotions
              </li>
              <li
                onClick={() =>
                  handleTabs({
                    title: "Create Promotions Class",
                    tblData: [
                      { headerName: "ID", field: "id", width: 100 },
                      { headerName: "Name", field: "name", width: 150 },
                      {
                        headerName: "Actions",
                        cellRenderer: () => (
                          <Actions setSitepopup={setSitepopup} />
                        ),
                      },
                    ],
                  })
                }
                className={`${
                  activeTab === "Create Promotions Class" ? "active-tab" : ""
                }`}
                role="presentation"
              >
                Create Promotions Class
              </li>
            </ul>
            {activeTab === "Testimonials" ? (
              <Button
                className="send-notificaton"
                onClick={() => {
                  handlePopup("addTestimonials");
                }}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>
                Add Testimonials
              </Button>
            ) : activeTab === "News" ? (
              <Button
                className="send-notificaton"
                onClick={() => {
                  handlePopup("addNews");
                }}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add News
              </Button>
            ) : activeTab === "Promotions" ? (
              <Button
                className="send-notificaton"
                onClick={() => {
                  handlePopup("ceratePromo");
                }}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add Promotions
              </Button>
            ) : (
              " "
            )}
          </div>
        </div>
        <Filter activeTab={activeTab} setRecall={setRecall} />
        <SiteContenttable
          activeTab={activeTab}
          setSitepopup={setSitepopup}
          columnDefs={columnDefs}
          recall={recall}
        />
        <SiteContentPopup
          show={sitePopup}
          sitePopup={sitePopup}
          handleClose={handlePopup}
          activeTab={activeTab}
          setRecall={setRecall}
        />
      </div>
    </Layout>
  );
};
export default SiteContent;
const Actions = ({ setSitepopup }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = (value) => {
    setFlag(!flag);
    setSitepopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
      {/* <EditPaymentMethod show={flag} handleClose={handlePoppup} /> */}
    </>
  );
};
