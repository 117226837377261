import { Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
const VerifyPopup = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup add-social-popup comment-popup authenticator-popup verify-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verify Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section ">
          <p>Please, VERIFY your Email address and Mobile number</p>
          <div className="comment-filter authenticator-filter verify-code">
            <ButtonWrapper text="OK" handleClick={handleClose} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default VerifyPopup;
