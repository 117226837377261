import { useState } from "react";
import Pagination from "../../../utils/Pagination";
import GlobalTable from "../../global/globalTable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Modal } from "react-bootstrap";
const BannerTable = ({ columnDefs, rowData, page, pages, setPage, loader }) => {
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Banners</h6>
      </div>

      {loader ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs.map((colDef) =>
              colDef.field === "bannerImage"
                ? { ...colDef, cellRenderer: RenderImage }
                : colDef
            )}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const RenderImage = (params) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const handleDownload = () => {
    const splitArr = params?.value.split("/");
    const fileName = splitArr[splitArr.length - 1];
    fetch(params?.value)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      <img
        className="table-image"
        role="button"
        onClick={handleClick}
        src={params.value}
        alt="Social Icon"
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        centered
        // className="notification-popup add-payment-popup add-commission-popup"
      >
        <Modal.Body>
          {/* {console.log("image value>>>>", params?.value)} */}
          <img
            className="image-preview"
            src={params?.value}
            alt="Social Icon"
          />
          <div className="text-center mt-2">
            {/* Add a download button */}
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                handleDownload();
              }}
            >
              Download <CloudDownloadIcon />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BannerTable;
