import {
  AccountBalanceWallet,
  AttachMoney,
  CardGiftcard,
  CompareArrows,
  Dashboard,
  Group,
  Message,
  Notifications,
  PanTool,
  Payments,
  Report,
  Wallpaper,
} from "@mui/icons-material";

export const navItemsArr = [
  {
    text: "Dashboard",
    url: "/dashboard",
    icon: <Dashboard />,
  },
  {
    text: "Users",
    url: "/users",
    icon: <Group />,
  },
  {
    text: "Orders",
    url: "/orders",
    icon: <AccountBalanceWallet />,
  },
  {
    text: "Notifications",
    url: "/notifications",
    icon: <Notifications />,
  },
  {
    text: "Payments",
    url: "",
    icon: <Payments />,
    subMenu: [
      {
        text: "Payment Methods",
        url: "/payment-methods",
      },
      {
        text: "Vouchers",
        url: "/voucher",
      },
      {
        text: "Payment Restrictions",
        url: "/payment-restriction",
      },
    ],
  },
  {
    text: "System Transactions",
    url: "/system-transaction",
    icon: <CompareArrows />,
  },
  {
    text: "Affiliate",
    url: "",
    icon: <AttachMoney />,
    subMenu: [
      {
        text: "Affiliate List",
        url: "/affiliate-list",
      },
      {
        text: "Requests",
        url: "/affiliate-requests",
      },
      {
        text: "Media Templates",
        url: "/media-templates",
      },
      {
        text: "Register Affiliates",
        url: "/register-affiliates",
      },
      {
        text: "Commission Stages",
        url: "/commission-stages",
      },
      {
        text: "Report",
        url: "/affiliate-report",
      },
      {
        text: "Affilate Users Reg & Deposits",
        url: "/affiliate-deposit",
      },
      {
        text: "Affilate Report",
        url: "/affiliate-user-report",
      },
      {
        text: "Monthly Payment",
        url: "/monthly-payment",
      },
    ],
  },
  {
    text: "Risk Control",
    url: "",
    icon: <PanTool />,
    subMenu: [
      {
        text: "User Group",
        url: "/user-group",
      },
      {
        text: "Bet Limits",
        url: "/bet-limit",
      },
      {
        text: " Transactions",
        url: "/transaction",
      },
      {
        text: "Login Log",
        url: "/login-log",
      },
      {
        text: "KYC Forms",
        url: "/kyc-Form",
      },
      {
        text: "KYC Verification",
        url: "/KycVerification",
      },
    ],
  },
  {
    text: "Report",
    url: "",
    icon: <Report />,
    subMenu: [
      {
        text: "General Statistics",
        url: "/general-statistics",
      },
      {
        text: "Vendor Statistics",
        url: "/vendor-statistics",
      },
      {
        text: "User Statistics",
        url: "/user-statistics",
      },
      {
        text: "User Game Statistics",
        url: "/usergame-statistics",
      },
      {
        text: "Game Statistics",
        url: "/game-statistics",
      },
      {
        text: "Registration Statistics",
        url: "/registration",
      },
      {
        text: "Authorization Statistics",
        url: "/authorization",
      },
      {
        text: "Cashdesk Statistics",
        url: "/cashdesk",
      },
      {
        text: "Performance Statistics",
        url: "/performance-statistics",
      },
      {
        text: "Payment Statistics",
        url: "/paymentStatistics",
      },
      {
        text: "First Depositors",
        url: "/firstDeposit",
      },
      {
        text: "Country Statistics",
        url: "/countryStatistics",
      },
      {
        text: "Bonus Report",
        url: "/bonusReport",
      },
      {
        text: "Bonus Template Report",
        url: "/BonusTemplateReport",
      },
      {
        text: "Activity Statistics",
        url: "/activityStatistics",
      },
      {
        text: "Top Users Report",
        url: "/topUserReport",
      },
      {
        text: "Brand Balances",
        url: "/brandBalance",
      },
      {
        text: "User Daily Balances",
        url: "/userDailyBalance",
      },
      {
        text: "Admin Comments",
        url: "/adminComment",
      },
      {
        text: "Admin IP Info",
        url: "/ip-info",
      },
      {
        text: "Total Deposit",
        url: "/total-deposit",
      },
    ],
  },
  {
    text: "Contents",
    url: "",
    icon: <Wallpaper />,
    subMenu: [
      {
        text: "Site Content",
        url: "/siteContent",
      },
      {
        text: "Translations",
        url: "/translation",
      },
      {
        text: "Site Texts",
        url: "/siteText",
      },
      {
        text: "Banners",
        url: "/banners",
      },
      {
        text: "Popups",
        url: "/contentPopup",
      },
      {
        text: "Main Page",
        url: "/mainPage",
      },
      {
        text: "Social",
        url: "/social",
      },
      {
        text: "Scripts",
        url: "/scripts",
      },
      {
        text: "General Content",
        url: "/generalContent",
      },
      {
        text: "Static Pages",
        url: "",
      },
      {
        text: "Push Notifications",
        url: "/pushNotification",
      },
    ],
  },
  {
    text: "Bonuses",
    url: "/bonus",
    icon: <CardGiftcard />,
  },
  {
    text: "Comments",
    url: "/comments",
    icon: <Message />,
  },
  {
    text: "Admin Management",
    icon: <Group />,
    subMenu: [
      {
        text: "Admin list",
        url: "/addSiteContent"
      },
      {
        text: "Add Admin",
        url: "/create-site",
      },
    ],
  },
];
