import Filter from "./filter.jsx";
import Layout from "../../layout/layout";
import GeneralData from "./generalData.jsx";
import "./generalContent.css";
const GeneralContent = () => {
  return (
    <Layout title="GENERAL CONTENT">
      <div className="notification-page payment-method-page">
        <Filter />
        <GeneralData />
      </div>
    </Layout>
  );
};
export default GeneralContent;
