import ButtonWrapper from "../../global/button";
import FilterInput from "../../global/filterInput";

const Filter = () => {
  return (
    <div className="voucher-filter">
      <div className="filter-section filter-pd">
        <FilterInput label="User ID" name="UserID"/>
        <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
      </div>
    </div>
  );
};
export default Filter;
