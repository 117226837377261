import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SwitchBox from "../global/switchBox";
import ButtonWrapper from "../global/button";
import UploadImage from "../global/uploadImage";
import { useMyContect } from "../../context/myContext";
import { useEffect, useState } from "react";
import { createPaymentGroup, deletePaymentGroup } from "../../utils/Apis";
import toast from 'react-hot-toast';

const AddGroupPopup = ({
  show,
  handleClose,
  addPaymentType,
  setRecall,
  recall,
}) => {
  const { data, setIsLoading, isLoading } = useMyContect();
  const [paymentGroup, setPaymentGroup] = useState({
    name: "",
    enabled: false,
    smallImage: null,
    largeImage: null,
  });

  const [error, setError] = useState({
    name: false,
    enabled: false,
    smallImage: false,
    largeImage: false,
  });
  useEffect(() => {
    if (addPaymentType === "Edit" && data) {
      setPaymentGroup({
        name: data.name,
        enabled: data.enabled === "Yes",
        smallImage: null,
        largeImage: null
      });
    } else {
      setPaymentGroup({
        name: "",
        enabled: false,
        smallImage: null,
        largeImage: null,
      });
    }
  }, [data, addPaymentType]);

  const handleChange = (e) => {
    setPaymentGroup({
      ...paymentGroup,
      name: e.target.value,
    });
    console.log(paymentGroup);
  };

  const handleToggle = () => {
    setPaymentGroup({
      ...paymentGroup,
      enabled: !paymentGroup.enabled,
    });
    console.log(paymentGroup);
  };

  const handleSmallImageUpload = (file) => {
    setPaymentGroup((prevState) => ({
      ...prevState,
      smallImage: file,
    }));
  };

  const handleLargeImageUpload = (file) => {
    console.log("large", file)
    setPaymentGroup((prevState) => ({
      ...prevState,
      largeImage: file,
    }));
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("secretDomainKey");
    try {
      if (token) {
        setIsLoading(true);
        const res = await deletePaymentGroup(data?.id);
        console.log(res);
        if (res) {
          toast.success(res.data.message)
          setIsLoading(false);
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found")
      }
    } catch (error) {
      toast.error(error.message)
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePaymentGroup = async () => {
    try {
      const formData = new FormData();
      console.log(paymentGroup)

      formData.append("name", paymentGroup.name);
      formData.append("enabled", paymentGroup.enabled ? "Yes" : "No");
      formData.append("smallImage", paymentGroup.smallImage);
      formData.append("largeImage", paymentGroup.largeImage);
      const token = localStorage.getItem("secretDomainKey");
      const checkError = {
        name: paymentGroup.name === "",
        smallImage: paymentGroup.smallImage === null,
        largeImage: paymentGroup.largeImage === null,
      };
      setError(checkError);
      if (Object.values(checkError).some((error) => error)) {
        return;
      }
      if (token) {
        setIsLoading(true);
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
        };
        console.log(config);

        const res = await createPaymentGroup(formData, data?.id);
        console.log(res);

        if (res) {
          setIsLoading(false);
          toast.success('Successfully created!');
          setRecall(recall + 1);
          handleClose();
          console.log("Response is getting", res);
        }
      } else {
        console.log("No token found");
        toast.error("Token Not Found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-payment-popup add-provider-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {addPaymentType === "Delete"
            ? "Delete"
            : addPaymentType === "Edit"
            ? "Update"
            : "Create"}{" "}
          Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addPaymentType === "Delete" ? (
          <>
            <div className="">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="auto-btn">
              <ButtonWrapper
                text={isLoading ? "Deleting..." : "Delete"}
                disable={isLoading}
                handleClick={handleDelete}
              />
            </div>
          </>
        ) : (
          <div className="tabs-section">
            <div className="row-one">
              <FloatingLabel controlId="floatingInput" label="Provider Name">
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="name"
                  value={paymentGroup.name}
                  onChange={handleChange}
                />
                {error.name && (
                  <div className="text-danger">Provider Name is required</div>
                )}
              </FloatingLabel>
              <SwitchBox
                label={"Enabled"}
                handleToggle={handleToggle}
                toggleFlag={paymentGroup.enabled}
              />
            </div>
            <div className="row-one">
              <UploadImage
                label={"Small Image"}
                id="smallImage"
                onImageUpload={handleSmallImageUpload}
              />
              {error.smallImage && (
                <div className="text-danger">Small Image is required</div>
              )}
              <UploadImage
                label={"Large Image"}
                id="largeImage"
                onImageUpload={handleLargeImageUpload}
              />
              {error.largeImage && (
                <div className="text-danger">Large Image is required</div>
              )}
            </div>
            <div className="payment-btn">
              <ButtonWrapper
                text={`${
                  addPaymentType === "Edit"
                    ? isLoading
                      ? "Updating..."
                      : "Update"
                    : isLoading
                    ? "Creating..."
                    : "Create"
                }`}
                handleClick={handleCreatePaymentGroup}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AddGroupPopup;
