import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import { countries } from "./countries";

const Filter = ({ setRecall, setFilter, filter }) => {
  let readyToTransfer = [
    {
      id: 1,
      value: "Yes",
    },
    {
      id: 2,
      value: "No",
    },
  ];
  let Comments = [
    {
      id: 1,
      value: "Yes",
    },
    {
      id: 2,
      value: "No",
    },
  ];
  let KycStatus = [
    {
      id: 1,
      value: "Yes",
    },
    {
      id: 2,
      value: "No",
    },
  ];
  let paymentMode = [
    {
      id: 1,
      value: "Manual",
    },
    {
      id: 2,
      value: "Automatic",
    },
  ];
  let paymentStatus = [
    {
      id: 1,
      value: "Checked",
    },
    {
      id: 2,
      value: "Not Checked",
    },
  ];

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
    
  };

  return (
    <div className="filter-section">
      <SelectInput
        defaultOption={"Ready to Transfer"}
        listArray={readyToTransfer}
      />
      <SelectInput defaultOption={"Comments"} listArray={Comments} />
      <SelectInput defaultOption={"KYC Status"} listArray={KycStatus} />
      <FloatingLabel controlId="floatingInput" label="Amount From">
        <Form.Control
          type="number"
          placeholder=""
          autoComplete="off"
          name="fromAmount"
          value={filter?.fromAmount}
          onChange={handleChange}
        />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInput" label="Amount To">
        <Form.Control
          type="number"
          placeholder=""
          autoComplete="off"
          name="toAmount"
          value={filter?.toAmount}
          onChange={handleChange}
        />
      </FloatingLabel>
      {/* <FilterInput
          type={"number"}
          label={"User Id"}
          name={"userId"}
          value={filter.userId}
          handleChange={handleChange}
        /> */}
      <FloatingLabel controlId="floatingInput" label="User Id">
        <Form.Control
          type="number"
          placeholder="name@example.com"
          autoComplete="off"
          name="userId"
          value={filter?.userId}
          onChange={handleChange}
        />
      </FloatingLabel>
      <SelectInput defaultOption={"Payment Mode"} listArray={paymentMode} />
      <SelectInput defaultOption={"Country"} listArray={countries} />
      <SelectInput defaultOption={"Group"} />
      <FloatingLabel controlId="floatingInput" label="Payment Provider">
        <Form.Control
          type="text"
          placeholder=""
          autoComplete="off"
          name="firstname"
          disabled
        />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInput" label="Payment Channels">
        <Form.Control
          type="text"
          placeholder=""
          autoComplete="off"
          name="lastname"
          disabled
        />
      </FloatingLabel>
      <SelectInput defaultOption={"Payment Status"} listArray={paymentStatus} />
      <FloatingLabel controlId="floatingInput" label="NGR From">
        <Form.Control
          type="text"
          placeholder=""
          autoComplete="off"
          name="mobile"
        />
      </FloatingLabel>
      <FloatingLabel controlId="floatingInput" label="NGR To">
        <Form.Control
          type="text"
          placeholder=""
          autoComplete="off"
          name="mobilecode"
        />
      </FloatingLabel>
      <ButtonWrapper
        text={"Search"}
        clValue="search-filter-btn"
        handleClick={() => {
          setRecall(Math.random());
        }}
      />
      {/* <ButtonWrapper
        text="Clear All"
        clValue="clear-btn"
        handleClick={() => {
          setFilter({
            userId: "",
            fromAmount: "",
            toAmount: "",
          });
          setRecall(Math.random());
        }}
      /> */}
    </div>
  );
};
export default Filter;
