import { createContext, useContext, useState } from "react";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const [updateSiteData, setupdateSiteData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isRefresh, setIsRefresh] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [brand, setBrand] = useState([])

  console.log("isLoading inside from the context", isLoading);
  return (
    <MyContext.Provider
      value={{
        user,
        setUser,
        updateSiteData,
        setupdateSiteData,
        data,
        setData,
        setIsLoading,
        isLoading,
        isRefresh,
        setIsRefresh,
        brand, setBrand,
        isLogin, setIsLogin
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useMyContect = () => {
  return useContext(MyContext);
};
