import { useState, useEffect } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import "./userGroup.css";
import { useMyContect } from "../../../context/myContext";
import { addUpdateRiskUserGroup, deleteRiskControlUserGroups } from "../../../utils/Apis";
import ButtonWrapper from "../../global/button";
import ToggleSwitch from "../../global/toggleSwitch";
const UserGroupPopup = ({ handleClose, socialPopup,getData }) => {
  const { data} = useMyContect();
  const [loading , setLoading] = useState(false) 
  const [field, setField] = useState({ 
  "name": data?.name,
  "brand":data?.brand,
  "color":data?.color,
  "isBet":data?.isBet,
  "isCanDeposit":data?.isCanDeposit,
  "isBonusAllowed":data?.isBonusAllowed,
  "isCanWithdraw":data?.isCanWithdraw,
  "isBlocked":data?.isBlocked
});

  const handleCreate = async () => {
    try {  
      setLoading(true);
        const res = await addUpdateRiskUserGroup({
          body: field,
          groupId: data?.id
        })
        if (res?.data?.status === 200) {
            toast.success("You Have Updated successfully User Group", {
              toastId: "update",
            });
            handleClose();
            setLoading(false);
            await getData();
        } else {
          toast.error("Something went Wrong Plase try Again", {
            toastId: "tryAgain",
          });
        }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true); 
      const res=await deleteRiskControlUserGroups(data?.id);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted Testimonial", {
            toastId: "toats",
          });
          setLoading(false);
          handleClose();
          await getData(); 
        }
       else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setField({ ...field, [name]: value });
  };

  const handleToggle = (name) => {
    setField({ ...field, [name]: !field[name] });
  };
  
console.log("socialPopup=====>",socialPopup)
  useEffect(() => {
    if (socialPopup?.includes("Edit")) {
      setField({...field,
        "name": data?.name,
        "brand":data?.brand,
        "color":data?.color,
        "isBet":data?.isBet,
        "isCanDeposit":data?.isCanDeposit,
        "isBonusAllowed":data?.isBonusAllowed,
        "isCanWithdraw":data?.isCanWithdraw,
        "isBlocked":data?.isBlocked
      });
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Modal
      show={!!socialPopup}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup add-social-popup comment-popup`}>
      <Modal.Header closeButton>
      <Modal.Title>
      {socialPopup !== "Delete"?"Edit":"Delete"} Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section user-group-page">
          <div className="comment-filter user-group-filter">
            {socialPopup !== "Delete" ? (
              <div>
            <div className="filter-section">
              
            <FloatingLabel controlId="floatingInput" label="Name">
              <Form.Control
                type="text"
                placeholder=""
                autoComplete="off"
                name="name"
                onChange={handleChange}
                value={field?.name}
              />
              <div className="color-picker">
                <input type="color" name="color" onChange={handleChange}/>
              </div>
            </FloatingLabel>
            <ButtonWrapper  text={`${"Update"}`}
            loading={loading}
                handleClick={handleCreate} clValue="search-filter-btn"/>
         
          </div>
          <div className="d-flex align-items-center justify-content-between user-grid-popup">
          <div className="filter-section filter-user-group">
          <p>Can bet:</p><ToggleSwitch name="isBet" handleToggle={()=>handleToggle("isBet")} toggleFlag={field?.isBet}/>
          </div>
          <div className="filter-section filter-user-group">
          <p>Can withdraw</p><ToggleSwitch  name="isCanWithdraw" handleToggle={()=>handleToggle("isCanWithdraw")} toggleFlag={field?.isCanWithdraw} />
          </div>
          <div className="filter-section filter-user-group">
          <p>Bonus Allowed</p><ToggleSwitch name="isBonusAllowed" handleToggle={()=>handleToggle("isBonusAllowed")}  toggleFlag={field?.isBonusAllowed}/>
          </div>
          <div className="filter-section filter-user-group">
          <p>Can Deposit</p><ToggleSwitch name="isCanDeposit" handleToggle={()=>handleToggle("isCanDeposit")} toggleFlag={field?.isCanDeposit}/>
          </div>
          <div className="filter-section filter-user-group">
          <p>Blocked</p><ToggleSwitch name="isBlocked" handleToggle={()=>handleToggle("isBlocked")} toggleFlag={field?.isBlocked}/>
          </div>
          </div>
          </div>
          
            ) : (
              <div className="ms-2">
                <p>Are you sure you want to delete this?</p>
              </div>
            )}
            { socialPopup === "Delete" && (
              <ButtonWrapper
                text={`${"Delete"}`}
                loading={loading}
                handleClick={handleDelete}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default UserGroupPopup;
