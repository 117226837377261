import { React, useEffect } from "react";
import Layout from "../../layout/layout";
import { Tab, Tabs } from "react-bootstrap";
import TransactionFilter from "./transactionFilter";
import GlobalTable from "../../global/globalTable";
import { useState } from "react";
import SelectInput from "../../global/selectInput";
import "./transaction.css";
import ExportExcel from "../../global/exportExcel";
import TableColumns from "../../global/tableColumns";
import { getGameTransactionData } from "../../../utils/Apis";
import Pagination from "../../../utils/Pagination";

const Transaction = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("20");
  const [pages, setPages] = useState("");
  const [recall, setRecall] = useState(0);
  const [filter, setFilter] = useState({
    userId: "",
    transactionId: "",
    gameId: "",
    actionType: "",
    fromAmount: "",
    toAmount: "",
    fromDate: "",
    toDate: "",
  });

  const [columnDefs, setColumnDefs] = useState([
    // { field: "index", headerName: "Sr No.", },
    { field: "transactionId", headerName: "Transaction ID" },
    {
      field: "userId",
      headerName: "User ID",
      width: "80px",
      cellStyle: {
        "text-align": "center",
      },
    },
    { field: "brand", headerName: "Brand" },
    { field: "createdAt", headerName: "Date" },
    { field: "actionType", headerName: "Operation" },
    { field: "amount", headerName: "Amount", width: "180px", cellStyle: { "text-align": "right" } },
    { field: "beforeBalance", headerName: "Start Balance", width: "180px", cellStyle: { "text-align": "right" } },
    { field: "afterBalance", headerName: "End Balance", width: "180px", cellStyle: { "text-align": "right" } },
    { field: "", headerName: "Game Provider" },
    { field: "", headerName: "Game Vendor" },
    { field: "", headerName: "Game" },
    { field: "gameId", headerName: "Game ID" },
    { field: "", headerName: "Game Type" },
  ]);

  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );
  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  const handleGetGameTransactionData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      setLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        };
        setRowData([]);
        const res = await getGameTransactionData(config);
        if (res && res.data && res.data?.data) {
          setLoading(false);
          const transformedData = res.data?.data.map((item, index) => ({
            ...item,
            index: index + 1,
            transactionId: item.transactionId ? item.transactionId : "---",
            userId: item.userId ? item.userId : "---",
            amount: typeof item.amount === "number" ? item.amount?.toFixed(2) : "---",
            beforeBalance: typeof item.beforeBalance === "number" ? item.beforeBalance?.toFixed(2) : "---",
            afterBalance: typeof item.afterBalance === "number" ? item.afterBalance?.toFixed(2) : "---",
            brand: localStorage.getItem("brand") || "",
            createdAt: item?.createdAt ? item?.createdAt.split("T")[0] : "---",
            actionType: item?.actionType ? item?.actionType : "---",
          }));
          // console.log(transformedData)
          setPages(res.data.pages);
          setRowData(transformedData);
        }
        console.log("Response is getting", res);
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found in the fetching gameTrancation Data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetGameTransactionData(page, limit);
    // eslint-disable-next-line
  }, [page, limit, recall]);

  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <Layout title="TRANSACTIONS">
      <div className="users-page transaction-page translation-page">
        <div className="tabs-section">
          <Tabs
            defaultActiveKey="Game-Transactions"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Game-Transactions" title="Game Transactions">
              <div className="top-dashboard-section">
                <TransactionFilter
                  setRecall={setRecall}
                  filter={filter}
                  setFilter={setFilter}
                />
              </div>
            </Tab>

            <Tab eventKey="Last Month" title="Archived Game Transactions">
              <div className="top-dashboard-section">
                <TransactionFilter />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* table sexction */}
      <div className="voucher-page users-page system-transaction-page pt-0">
        <div className="deposit-table mt-0">
          <div className="top-area">
            <h6>Transaction</h6>
            <div className="right-area">
              <ExportExcel rowData={rowData} tableData={tableData} />
              <SelectInput
                listArray={options}
                defaultOption="20"
                handleSelectOption={(e) => {
                  setLimit(e.target.value || "20");
                }}
              />
              <TableColumns
                tableData={tableData}
                checkedBox={handleTableCheckBox}
              />
            </div>
          </div>
          {(loading) ? (
            <div className="spinner my-5"></div>
          ) : rowData?.length === 0 ? (
            <h4 className="text-center my-5">No Data Found</h4>
          ) : (
            <>
              <GlobalTable
                columnDefs={columnDefs}
                rowData={rowData}
              // pageCss={"aghader"}
              />
              <Pagination
                currentPage={page}
                totalPages={pages}
                onPageChange={setPage}
              />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Transaction;
