import { Button } from "react-bootstrap";
import Layout from "../../layout/layout";
import { useState } from "react";
import Filter from "./filter";
import SocialTable from "./socialTable";
import SocialPopup from "./socailPopup";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const Socials = () => {
  const [addPopup, setAddPopup] = useState("");
  const [recall, setRecall] = useState(0);
  const [columnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      width: 100,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Position",
      field: "bannerPosition",
      width: 100,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Language",
      field: "language",
      width: 100,
      cellStyle: { "text-align": "center" },
    },
    { headerName: "Language", field: "image" },
    {
      headerName: "Open In New Tab",
      field: "opensInNewTab",
      width: 150,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Url",
      field: "bannerUrl",
      width: 150,
      cellStyle: { "text-align": "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      field: "Actions",
      cellRenderer: () => <Actions setAddPopup={setAddPopup} />,
    },
  ]);
  const handlePopup = (value) => {
    if (value !== " ") {
      setAddPopup(value);
    } else {
      setAddPopup("");
    }
  };
  return (
    <Layout title="SOCIAL">
      <div className="notification-page payment-method-page banner-content-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs horizontal-tabs-top horizontal-tabs-mb">
            <Button
              className="send-notificaton"
              onClick={() => handlePopup("socialPopup")}
            >
              <span className="add-icon">
                <AddBoxIcon />
              </span>
              Add Social Icon
            </Button>
          </div>
          <Filter />
        </div>
        <SocialPopup show={addPopup} handleClose={handlePopup}  setRecall={setRecall}/>
        <SocialTable columnDefs={columnDefs} recall={recall}l/>
      </div>
    </Layout>
  );
};
export default Socials;

const Actions = ({ setAddPopup }) => {
  const handlePoppup = (value) => {
    setAddPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
