import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import GlobalTable from "../../global/globalTable";

const TranslationTable = ({ columnDefs, rowData,pageCss }) => {
  return (
    <div className="deposit-table">
      <GlobalTable columnDefs={columnDefs} rowData={rowData} pageCss={pageCss} />
    </div>
  );
};
export default TranslationTable;
