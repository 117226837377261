import { Button, FloatingLabel, Form } from "react-bootstrap";

const DatePicker = ({ data, setData, handleClick }) => {
  const handleChange = (e) => {
    setData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <div className="datepicker">
      <FloatingLabel controlId="floatingInput" label="From date">
        <Form.Control
          type="date"
          name="fromDate"
          value={data?.fromDate}
          onChange={handleChange}
        />
      </FloatingLabel>
  
      <FloatingLabel controlId="floatingInput" label="To Date" >
        <Form.Control
          type="date"
          name="endDate"
          value={data?.endDate}
          onChange={handleChange}
        />
      </FloatingLabel>
      <Button onClick={handleClick}>OK</Button>
    </div>
  );
};
export default DatePicker;
