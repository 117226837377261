import { useState } from "react";
import GlobalTable from "../global/globalTable";
import Layout from "../layout/layout";
import AffiliateFilter from "./affiliateFilter";
import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import "./affiliate.css";
import TableColumns from "../global/tableColumns";

const options = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

const AffiliateList = () => {
  return (
    <Layout title="AFFILIATES LIST">
      <div className="affiliate-list-page users-page">
        <AffiliateFilter />
        <AffiliateTable />
      </div>
    </Layout>
  );
};

const AffiliateTable = () => {
  const [rowData] = useState([]);

  const columnDefs = [
    { field: "Brand" },
    { field: "User ID" },
    { field: "User Name" },
    { field: "Email" },
    { field: "Country" },
    { field: "First Name" },
    { field: "Last Name" },
    { field: "Create Date" },
  ];

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Affiliates</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption={10} />
          <TableColumns tableData={columnDefs}/>
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default AffiliateList;
