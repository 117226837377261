import React from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";

const AddCommissionCPA = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="notification-popup add-payment-popup add-commission-popup commision-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Commission Stage CPA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <div className="row-one">
            {["Level Name", "Amount", "FTD Count More Than Or Equal"].map(
              (label, index) => (
                <FloatingLabel
                  key={index}
                  controlId={`floatingInput-${index}`}
                  label={label}
                >
                  <Form.Control
                    type="text"
                    placeholder={`Enter ${label.toLowerCase()}`}
                    autoComplete="off"
                    name={label.toLowerCase().replace(/\s/g, "_")}
                  />
                </FloatingLabel>
              )
            )}
          </div>
          <div className="payment-btn">
            <ButtonWrapper text="Save" />
            <ButtonWrapper text="Clear All" clValue="clear-btn" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCommissionCPA;
