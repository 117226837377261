import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import StarIcon from "@mui/icons-material/Star";
const TranslationTableRight = () => {
  return (
    <div className="table-section">
      <h3 text_key="TEXTS" title="Group: TRANSLATIONS, Key: TEXTS">
        Texts
      </h3>
      <ul>
        <li>
          <FloatingLabel controlId="floatingInput" label="Search Phrase">
            <Form.Control
              type="text"
              placeholder=""
              autoComplete="off"
              name="userid"
            />
            <span className="star-icon">
              <StarIcon />
            </span>
          </FloatingLabel>
        </li>
        <li>
          <FloatingLabel controlId="floatingInput" label="Search Phrase">
            <Form.Control
              type="text"
              placeholder=""
              autoComplete="off"
              name="userid"
            />
            <span className="star-icon">
              <StarIcon />
            </span>
          </FloatingLabel>
        </li>
      </ul>
    </div>
  );
};

export default TranslationTableRight;
