import Layout from "../../layout/layout";
import Filter from "./filter";
import MainPageTable from "./mainPageTable";
const MainPage = () => {
  return (
    <Layout title="MAIN PAGE">
      <div className="notification-page payment-method-page mt-0 ">
        <div className="tabs-section">
          <Filter />
        </div>
        <MainPageTable />
      </div>
    </Layout>
  );
};
export default MainPage;
