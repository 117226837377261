import { useState, useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
import toast from "react-hot-toast";
import ButtonWrapper from "../global/button";
import "./login.css";

const Login = () => {
  const { setUser, setIsLogin, setBrand } = useMyContect();
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("accToken") || "";
  const [recaptcha, setRecaptcha] = useState(null);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    recaptcha: "",
  });
  const siteKey = "6LdpNUwpAAAAABgGNIXmz1tP8bfXkfLVeEOwSzOh";
  const navigate = useNavigate();

  const handleRecaptchaRespone = (value) => {
    setRecaptcha(value);
    setErrors({ ...errors, recaptcha: "" });
  };
  useEffect(() => {
    if (token || localStorage.getItem("brand")) {
      setBrand(localStorage.getItem("brand"));

      navigate("/dashboard");
    }
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!userDetails.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else {
      newErrors.email = "";
    }
    if (!userDetails.password.trim()) {
      newErrors.password = "Password is required";
      isValid = false;
    } else {
      newErrors.password = "";
    }
    if (!recaptcha) {
      newErrors.recaptcha = "Please complete the reCAPTCHA";
      isValid = false;
    } else {
      newErrors.recaptcha = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      const { status, tokens, user } = await loginUser(userDetails);
      console.log(status, token, user);
      const accToken = tokens?.access?.token;
      const refToken = tokens?.refresh?.token;

      if (status === 200) {
        setUser(user);
        setIsLogin(true);
        localStorage.setItem("accToken", accToken);
        localStorage.setItem("refToken", refToken);
        // toast.success("Login Successfully");
        navigate("/dashboard");
      } else {
        toast.error("Enter a valid E-mail or Enter a valid Password", {
          id: "error-fetching-banner-images",
        });
      }
    } catch (error) {
      toast.error("Enter a valid E-mail or Enter a valid Password", {
        id: "error-fetching-banner-images",
      });
      // toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <main className="login ">
        <form className="type-2 login" onSubmit={handleLogin}>
          <div className="fields">
            <h3>Welcome To Admin Panel</h3>
            <div className="login-inputs">
              <FloatingLabel
                controlId="floatingInput"
                label="Email / Username"
                className="mb-4"
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="email"
                  value={userDetails.email}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }
                />
                <Form.Text className="text-danger error-textt">
                  {errors.email}
                </Form.Text>
              </FloatingLabel>
              <FloatingLabel
                className="mb-4"
                controlId="floatingPassword"
                label="Password"
              >
                <Form.Control
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  value={userDetails.password}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                />
                <Form.Text className="text-danger error-textt">
                  {errors.password}
                </Form.Text>
              </FloatingLabel>
              <div className="recaptcha mb-4">
                <ReCAPTCHA
                  onChange={handleRecaptchaRespone}
                  sitekey={siteKey}
                />
                <Form.Text className="text-danger error-textt">
                  {errors.recaptcha}
                </Form.Text>
              </div>
              <ButtonWrapper
                text={"Login"}
                handleClick={handleLogin}
                loading={isLoading}
              />
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default Login;
