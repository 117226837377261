
import Layout from "../../layout/layout"
import DepositFilter from "./depositFilter";
import DepositTable from "./depositTable";
import './deposit.css'

const TotalDeposit = () => {
    return (
      <Layout title="TOTAL DEPOSIT">
        <div className="users-page total-deposit-page">
            <DepositFilter />
            <DepositTable />
        </div>
      </Layout>
    );
  };
export default TotalDeposit