import SelectInput from "../../global/selectInput";
import SwitchBox from "../../global/switchBox";
import ButtonWrapper from "../../global/button";
import FilterInput from "../../global/filterInput";
import FilterDatePicker from "../../global/filterDatePicker";

const BetFilter = ({ recall, setFilter, filter, setRecall, activeTab }) => {
  let limitTypeOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];

  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];

  let periodTypeOptions = [
    {
      id: 1,
      value: "Daily",
    },
    {
      id: 1,
      value: "Weekly",
    },
    {
      id: 1,
      value: "Monthly",
    },
  ];

  let gameVenderOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];
  let gameTypeOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];

  let gameTemplateOptions = [
    {
      id: 1,
      value: "Disabled",
    },
    {
      id: 1,
      value: "Per Transaction Amount",
    },
    {
      id: 1,
      value: "Per Period Amount",
    },
    {
      id: 1,
      value: "Per Period Bet Count",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(filter);
  };
  const handleDateChange = (name, date) => {
    setFilter((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleClear = () => {
    setFilter({
      limitType: "",
      periodType: "",
      countFrom: "",
      countTo: "",
      gameVendor: "",
      gameType: "",
      gameTemplate: "",
      group: "",
      userId: "",
      startDatefrom: "",
      startDateTo: "",
      endDateFrom: "",
      endDateTo: "",
      preventGameLunch: "",
      status: "",
    });
    setRecall(Math.random())
  };

  return (
    <div className="voucher-filter bet-filter">
      <div className="filter-section  mb-filter">
        <SelectInput
          defaultOption={"Limit Type"}
          listArray={limitTypeOptions}
          name={"limitType"}
          selectedValue={filter.limitType}
          handleSelectOption={handleChange}
        />
        <SelectInput
          defaultOption={"Period Type"}
          listArray={periodTypeOptions}
          name={"periodType"}
          selectedValue={filter.periodType}
          handleSelectOption={handleChange}
        />
        <FilterInput
          type={"number"}
          label={"Bet Count From"}
          name={"countFrom"}
          value={filter.countFrom}
          handleChange={handleChange}
        />
        <FilterInput
          type={"number"}
          label="Bet Count To"
          name={"countTo"}
          value={filter.countTo}
          handleChange={handleChange}
        />
      </div>
      <div className="filter-section  mb-filter">
        <SwitchBox
          label={"Active"}
          toggleFlag={filter.status === "Yes"}
          handleToggle={(item) => {
            setFilter((prevState) => ({
              ...prevState,
              status: item === true ? "Yes" : "No",
            }));
          }}
        />
        <SelectInput
          defaultOption={"Game Vendor"}
          listArray={gameVenderOptions}
          name={"gameVendor"}
          selectedValue={filter.gameVendor}
          handleSelectOption={handleChange}
        />
        <SelectInput
          defaultOption={"Game Type"}
          listArray={gameTypeOptions}
          name={"gameType"}
          selectedValue={filter.gameType}
          handleSelectOption={handleChange}
        />
        <SelectInput
          defaultOption={"Game Template"}
          listArray={gameTemplateOptions}
          name={"gameTemplate"}
          selectedValue={filter.gameTemplate}
          handleSelectOption={handleChange}
        />
        <SwitchBox
          label={"Prevent Game Launch"}
          toggleFlag={filter.preventGameLunch === "Yes"}
          handleToggle={(item) => {
            setFilter((prevState) => ({
              ...prevState,
              preventGameLunch: item === true ? "Yes" : "No",
            }));
          }}
        />
      </div>
      <div className="filter-section">
        {activeTab === "Individual Limits" && (
          <FilterInput
            name={"userId"}
            label="User Id"
            value={filter.userId}
            handleChange={handleChange}
          />
        )}
        {activeTab === "Group Limits" && (
          <SelectInput
            isRequired={true}
            defaultOption={"Groups"}
            listArray={Currencyoptions}
            name="group"
            selectedValue={filter.group}
            handleSelectOption={handleChange}
          />
        )}
        <FilterDatePicker
          label="Start Date From"
          name="startDatefrom"
          value={filter.startDatefrom}
          handleDateChange={handleDateChange}
        />
        <FilterDatePicker
          label="Start Date To"
          name={"startDateTo"}
          value={filter.startDateTo}
          handleDateChange={handleDateChange}
        />

        <FilterDatePicker
          label="To Date From"
          name={"endDateFrom"}
          value={filter.endDateFrom}
          handleDateChange={handleDateChange}
        />
        <FilterDatePicker
          label="To Date To"
          name={"endDateTo"}
          value={filter.endDateTo}
          handleDateChange={handleDateChange}
        />
      </div>
      <div className="filter-section btn-wrapper ">
        <ButtonWrapper
          text={"Filter"}
          clValue="search-filter-btn"
          handleClick={() => {
            setRecall(recall + 1);
          }}
        />
        <ButtonWrapper
          text={"Clear All"}
          clValue="clear-btn"
          handleClick={handleClear}
        />
      </div>
    </div>
  );
};
export default BetFilter;
