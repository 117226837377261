import React, { useState } from "react";
import DatePicker from "../global/datePicker";
import HorizontalTabs from "../global/horizontalTab";

const tabs = ["Today", "Yesterday", "Past Week", "Current Month", "Last Month"];

const TabSection = () => {
  const [activeTab, setActiveTab] = useState("Today");

  const handleTabs = (value) => setActiveTab(value);
  return (
    <div className="horizontal-tabs promo_tabs">
      <HorizontalTabs
        activeTab={activeTab}
        handleTabs={handleTabs}
        tabs={tabs}
      />
      <DatePicker />
    </div>
  );
};

export default TabSection;
