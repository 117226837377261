import { Button } from "react-bootstrap";
import { useLayoutEffect, useState } from "react";
import Layout from "../../layout/layout";
import GlobalTable from "../../global/globalTable";
import AddKycFormPopup from "./addKycFormPopup";
import AddNewQuestionnairePopup from "./addNewQuestionnairePopup";
import HorizontalTabs from "../../global/horizontalTab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "./kycform.css";
import { kycFormList, kycQuestionnaires } from "../../../utils/Apis";
import Pagination from "../../../utils/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const tabs = ["Forms", "Questionnaires"];

const KycForm = () => {
  const [sendNotification, setSendNotification] = useState("");
  const [activeTab, setActiveTab] = useState("Forms");
  // const [rowData] = useState([]);

  const [columnDefs] = useState([
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name" },
    { field: "createdAt", headerName: "Date Created" },
    { field: "creatorName", headerName: "Creator Name" },
    { field: "order", headerName: "Order" },
    // { field: "minQues", headerName: "Min Questionnaires" },
    { field: "required", headerName: "Required" },
    { field: "status", headerName: "Active" },
    {
      field: "",
      headerName: "Action",
      cellRenderer: () => <Actions setShowPopup={setSendNotification} />,
    },
  ]);
  const [columnDefs2] = useState([
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name" },
    { field: "order", headerName: "Order In Form" },
    { field: "required", headerName: "Mandatory" },
    { field: "status", headerName: "Active" },
    {
      field: "",
      headerName: "Action",
      cellRenderer: () => <Actions setShowPopup={setSendNotification} />,
    },
  ]);

  const handleNotification = (value) => {
    if (value !== "") {
      setSendNotification(value);
    } else {
      setSendNotification("");
    }
  };

  const handleTabs = (value) => {
    setActiveTab(value);
  };

  // ---------------------Kyc Form--------------------
  const [refreshForm, setRefreshForm] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [kycFormData, setKycFormData] = useState([]);
  const [kycLoading, setKycLoading] = useState(false);
  useLayoutEffect(() => {
    setKycLoading(true);
    kycFormList()
      .then((result) => {
        // const list = result?.data?.data?.map((item) => ({
        //   ID: item.id,
        //   Name: item.name,
        //   "Order In Form": item.order,
        //   Mandatory: item.required,
        //   Active: item.status,
        // }));
        setPages(result?.data?.pages);
        setKycFormData(result?.data?.data);
      })
      .finally(() => setKycLoading(false));
  }, [refreshForm]);
  // ---------------------Kyc Form--------------------
  const [refreshQues, setRefreshQues] = useState("");
  const [pageQues, setPageQues] = useState(1);
  const [pagesQues, setPagesQues] = useState(1);
  const [kycQues, setKycQues] = useState([]);
  const [quesLoading, setQuesLoading] = useState(false);
  useLayoutEffect(() => {
    setQuesLoading(true);
    kycQuestionnaires()
      .then((result) => {
        // const list = result?.data?.data?.map((item) => ({
        //   ID: item.id,
        //   Name: item.name,
        //   "Date Created": item.createdAt,
        //   "Creator Name": item.creatorName,
        //   Order: item.order,
        //   "Min Questionnaires": item.minQues,
        //   Required: item.required,
        //   Active: item.status,
        // }));
        setPagesQues(result?.data?.pages);
        setKycQues(result?.data?.data);
      })
      .finally(() => setQuesLoading(false));
  }, [refreshQues]);

  return (
    <Layout title="KYC FORMS">
      <div className="notification-page">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs notification-first">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            {activeTab === "Forms" ? (
              <Button
                className="send-notificaton"
                onClick={() => handleNotification("Add")}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add KYC Form
              </Button>
            ) : (
              <Button
                className="send-notificaton"
                onClick={() => handleNotification("Add")}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add New Questionnaire
              </Button>
            )}
          </div>
        </div>
        {activeTab === "Forms" && (
          <div className="deposit-table">
            <h2>KYC Forms</h2>
            {kycLoading ? (
              <div className="spinner my-5">
                
              </div>
            ) : kycFormData?.length === 0 ? (
              <h4 className="text-center my-5">No Data Found</h4>
            ) : (
              <GlobalTable columnDefs={columnDefs2} rowData={kycFormData} />
            )}
            <Pagination
              currentPage={page}
              totalPages={pages}
              onPageChange={setPage}
            />
          </div>
        )}
        {activeTab === "Questionnaires" && (
          <>
            {quesLoading ? (
              <div className="spinner my-5"></div>
            ) : kycQues?.length === 0 ? (
              <h4 className="text-center my-5">No Data Found</h4>
            ) : (
              <GlobalTable columnDefs={columnDefs} rowData={kycQues} />
            )}
            <Pagination
              currentPage={pageQues}
              totalPages={pagesQues}
              onPageChange={setPageQues}
            />
          </>
        )}
      </div>
      <AddKycFormPopup
        show={activeTab === "Forms" && !!sendNotification}
        sendNotification={sendNotification}
        handleClose={handleNotification}
        setRefreshForm={setRefreshForm}
      />
      <AddNewQuestionnairePopup
        show={activeTab==="Questionnaires" && !!sendNotification}
        handleClose={handleNotification}
        setRefreshQues={setRefreshQues}
      />
    </Layout>
  );
};
export default KycForm;

const Actions = ({ setShowPopup }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = (value) => {
    setFlag(!flag);
    setShowPopup(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action ms" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
    </>
  );
};
