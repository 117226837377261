import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import GTranslateIcon from "@mui/icons-material/GTranslate";
const FilterInput = ({
  label,
  name,
  flag,
  icon,
  hanldlePopup,
  handleChange,
  isRequired,
  value,
  type,
}) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        placeholder=""
        type={type ? type : "text"}
        autoComplete="off"
        name={name}
        value={value}
        required={isRequired ? isRequired : false}
        disabled={flag === true ? flag : false}
        onChange={handleChange}
      />
      {icon && (
        <GTranslateIcon
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "#b1b1b1",
          }}
          onClick={hanldlePopup}
        />
      )}
    </FloatingLabel>
  );
};

export default FilterInput;
