import { FloatingLabel, Form, Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import toast from "react-hot-toast";
import GlobalEditor from "../../global/globalEditor";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import {
  createNews,
  createPromotion,
  createTestimonials,
  deleteNews,
  deletePromotion,
  deletePromotionClass,
  deleteTestimonials,
  updateNews,
  updatePromotion,
  updatePromotionClass,
  updateTestimonials,
} from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";

const SiteContentPopup = ({
  show,
  sitePopup,
  handleClose,
  activeTab,
  setRecall,
}) => {
  const editorRef = useRef(null);
  console.log(sitePopup, activeTab);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup content-popup add-notification-popup banner-page1 ${
        activeTab === "Promotions" ? "sitePopupWidth" : " "
      }`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {sitePopup === "edit" ? "Update" : sitePopup} {activeTab}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          {activeTab === "Testimonials" ? (
            <Testimonials
              editorRef={editorRef}
              show={show}
              handleClose={handleClose}
              sitePopup={sitePopup}
              setRecall={setRecall}
            />
          ) : (
            " "
          )}
          {activeTab === "News" ? (
            <News
              editorRef={editorRef}
              show={show}
              handleClose={handleClose}
              sitePopup={sitePopup}
              setRecall={setRecall}
            />
          ) : (
            " "
          )}
          {activeTab === "Promotions" ? (
            <CeratePromo
              editorRef={editorRef}
              show={show}
              handleClose={handleClose}
              sitePopup={sitePopup}
              setRecall={setRecall}
            />
          ) : (
            " "
          )}{" "}
          {activeTab === "Create Promotions Class" ? (
            <PromotionClass
              handleClose={handleClose}
              sitePopup={sitePopup}
              setRecall={setRecall}
            />
          ) : (
            " "
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SiteContentPopup;

const Testimonials = ({ sitePopup, editorRef, handleClose, setRecall }) => {
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);

  const [testimonialData, setTestimonialData] = useState({
    language: "",
    position: null,
    testimonialsName: "",
    company: "",
    testimonialsText: "",
  });

  useEffect(() => {
    if (sitePopup.includes("edit")) {
      setTestimonialData({
        language: data?.Lang,
        position: data?.Position,
        testimonialsName: data && data["Testimonial Name"],
        company: data?.Company,
        testimonialsText: data && data["Testimonial Text"],
      });
    }
    // eslint-disable-next-line
  }, [data]);
  const options = [
    { id: 1, value: "en-US" },
    { id: 2, value: "es-ES" },
    { id: 3, value: "fr-FR" },
    { id: 4, value: "de-DE" },
    { id: 5, value: "it-IT" },
    { id: 6, value: "ja-JP" },
    { id: 7, value: "ko-KR" },
    { id: 8, value: "zh-CN" },
    { id: 9, value: "ru-RU" },
    { id: 10, value: "ar-SA" },
  ];

  const [error, seterror] = useState({
    language: false,
    position: false,
    testimonialsName: false,
    company: false,
    testimonialsText: false,
  });

  const handleCreate = async () => {
    const newerror = {
      language: testimonialData.language === "",
      position:
        isNaN(testimonialData.position) || testimonialData.position === null,
      testimonialsName: testimonialData.testimonialsName === "",
      company: testimonialData.company === "",
      testimonialsText: testimonialData.testimonialsText === "",
    };

    seterror(newerror);

    if (Object.values(newerror).some((error) => error)) {
      return;
    }
    try {
      const token = localStorage.getItem("secretDomainKey");
      setLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          body:
            data && sitePopup === "edit"
              ? { ...testimonialData, id: data.id }
              : testimonialData,
        };
        const res =
          data && sitePopup === "edit"
            ? await updateTestimonials(config)
            : await createTestimonials(config);
        if (res?.data?.status === 200) {
          if (sitePopup === "edit") {
            toast.success("You Have Updated successfully Testimonial News", {
              toastId: "update",
            });
          } else {
            toast.success("You Have Created successfully Testimonial News", {
              toastId: "create",
            });
          }

          setLoading(false);
          setRecall(Math.random());
          handleClose();
        } else {
          toast.error("Something went Wrong Plase try Again", {
            toastId: "tryAgain",
          });
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  const handleChange = (e, fieldType) => {
    const { name, value } = e.target;
    const parsedValue = fieldType === "number" ? parseInt(value) : value;

    if (fieldType === "select") {
      setTestimonialData({
        ...testimonialData,
        language: value,
      });
    } else {
      setTestimonialData({
        ...testimonialData,
        [name]: parsedValue,
      });
    }
  };

  const handleEditorChange = (content) => {
    setTestimonialData({
      ...testimonialData,
      testimonialsText: content,
    });
  };

  const handleDeleteTestimonial = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
          body: data,
        };

        const res = await deleteTestimonials(config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted Testimonial", {
            toastId: "toats",
          });
          setLoading(false);
          setRecall(Math.random());
          handleClose();
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup content-popup">
      {sitePopup !== "Delete" ? (
        <>
          <div className="popup-filter">
            <div>
              <SelectInput
                listArray={options}
                defaultOption="Select Language"
                selectedValue={testimonialData.language}
                handleSelectOption={(e) => handleChange(e, "select")}
              />
              {error.language && (
                <div className="text-danger">*Language is required</div>
              )}
            </div>
            <FloatingLabel controlId="floatingInput" label="Position">
              <Form.Control
                type="number"
                placeholder="name@example.com"
                autoComplete="off"
                name="position"
                onChange={(e) => handleChange(e, "number")}
                value={testimonialData.position}
              />
              {error.position && (
                <div className="text-danger">*Position is required</div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Testimonial Name">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoComplete="off"
                name="testimonialsName"
                value={testimonialData.testimonialsName}
                onChange={(e) => handleChange(e)}
              />
              {error.testimonialsName && (
                <div className="text-danger">
                  *Testimonials Name is required
                </div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Company">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoComplete="off"
                name="company"
                value={testimonialData.company}
                onChange={(e) => handleChange(e)}
              />
              {error.company && (
                <div className="text-danger">*Company is required</div>
              )}
            </FloatingLabel>
          </div>

          <div className="add-languages"></div>
          <div className="editor-section">
            <h6>Text</h6>
            <GlobalEditor
              editorRef={editorRef}
              textValue={testimonialData?.testimonialsText}
              handleEditorChange={handleEditorChange}
            />
            {error.testimonialsText && (
              <div className="text-danger">*Testimonials Text is required</div>
            )}
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>
            Are you sure you want to delete{" "}
            <b>{data && data["Testimonial Name"]}</b>?
          </p>
        </div>
      )}
      <div className="auto-btn">
        {data && sitePopup === "edit" ? (
          <ButtonWrapper
            text={`${"Update"}`}
            loading={loading}
            handleClick={handleCreate}
          />
        ) : sitePopup === "Delete" ? (
          <ButtonWrapper
            text={`${"Delete"}`}
            handleClick={handleDeleteTestimonial}
            loading={loading}
          />
        ) : (
          <ButtonWrapper
            text={`${"Submit"}`}
            handleClick={handleCreate}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

const News = ({ editorRef, handleClose, sitePopup, setRecall }) => {
  const { data } = useMyContect();
  const [smallImageName, setSmallImageName] = useState("");
  const [loading, setLoading] = useState(false);

  const [largeImageName, setLargeImageName] = useState("");

  const [newsData, setNewsData] = useState({
    title: "",
    slug: "",
    seoTitle: "",
    metaDesc: "",
    smallImage: "",
    largeImage: "",
    shortDesc: "",
    detailedText: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    slug: false,
    seoTitle: false,
    metaDesc: false,
    smallImage: false,
    largeImage: false,
    shortDesc: false,
    detailedText: false,
  });
  useEffect(() => {
    if (sitePopup.includes("edit")) {
      const smallImageUrl = data && data["Small Image"];
      const largeImageUrl = data && data["Large Image"];
      let smallName = "";
      let largeName = "";
      if (smallImageUrl) {
        smallName = smallImageUrl?.substring(
          smallImageUrl.lastIndexOf("/") + 1
        );
        smallName = smallName.replace(/_\d+/g, "");
      }
      if (largeImageUrl) {
        largeName = largeImageUrl?.substring(
          largeImageUrl.lastIndexOf("/") + 1
        );
        largeName = largeName.replace(/_\d+/g, "");
      }
      setNewsData({
        id: data?.id,
        title: data?.Title,
        slug: data?.Slug,
        seoTitle: data && data["Seo Title"],
        metaDesc: data && data["Meta Description"],
        shortDesc: data && data["Short Description"],
        detailedText: data && data["Detailed Text"],
        smallImage: smallName,
        largeImage: largeName,
      });
      setErrors({ ...errors, smallImage: false, largeImage: false });
      setSmallImageName(smallName);
      setLargeImageName(largeName);
    }
    // eslint-disable-next-line
  }, [data]);
  const handleChange = (e, fieldType) => {
    const { name, value } = e.target;
    const parsedValue = fieldType === "number" ? parseInt(value) : value;
    setNewsData({
      ...newsData,
      [name]: parsedValue,
    });
    const newErrors = {
      ...errors,
      [name]: fieldType === "select" ? value === "" : !value.trim(),
    };
    setErrors(newErrors);
  };

  const handleEditorChange = (content) => {
    setNewsData({
      ...newsData,
      detailedText: content,
    });

    const newErrors = {
      ...errors,
      detailedText: !content.trim(),
    };
    setErrors(newErrors);
  };

  const handleImageUpload = (file) => {
    console.log("file", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setErrors({
        ...errors,
        smallImage: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setErrors({ ...errors, smallImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setErrors({
          ...errors,
          smallImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setErrors({
          ...errors,
          smallImage: false,
        });
        let name = file.name;
        if (name.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setNewsData({ ...newsData, smallImage: file });
        setSmallImageName(name);
      }
    }
  };

  const UploadLargeImage = (file) => {
    console.log("file", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setErrors({
        ...errors,
        largeImage: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setErrors({ ...errors, largeImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setErrors({
          ...errors,
          largeImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setErrors({
          ...errors,
          largeImage: false,
        });
        let name = file.name;
        if (name.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setNewsData({ ...newsData, largeImage: file });
        setLargeImageName(name);
      }
    }
  };
  const handleSubmit = async () => {
    const hasEmptyField = Object.values(newsData).some(
      (value) => value === "" || (typeof value === "string" && !value.trim())
    );
    const hasEmptyImages = !newsData.smallImage || !newsData.largeImage;

    if (hasEmptyField || hasEmptyImages) {
      const newErrors = {};
      for (const key in newsData) {
        newErrors[key] =
          typeof newsData[key] === "string"
            ? !newsData[key].trim()
            : !newsData[key];
      }

      setErrors(newErrors);

      console.log("hgtfe", errors);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("id", newsData.id);
      formData.append("title", newsData.title);
      formData.append("slug", newsData.slug);
      formData.append("seoTitle", newsData.seoTitle);
      formData.append("metaDescription", newsData.metaDesc);
      formData.append("smallImage", newsData.smallImage);
      formData.append("largeImage", newsData.largeImage);
      formData.append("shortDescription", newsData.shortDesc);
      formData.append("detailedText", newsData.detailedText);

      const token = localStorage.getItem("secretDomainKey");
      setLoading(true);
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
        };

        const res =
          sitePopup === "edit"
            ? await updateNews(newsData.id, config, formData)
            : await createNews(config, formData);
        if (res?.data?.status === 200) {
          if (sitePopup === "edit") {
            toast.success("You Have successfully Updated News", {
              toastId: "update",
            });
          } else {
            toast.success("You Have successfully Created News", {
              toastId: "create",
            });
          }

          setLoading(false);
          setRecall(Math.random());
          handleClose();
        } else {
          toast.error("Something went Wrong Plase try Again", {
            toastId: "tryAgain",
          });
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }finally{
      setLoading(false)
    }
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
        };

        const res = await deleteNews(data.id, config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted News", {
            toastId: "toats",
          });
          setLoading(false);
          setRecall(Math.random());
          handleClose();
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      {sitePopup !== "Delete" ? (
        <>
          <div className="auto-options1 popup-filter ">
            <FloatingLabel controlId="floatingInput" label="Title">
              <Form.Control
                type="text"
                placeholder="Title"
                autoComplete="off"
                name="title"
                value={newsData.title}
                onChange={(e) => handleChange(e)}
              />
              {errors.title && (
                <div className="text-danger">*Title is required</div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Slug">
              <Form.Control
                type="text"
                placeholder="Slug"
                autoComplete="off"
                name="slug"
                value={newsData.slug}
                onChange={(e) => handleChange(e)}
              />
              {errors.slug && (
                <div className="text-danger">*Slug is required</div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Seo Title">
              <Form.Control
                type="text"
                placeholder="Seo Title"
                autoComplete="off"
                name="seoTitle"
                value={newsData.seoTitle}
                onChange={(e) => handleChange(e)}
              />
              {errors.seoTitle && (
                <div className="text-danger">*Seo Title is required</div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Meta Desc">
              <Form.Control
                type="text"
                placeholder="Meta Description"
                autoComplete="off"
                name="metaDesc"
                value={newsData.metaDesc}
                onChange={(e) => handleChange(e)}
              />
              {errors.metaDesc && (
                <div className="text-danger">*Meta Description is required</div>
              )}
            </FloatingLabel>
          </div>
          <div className="mt-4 mb-4 popup-filter">
            <div>
              <div className="upload-image">
                {newsData.smallImage ? (
                  <label htmlFor="small-upload" className="upload-image-box">
                    {smallImageName}
                    <span className="uplaod-icon">
                      <ImageIcon />
                    </span>
                  </label>
                ) : (
                  <label htmlFor="small-upload" className="upload-image-box">
                    Small Image
                    <span className="cloud-icon">
                      <CloudUploadIcon />
                    </span>
                  </label>
                )}
                <input
                  type="file"
                  id="small-upload"
                  accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                  image/gif,
                  image/bmp,"
                  onChange={(event) => handleImageUpload(event.target.files[0])}
                />
              </div>
              {errors.smallImage && (
                <div className="text-danger">
                  {"*Small Image is Required. Please give valid File and Size (5MB)"}
                </div>
              )}
            </div>
            <div>
              <div className="upload-image">
                {newsData.largeImage ? (
                  <label htmlFor="large-upload" className="upload-image-box">
                    {largeImageName}
                    <span className="uplaod-icon">
                      <ImageIcon />
                    </span>
                  </label>
                ) : (
                  <label htmlFor="large-upload" className="upload-image-box">
                    Large Image
                    <span className="cloud-icon">
                      <CloudUploadIcon />
                    </span>
                  </label>
                )}
                <input
                  type="file"
                  id="large-upload"
                  accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                  image/gif,
                  image/bmp,"
                  onChange={(event) => UploadLargeImage(event.target.files[0])}
                />
              </div>
              {errors.largeImage && (
                <div className="text-danger">
                  {"*Large Image is Required.Please give valid File and Size (5MB)"}
                </div>
              )}
            </div>
          </div>
          <Form.Control
            as="textarea"
            type="textarea"
            rows={3}
            name="shortDesc"
            placeholder="Short Description"
            value={newsData.shortDesc}
            onChange={(e) => handleChange(e)}
          />
          {errors.shortDesc && (
            <div className="text-danger">*Short Description is required</div>
          )}
          <div className="editor-section">
            <h6>Text</h6>
            <GlobalEditor
              editorRef={editorRef}
              textValue={newsData?.detailedText}
              handleEditorChange={handleEditorChange}
            />
            {errors.detailedText && (
              <div className="text-danger">*Text is required</div>
            )}
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>
            Are you sure you want to delete <b>{data && data.Title}</b>?
          </p>
        </div>
      )}
      <div className="auto-btn">
        {data && sitePopup === "edit" ? (
          <ButtonWrapper
            text={`${"Update"}`}
            handleClick={handleSubmit}
            loading={loading}
          />
        ) : sitePopup === "Delete" ? (
          <ButtonWrapper
            text={`${"Delete"}`}
            handleClick={handleDelete}
            loading={loading}
          />
        ) : (
          <ButtonWrapper
            text={`${"Submit"}`}
            loading={loading}
            handleClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

const CeratePromo = ({
  // show,
  editorRef,
  handleClose,
  sitePopup,
  setRecall,
}) => {
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [smallImageName, setSmallImageName] = useState("");
  const [largeImageName, setLargeImageName] = useState("");
  const [promotionData, setPromotionData] = useState({
    language: "",
    class: "",
    position: "",
    name: "",
    bannerAlt: "",
    title: "",
    fullTitle: "",
    detailedText: "",
    smallImage: "",
    largeImage: "",
  });

  const [error, setError] = useState({
    language: false,
    class: false,
    position: false,
    name: false,
    bannerAlt: false,
    title: false,
    fullTitle: false,
    detailedText: false,
    smallImage: false,
    largeImage: false,
  });
  useEffect(() => {
    if (sitePopup.includes("edit")) {
      const smallImageUrl = data && data["Small Image"];
      const largeImageUrl = data && data["Large Image"];
      let smallName = "";
      let largeName = "";
      if (smallImageUrl) {
        smallName = smallImageUrl?.substring(
          smallImageUrl.lastIndexOf("/") + 1
        );
        smallName = smallName.replace(/_\d+/g, "");
      }
      if (largeImageUrl) {
        largeName = largeImageUrl?.substring(
          largeImageUrl.lastIndexOf("/") + 1
        );
        largeName = largeName.replace(/_\d+/g, "");
      }
      console.log("Data", data);
      setPromotionData({
        language: data?.language,
        class: data?.Class,
        position: data?.position,
        name: data?.name,
        bannerAlt: data?.bannerAlt,
        title: data?.title,
        fullTitle: data?.fullTitle,
        detailedText: data?.detailedText,
        smallImage: smallName,
        largeImage: largeName,
      });
      setSmallImageName(smallName);
      setLargeImageName(largeName);
    }
    // eslint-disable-next-line
  }, [data]);
  const language = [{ id: 1, value: "en-US" }];
  const classOptions = [
    { id: 1, value: 50 },
    { id: 2, value: 100 },
    { id: 3, value: 150 },
    { id: 4, value: 200 },
  ];

  const handleChange = (e, fieldType) => {
    const { name, value } = e.target;
    const parsedValue = fieldType === "number" ? parseInt(value) : value;
    setError({ ...error, [name]: false });

    if (fieldType === "select") {
      setPromotionData({
        ...promotionData,
        language: value,
      });

      setError({ ...error, language: false });
    } else if (fieldType === "class") {
      setPromotionData({
        ...promotionData,
        class: parseInt(value),
      });

      setError({ ...error, class: false });
    } else {
      setPromotionData({
        ...promotionData,
        [name]: parsedValue,
      });
    }

    if (!value.trim()) {
      setError({ ...error, [name]: true });
    }
  };

  const handleImageUpload = (file) => {
    console.log("file", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setError({
        ...error,
        smallImage: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, smallImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          smallImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          smallImage: false,
        });
        let name = file.name;
        if (name.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setPromotionData({ ...promotionData, smallImage: file });
        setSmallImageName(name);
      }
    }
  };

  const UploadLargeImage = (file) => {
    console.log("file", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setError({
        ...error,
        largeImage: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, largeImage: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          largeImage: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          largeImage: false,
        });
        let name = file.name;
        if (name.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setPromotionData({ ...promotionData, largeImage: file });
        setLargeImageName(name);
      }
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    const newErrors = { ...error };
    if (!promotionData.language) {
      newErrors.language = true;
      isValid = false;
    } else {
      newErrors.language = false;
    }
    if (!promotionData.class) {
      newErrors.class = true;
      isValid = false;
    } else {
      newErrors.class = false;
    }
    if (!promotionData.position) {
      newErrors.position = true;
      isValid = false;
    } else {
      newErrors.position = false;
    }
    if (!promotionData.name) {
      newErrors.name = true;
      isValid = false;
    } else {
      newErrors.name = false;
    }
    if (!promotionData.bannerAlt) {
      newErrors.bannerAlt = true;
      isValid = false;
    } else {
      newErrors.bannerAlt = false;
    }
    if (!promotionData.title) {
      newErrors.title = true;
      isValid = false;
    } else {
      newErrors.title = false;
    }
    if (!promotionData.fullTitle) {
      newErrors.fullTitle = true;
      isValid = false;
    } else {
      newErrors.fullTitle = false;
    }
    if (!promotionData.detailedText) {
      newErrors.detailedText = true;
      isValid = false;
    } else {
      newErrors.detailedText = false;
    }
    if (!promotionData.smallImage || !!error.smallImage) {
      newErrors.smallImage = error.smallImage || "*Small Image is Required";
      isValid = false;
    } else {
      newErrors.smallImage = false;
    }
    if (!promotionData.largeImage || !!error.largeImage) {
      newErrors.largeImage = error.largeImage || "*Large Image is Required";
      isValid = false;
    } else {
      newErrors.largeImage = false;
    }

    setError(newErrors);

    if (isValid) {
      const formData = new FormData();
      formData.append("id", promotionData.id);
      formData.append("language", promotionData.language);
      formData.append("class", promotionData.class);
      formData.append("position", promotionData.position);
      formData.append("name", promotionData.name);
      formData.append("bannerAlt", promotionData.bannerAlt);
      formData.append("title", promotionData.title);
      formData.append("fullTitle", promotionData.fullTitle);
      formData.append("detailedText", promotionData.detailedText);
      formData.append("smallImage", promotionData.smallImage);
      formData.append("largeImage", promotionData.largeImage);
      try {
        const token = localStorage.getItem("secretDomainKey");
        setLoading(true);
        if (token) {
          const config = {
            headers: {
              DomainToken: `${token}`,
            },
          };

          const res =
            sitePopup === "edit"
              ? await updatePromotion(data.id, config, formData)
              : await createPromotion(config, formData);
          if (res?.data?.status === 200) {
            if (sitePopup === "edit") {
              toast.success("You Have successfully Updated Promotion", {
                toastId: "update",
              });
            } else {
              toast.success("You Have successfully Created Promotion", {
                toastId: "create",
              });
            }

            setLoading(false);
            setRecall(Math.random());
            handleClose();
          } else {
            toast.error("Something went Wrong Plase try Again", {
              toastId: "tryAgain",
            });
          }
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.log("Error found while fetching data", error);
      }
      console.log("Form submitted successfully:", promotionData);
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
        };

        const res = await deletePromotion(data.id, config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted Promotion", {
            toastId: "toats",
          });
          setLoading(false);
          setRecall(Math.random());
          handleClose();
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      {sitePopup !== "Delete" ? (
        <>
          <div className="auto-options1 popup-filter ">
            <div>
              <SelectInput
                listArray={language}
                defaultOption="Select Language"
                selectedValue={promotionData.language}
                handleSelectOption={(e) => handleChange(e, "select")}
              />
              {error.language && (
                <div className="text-danger">*Language is required</div>
              )}
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                className="form-floating"
                onChange={(e) => handleChange(e, "class")}
                // value={promotionData.class === "" && "Class"}
              >
                {promotionData.class ? null : (
                  <option value="">{"Select Class"}</option>
                )}
                {classOptions &&
                  classOptions.map((el, i) => (
                    <option value={el.text ? el.text : el.value} key={i}>
                      {el.text ? el.text : el.value}
                    </option>
                  ))}
              </Form.Select>

              {error.class && (
                <div className="text-danger">*Class is required</div>
              )}
            </div>
          </div>
          <div className="popup-filter">
            <FloatingLabel controlId="floatingInput" label="Position">
              <Form.Control
                type="number"
                placeholder="Position"
                autoComplete="off"
                name="position"
                value={promotionData.position}
                onChange={(e) => handleChange(e, "number")}
              />
              {error.position && (
                <div className="text-danger">*Position is required</div>
              )}
            </FloatingLabel>

            <FloatingLabel controlId="floatingInput" label="Testimonial Name">
              <Form.Control
                type="text"
                placeholder="Testimonial Name"
                autoComplete="off"
                name="name"
                value={promotionData.name}
                onChange={(e) => handleChange(e)}
              />
              {error.name && (
                <div className="text-danger">*Testimonial Name is required</div>
              )}
            </FloatingLabel>
          </div>
          <div className="mt-4 mb-4 popup-filter">
            <div>
              <div className="upload-image">
                {promotionData.smallImage ? (
                  <label htmlFor="small-upload" className="upload-image-box">
                    {smallImageName}
                    <span className="uplaod-icon">
                      <ImageIcon />
                    </span>
                  </label>
                ) : (
                  <label htmlFor="small-upload" className="upload-image-box">
                    Small Image
                    <span className="cloud-icon">
                      <CloudUploadIcon />
                    </span>
                  </label>
                )}
                <input
                  type="file"
                  id="small-upload"
                  accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                  image/gif,
                  image/bmp,"
                  onChange={(event) => handleImageUpload(event.target.files[0])}
                />
              </div>
              {!!error.smallImage && (
                <div className="text-danger">{error.smallImage}</div>
              )}
            </div>
            <div>
              <div className="upload-image">
                {promotionData.largeImage ? (
                  <label htmlFor="large-upload" className="upload-image-box">
                    {largeImageName}
                    <span className="uplaod-icon">
                      <ImageIcon />
                    </span>
                  </label>
                ) : (
                  <label htmlFor="large-upload" className="upload-image-box">
                    Large Image
                    <span className="cloud-icon">
                      <CloudUploadIcon />
                    </span>
                  </label>
                )}
                <input
                  type="file"
                  accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                  image/gif,
                  image/bmp,"
                  id="large-upload"
                  onChange={(event) => UploadLargeImage(event.target.files[0])}
                />
              </div>
              {!!error.largeImage && (
                <div className="text-danger">{error?.largeImage}</div>
              )}
            </div>
          </div>

          <div className="row-one1 popup-filter">
            <FloatingLabel controlId="floatingInput" label="Banner ALT">
              <Form.Control
                type="text"
                placeholder="Banner ALT"
                autoComplete="off"
                name="bannerAlt"
                value={promotionData.bannerAlt}
                onChange={(e) => handleChange(e)}
              />
              {error.bannerAlt && (
                <div className="text-danger">*Banner ALT is required</div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Title">
              <Form.Control
                type="text"
                placeholder="Title"
                autoComplete="off"
                name="title"
                value={promotionData.title}
                onChange={(e) => handleChange(e)}
              />
              {error.title && (
                <div className="text-danger">*Title is required</div>
              )}
            </FloatingLabel>
            <FloatingLabel controlId="floatingInput" label="Full Title">
              <Form.Control
                type="text"
                placeholder="Full Title"
                autoComplete="off"
                name="fullTitle"
                value={promotionData.fullTitle}
                onChange={(e) => handleChange(e)}
              />
              {error.fullTitle && (
                <div className="text-danger">*Full Title is required</div>
              )}
            </FloatingLabel>
          </div>
          <div className="editor-section">
            <h6>Text</h6>
            <GlobalEditor
              editorRef={editorRef}
              textValue={promotionData.detailedText}
              handleEditorChange={(content) =>
                handleChange({
                  target: { name: "detailedText", value: content },
                })
              }
            />
            {error.detailedText && (
              <div className="text-danger">*Text is required</div>
            )}
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>
            Are you sure you want to delete <b>{data && data.name}</b>?
          </p>
        </div>
      )}
      <div className="auto-btn">
        {data && sitePopup === "edit" ? (
          <ButtonWrapper
            text={`${"Update"}`}
            handleClick={handleSubmit}
            loading={loading}
          />
        ) : sitePopup === "Delete" ? (
          <ButtonWrapper
            text={`${"Delete"}`}
            loading={loading}
            handleClick={handleDelete}
          />
        ) : (
          <ButtonWrapper
            text={`${"Submit"}`}
            loading={loading}
            handleClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
};

const PromotionClass = ({ handleClose, sitePopup, setRecall }) => {
  const { data } = useMyContect();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  useEffect(() => {
    if (sitePopup.includes("edit") && data) {
      setName(data?.name);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleChange = (e) => {
    setName(e.target.value);
    setError("");
  };
  const handleSubmit = async () => {
    try {
      if (!name) {
        setError("Please Enter the Name");
      } else {
        const token = localStorage.getItem("secretDomainKey");
        if (token) {
          const config = {
            headers: {
              DomainToken: `${token}`,
            },
            // params: {
            //   page: page,
            //   pageSize: pageSize,
            // },
            body: {
              name,
            },
          };
          setLoading(true);
          const res = await updatePromotionClass(data?.id, config);
          // console.log(res);
          if (res) {
            setLoading(true);
            setName("");
            setError("");
            handleClose();
            setRecall(Math.random());
            // setRecall(Math.random());

            toast.success("Promotions Class Updated Successfully.");
          }
        } else {
          console.log("No token found");
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
        };

        const res = await deletePromotionClass(data.id, config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted Promotion", {
            toastId: "toats",
          });
          setLoading(false);
          handleClose();
          setRecall(Math.random());
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      {sitePopup !== "Delete" ? (
        <>
          <div className="row-one1 popup-filter">
            <FloatingLabel controlId="floatingInput" label="Banner ALT">
              <Form.Control
                type="text"
                placeholder="Name"
                autoComplete="off"
                name="name"
                value={name}
                onChange={handleChange}
              />
              {!!error && <div className="text-danger">*Name is required</div>}
            </FloatingLabel>
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>
            Are you sure you want to delete <b>{data && data.name}</b>?
          </p>
        </div>
      )}
      <div className="auto-btn">
        {data && sitePopup === "edit" ? (
          <ButtonWrapper
            text={`${"Update"}`}
            handleClick={handleSubmit}
            loading={loading}
          />
        ) : sitePopup === "Delete" ? (
          <ButtonWrapper
            text={`${"Delete"}`}
            handleClick={handleDelete}
            loading={loading}
          />
        ) : (
          <ButtonWrapper text={`${"Submit"}`} handleClick={handleSubmit} />
        )}
      </div>
    </div>
  );
};
