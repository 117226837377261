const ToggleSwitch = ({name, handleToggle, toggleFlag }) => {
  const handleChange =()=>{
    handleToggle(!toggleFlag)
  }
  return (
    <label className="switch1">
      <input type="checkbox" name={name} checked={toggleFlag} onChange={handleChange} />
      <span className="slider1" />
    </label>
  );
};
export default ToggleSwitch;
