import { useState } from "react";

const LanguageDropdown = ({ flagShow, handleFlag }) => {
  let flag = ["GB", "GE", "TR", "DE", "NL", "RU", "KR", "BR"]
  const [topFlag, setTopflag] = useState("GB");
  const handleTopFlag = (data) => {
    setTopflag(data);
  }
  return (
    <div className="header-flag" onClick={handleFlag} role="presentation">
      <span><img src={`https://flagsapi.com/${topFlag}/flat/24.png`} alt="flag" /></span>

      <ul style={{ height: flagShow ? "266px" : "0", border: flagShow ? "0.0625rem solid #e3e4eb" : "unset" }}>
        {flag.map((item, index) => {
          return (
            <li key={index} onClick={() => handleTopFlag(item)}>
              <img src={`https://flagsapi.com/${item}/flat/24.png`} alt="flag" />
            </li>)
        })}
      </ul>
    </div>
  );
};
export default LanguageDropdown;
