import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Navbar from "../sideNav/navbar";
import Header from "../header/header";
import "./layout.css";

let screenWidth = window.innerWidth;

const Layout = ({ children, title }) => {
  const [toggle, setToggle] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      screenWidth = window.innerWidth;
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        screenWidth <= 1024
      ) {
        setToggle(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useLayoutEffect(() => {
    const initScreenWidth = window.innerWidth;
    if (initScreenWidth <= 1024) {
      setToggle(true);
    }
  }, []);

  return (
    <div className={`wrapper ${toggle ? "hide-sidebar" : ""}`}>
      <div className="navbar-section" ref={wrapperRef}>
        <Navbar />
      </div>
      <div className="wrapper-content">
        <Header
          toggle={toggle}
          setToggle={setToggle}
          title={title}
          screenWidth={screenWidth}
        />
        {children}
      </div>
    </div>
  );
};

export default Layout;
