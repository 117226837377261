
import Layout from "../../layout/layout.jsx";
import Filter from "./filter.jsx";
import BrandTable from "./brandTable.jsx";
const BrandBalance = () => {
  return (
    <Layout title="BRAND BALANCES">
      <div className="notification-page users-page cashdesk-page">
       <div className="cashdesk-section">
         <Filter />
         <BrandTable />
       </div>
      </div>
    </Layout>
  );
};
export default BrandBalance;
