import React, { useState, useEffect } from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import SwitchBox from "../../global/switchBox";
import { useMyContect } from "../../../context/myContext";
import { createSocial, updateSocial, deleteSocial } from "../../../utils/Apis";
import toast from "react-hot-toast";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import "./social.css";

const SocialPopup = ({ show, handleClose, setRecall }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup add-social-popup  banner-page1`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {show?.includes("Delete")
            ? "Delete"
            : show?.includes("edit")
            ? "Edit"
            : "Add"}{" "}
          Social Icon
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section ">
          <AddBanner
            handleClose={handleClose}
            show={show}
            setRecall={setRecall}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SocialPopup;

const AddBanner = ({ handleClose, show, setRecall }) => {
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [imageName, setImageName] = useState("");
  const [addSocial, setAddSocial] = useState({
    language: "",
    image: "",
    bannerPosition: "",
    bannerUrl: "",
    opensInNewTab: false,
  });

  const handleClearAll = () => {
    setAddSocial({
      language: "",
      image: "",
      bannerPosition: "",
      bannerUrl: "",
      opensInNewTab: false,
    });
  };

  useEffect(() => {
    if (show === "edit") {
      if (data?.opensInNewTab === "True") {
        setToggleFlag(true);
      } else {
        setToggleFlag(false);
      }
      const imageUrl = data?.image;
      let name = "";
      if (imageUrl) {
        name = imageUrl?.substring(imageUrl.lastIndexOf("/") + 1);
        name = name.replace(/_\d+/g, "");
      }
      setAddSocial({
        language: data?.language,
        bannerPosition: data?.bannerPosition,
        bannerUrl: data?.bannerUrl,
        image: name,
        opensInNewTab: data?.opensInNewTab,
      });
      setImageName(name);
    }
    // eslint-disable-next-line
  }, [data]);

  const language = [
    { id: 1, value: "en-US" },
    { id: 2, value: "es-ES" },
    { id: 3, value: "fr-FR" },
    { id: 4, value: "de-DE" },
    { id: 5, value: "it-IT" },
    { id: 6, value: "ja-JP" },
    { id: 7, value: "ko-KR" },
    { id: 8, value: "zh-CN" },
    { id: 9, value: "ru-RU" },
    { id: 10, value: "ar-SA" },
  ];

  const handleToggle = (e) => {
    setToggleFlag(!toggleFlag);
    setAddSocial({
      ...addSocial,
      opensInNewTab: !toggleFlag,
    });
  };

  const [error, setError] = useState({
    language: false,
    image: false,
    bannerPosition: false,
    bannerUrl: false,
    opensInNewTab: false,
  });

  const handleChange = (e, fieldType) => {
    const { name, value } = e.target;
    const parsedValue = fieldType === "number" ? parseInt(value) : value;
    setError({ ...error, [name]: false });
    if (fieldType === "select") {
      setAddSocial({
        ...addSocial,
        language: value,
      });
      setError({ ...error, language: false });
    } else {
      setAddSocial({
        ...addSocial,
        [name]: parsedValue,
      });
    }
    if (!value.trim()) {
      setError({ ...error, [name]: true });
    }
  };

  const handleImageUpload = (file) => {
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setError({
        ...error,
        image: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, image: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          image: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          image: false,
        });
        let name = file.name;
        if (name?.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setAddSocial({ ...addSocial, image: file });
        setImageName(name);
      }
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    const newErrors = { ...error };
    if (!addSocial.language) {
      newErrors.language = true;
      isValid = false;
    } else {
      newErrors.language = false;
    }
    if (!addSocial.bannerPosition) {
      newErrors.bannerPosition = true;
      isValid = false;
    } else {
      newErrors.bannerPosition = false;
    }

    if (!addSocial.bannerUrl) {
      newErrors.bannerUrl = true;
      isValid = false;
    } else {
      newErrors.bannerUrl = false;
    }
    if (!addSocial.image || newErrors.image) {
      newErrors.image = newErrors.image || "*Image is Required.";
      isValid = false;
    } else {
      newErrors.image = false;
    }
    setError(newErrors);
    if (isValid) {
      const formData = new FormData();
      formData.append("language", addSocial.language);
      formData.append("image", addSocial.image);
      formData.append("bannerPosition", addSocial.bannerPosition);
      formData.append("bannerUrl", addSocial.bannerUrl);
      formData.append("opensInNewTab", toggleFlag);
      try {
        const token = localStorage.getItem("secretDomainKey");
        setLoading(true);
        if (token) {
          const config = {
            headers: {
              DomainToken: `${token}`,
            },
          };
          const res =
            show === "edit"
              ? await updateSocial(data.id, config, formData)
              : await createSocial(config, formData);
          if (res?.data?.status === 200) {
            setLoading(false);
            setRecall(Math.random());
            if (show === "edit") {
              toast.success("You Have successfully Updated Data", {
                toastId: "update",
              });
            } else {
              toast.success("You Have successfully Created Data", {
                toastId: "create",
              });
            }

            setLoading(false);
            handleClose();
          } else {
            toast.error("Something went Wrong Plase try Again", {
              toastId: "tryAgain",
            });
          }
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.log("Error found while fetching data", error);
      } finally {
        setLoading(false);
      }
      console.log("Form submitted successfully:", addSocial);
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
        };
        const res = await deleteSocial(data.id, config);
        if (res?.data?.status === 200) {
          toast.success("You Have Successfully Deleted data", {
            toastId: "toats",
          });
          setLoading(false);
          setRecall(Math.random());
          handleClose();
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      {show !== "Delete" ? (
        <>
          <div className="auto-options1 popup-filter">
            <SelectInput
              listArray={language}
              defaultOption="Select Language"
              selectedValue={addSocial.language}
              handleSelectOption={(e) => handleChange(e, "select")}
            />
            {error.language && (
              <div className="text-danger">*Language is required</div>
            )}
            <div>
              <div className="border p-2 rounded-2">
                <div className="upload-image">
                  {addSocial.image ? (
                    <label htmlFor="upload" className="upload-image-box">
                      {imageName}
                      <span className="uplaod-icon">
                        <ImageIcon />
                      </span>
                    </label>
                  ) : (
                    <label htmlFor="upload" className="upload-image-box">
                      Image
                      <span className="cloud-icon">
                        <CloudUploadIcon />
                      </span>
                    </label>
                  )}
                  <input
                    type="file"
                    id="upload"
                    accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                    image/gif,
                    image/bmp,"
                    onChange={(event) =>
                      handleImageUpload(event.target.files[0])
                    }
                  />
                </div>
              </div>
              {!!error.image && (
                <div className="text-danger">{error?.image}</div>
              )}
            </div>
          </div>
          <div className="auto-options1 mt-4 popup-filter">
            <div>
              <FloatingLabel controlId="floatingInput" label="Banner Position">
                <Form.Control
                  type="number"
                  autoComplete="off"
                  name="bannerPosition"
                  value={addSocial.bannerPosition}
                  onChange={(e) => handleChange(e)}
                />
              </FloatingLabel>
              {error.bannerPosition && (
                <div className="text-danger">*Banner Position is required</div>
              )}
            </div>
            <div>
              <FloatingLabel controlId="floatingInput" label="Banner Url">
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="bannerUrl"
                  value={addSocial.bannerUrl}
                  onChange={(e) => handleChange(e)}
                />
              </FloatingLabel>
              {error.bannerUrl && (
                <div className="text-danger">*Banner Url is required</div>
              )}
            </div>
            <SwitchBox
              handleToggle={handleToggle}
              toggleFlag={toggleFlag}
              label={"Opens in new tab"}
            />
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>Are you sure you want to delete this?</p>
        </div>
      )}

      <div className="auto-btn filter-section btn-wrapper">
        {data && show === "edit" ? (
          <ButtonWrapper
            loading={loading}
            text={`${loading ? "Updating..." : "Update"}`}
            handleClick={handleSubmit}
          />
        ) : show === "Delete" ? (
          <ButtonWrapper
            loading={loading}
            text={`${loading ? "Deleting..." : "Delete"}`}
            handleClick={handleDelete}
          />
        ) : (
          <ButtonWrapper
            loading={loading}
            text={`${loading ? "Creating..." : "Submit"}`}
            handleClick={handleSubmit}
          />
        )}
        {show === "edit" && (
          <ButtonWrapper
            text={"Clear All"}
            handleClick={handleClearAll}
            clValue="clear-btn"
          />
        )}
      </div>
    </div>
  );
};
