import React from "react";
import ButtonWrapper from "../global/button";
import SelectInput from "../global/selectInput";
import FilterInput from "../global/filterInput";

const MediaFilter = ({ setRecall, setFields, fields }) => {
  const urlOptions = [
    {
      id: 1,
      value: "Url",
    },
    {
      id: 1,
      value: "Banner",
    },
    {
      id: 1,
      value: "Text",
    },
  ];

  const bannerTypeOptions = [
    {
      id: 1,
      value: "Static",
    },
    {
      id: 1,
      value: "Gif",
    },
    {
      id: 1,
      value: "Screenshot",
    },
  ];

  const linkOptions = [
    {
      id: 1,
      value: "Referral Link",
    },
  ];

  const productOptions = [
    {
      id: 1,
      value: "Sport",
    },
    {
      id: 1,
      value: "Slot",
    },
    {
      id: 1,
      value: "Live Casino",
    },
    {
      id: 1,
      value: "Other",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));

    console.log(fields);
  };

  return (
    <div className="voucher-filter">
      <div className="filter-section">
        <SelectInput
          isRequired={true}
          defaultOption="Media Type"
          listArray={urlOptions}
          selectedValue={fields?.mediaType}
          name={"mediaType"}
          handleSelectOption={handleChange}
        />
        <SelectInput
          defaultOption="Banner Type"
          listArray={bannerTypeOptions}
          selectedValue={fields?.bannerType}
          name={"bannerType"}
          // isDisabled={fields?.mediaType !== "Banner"}
          // isRequired={fields?.mediaType === "Banner"}
          handleSelectOption={handleChange}
        />

        <SelectInput
          defaultOption="Link Category"
          listArray={linkOptions}
          selectedValue={fields?.linkCategory}
          name={"linkCategory"}
          // isDisabled={fields?.mediaType !== "Url"}
          handleSelectOption={handleChange}
        />
        <SelectInput
          isRequired={true}
          defaultOption="Product"
          listArray={productOptions}
          selectedValue={fields?.product}
          name={"product"}
          // isDisabled={fields?.bannerType!=="Banner"}
          handleSelectOption={handleChange}
        />
        <FilterInput
          isRequired={true}
          type={"number"}
          label="Affiliated ID"
          name="affiliateId"
          handleChange={handleChange}
          value={fields?.affiliateId}
        />
        <ButtonWrapper
          text="Search"
          clValue="search-filter-btn"
          handleClick={() => {
            setRecall(Math.random());
          }}
        />
        <ButtonWrapper
          text="Clear All"
          clValue="clear-btn"
          handleClick={() => {
            setFields({
              mediaType: "",
              bannerType: "",
              linkCategory: "",
              product: "",
              affiliateId: "",
            });
          }}
        />
      </div>
    </div>
  );
};

export default MediaFilter;
