
import ButtonWrapper from "../../global/button";
import FilterDatePicker from "../../global/filterDatePicker";

const Filter = () => {
  return (
    <div className="voucher-filter">
      <div className="filter-section ">
        <FilterDatePicker label="From Date" name="FromDate"/>
        <FilterDatePicker label="To Date" name="To Date"/>
        <ButtonWrapper text={"Search"} />
      </div>
    </div>
  );
};
export default Filter;
