import { useState } from "react";
import ButtonWrapper from "../../global/button";
import Layout from "../../layout/layout";
import "./scripts.css";
const Scripts = () => {
  const [activeTab, sectActiveTab] = useState("Zopim");
  const handleTabs = (value) => {
    if (value !== " ") {
      sectActiveTab(value);
    } else {
      sectActiveTab(" ");
    }
  };
  return (
    <Layout title="SCRIPTS">
      <div className="notification-page payment-method-page">
        <div className="scripts-page">
          <div className="script-item">
            <h3>Google Analytics</h3>
            <textarea name="hide_google_analytics"></textarea>
            <div className="auto-btn ">
              <ButtonWrapper text="Save" />
            </div>
          </div>
          <div className="script-item">
            <div className="script-tabs">
              <ul>
                <li
                  className={`${
                    activeTab === "Zopim" ? "tabs-border-btm" : " "
                  }`}
                  onClick={() => handleTabs("Zopim")}
                >
                  Zopim
                </li>
                <li
                  className={`${
                    activeTab === "Comm100" ? "tabs-border-btm" : " "
                  }`}
                  onClick={() => handleTabs("Comm100")}
                >
                  Comm100
                </li>
                <li
                  className={`${
                    activeTab === "LiveChatInc" ? "tabs-border-btm" : " "
                  }`}
                  onClick={() => handleTabs("LiveChatInc")}
                >
                  LiveChatInc
                </li>
              </ul>
            </div>
            <textarea name="hide_google_analytics"></textarea>
            <div className="auto-btn ">
              <ButtonWrapper text="Save" />
            </div>
          </div>
          <div className="script-item">
            <h3>Otherscripts 1</h3>
            <textarea name="Otherscripts_1"></textarea>
            <div className="auto-btn ">
              <ButtonWrapper text="Save" />
            </div>
          </div>
          <div className="script-item">
            <h3>Otherscripts 2</h3>
            <textarea name="Otherscripts_2"></textarea>
            <div className="auto-btn ">
              <ButtonWrapper text="Save" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Scripts;
