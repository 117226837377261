import { FloatingLabel, Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageIcon from "@mui/icons-material/Image";
import { useEffect, useRef, useState } from "react";
import GlobalEditor from "../../global/globalEditor";
import { useMyContect } from "../../../context/myContext";
import { createPopup, deletePopup, updatePopup } from "../../../utils/Apis";
const AddPopup = ({ show, handleClose, setRecall }) => {
  const editorRef = useRef(null);
  return (
    <Modal
      show={!!show}
      onHide={handleClose}
      centered
      className={`notification-popup add-notification-popup content-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{show} Popup</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="tabs-section ">
          <AddBanner
            editorRef={editorRef}
            handleClose={handleClose}
            setRecall={setRecall}
            show={show}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddPopup;

const AddBanner = ({ editorRef, handleClose, show, setRecall }) => {
  const { data } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [addPopup, setAddPopup] = useState({
    language: "",
    intervalHours: "",
    image: "",
    detailedText: "",
  });

  useEffect(() => {
    if (show === "Edit") {
      const imageUrl = data?.image;
      let name = "";
      if (imageUrl) {
        name = imageUrl?.substring(imageUrl.lastIndexOf("/") + 1);
        if (name.length > 15) {
          name = name.slice(0, 15);
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
      }

      setAddPopup({
        language: data?.language,
        intervalHours: data?.intervalhours,
        detailedText: data?.detailedText,
        image: name,
      });
      console.log(data?.intervalHours, data);
      setImageName(name);
    }
    // eslint-disable-next-line
  }, [data]);
  const [error, setError] = useState({
    language: false,
    intervalHours: false,
    image: false,
    detailedText: false,
  });
  const language = [{ id: 1, value: "en-US" }];
  const handleChange = (e, fieldType) => {
    const { name, value } = e.target;
    const parsedValue = fieldType === "number" ? parseInt(value) : value;
    setError({ ...error, [name]: false });

    if (fieldType === "select") {
      setAddPopup({
        ...addPopup,
        language: value,
      });

      setError({ ...error, language: false });
    } else {
      setAddPopup({
        ...addPopup,
        [name]: parsedValue,
      });
    }

    if (!value.trim()) {
      setError({ ...error, [name]: true });
    }
  };

  const handleImageUpload = (file) => {
    console.log("file", file);
    const validFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
    ];
    if (!file) {
      setError({
        ...error,
        image: "*Image is required",
      });
    } else {
      if (!validFileTypes.includes(file?.type)) {
        setError({ ...error, image: "File type is not Valid" });
      } else if (file.size > 1000 * 1000) {
        setError({
          ...error,
          image: "File Size is Too Large. (Max Size - 1MB)",
        });
      } else {
        setError({
          ...error,
          image: false,
        });
        let name = file.name;
        if (name.length > 15) {
          name = name.slice(0, 15) + "...";
          // console.log(name);
        }
        name = name.replace(/_\d+/g, "");
        setAddPopup({ ...addPopup, image: file });
        setImageName(name);
      }
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    const newErrors = { ...error };
    if (!addPopup.language) {
      newErrors.language = true;
      isValid = false;
    } else {
      newErrors.language = false;
    }
    if (!addPopup.intervalHours) {
      newErrors.intervalHours = true;
      isValid = false;
    } else {
      newErrors.intervalHours = false;
    }

    if (!addPopup.detailedText) {
      newErrors.detailedText = true;
      isValid = false;
    } else {
      newErrors.detailedText = false;
    }
    if (!addPopup.image || error.image) {
      newErrors.image = error?.image || "*Image Is Required.";
      isValid = false;
    } else {
      newErrors.image = false;
    }
    setError(newErrors);
    if (isValid) {
      const formData = new FormData();
      // formData.append("id", addPopup.id);
      formData.append("language", addPopup.language);
      formData.append("image", addPopup.image);
      formData.append("detailedText", addPopup.detailedText);
      formData.append("intervalhours", addPopup.intervalHours);
      try {
        const token = localStorage.getItem("secretDomainKey");
        setLoading(true);
        if (token) {
          const config = {
            headers: {
              DomainToken: `${token}`,
            },
          };
          const res =
            show === "Edit"
              ? await updatePopup(data.id, config, formData)
              : await createPopup(config, formData);
          if (res) {
            console.log("Response is getting", res);
            setLoading(false);
            handleClose();
            setRecall(Math.random());
          }
        } else {
          console.log("No token found");
        }
      } catch (error) {
        console.log("Error found while fetching data", error);
      }
      console.log("Form submitted successfully:", addPopup);
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: token,
          },
        };
        const res = await deletePopup(data.id, config);
        if (res) {
          console.log("Response is getting", res);
          setLoading(false);
          handleClose();
          setRecall(Math.random());
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      {show !== "Delete" ? (
        <>
          <div className="auto-options1 popup-filter">
            <div>
              <SelectInput
                listArray={language}
                defaultOption="Select Language"
                selectedValue={addPopup.language}
                handleSelectOption={(e) => handleChange(e, "select")}
              />{" "}
              {error.language && (
                <div className="text-danger">*Language is required</div>
              )}
            </div>
            <div>
              <FloatingLabel
                controlId="floatingInput"
                label="Interval in Hours"
              >
                <Form.Control
                  type="number"
                  placeholder="name@example.com"
                  autoComplete="off"
                  name="intervalHours"
                  value={addPopup.intervalHours}
                  onChange={(e) => handleChange(e)}
                />
              </FloatingLabel>
              {error.intervalHours && (
                <div className="text-danger">*Interval Hours is required</div>
              )}
            </div>

            <div>
              <div className="border p-1 rounded-2">
                <div className="upload-image">
                  {addPopup.image ? (
                    <label htmlFor="upload" className="upload-image-box">
                      {imageName}
                      <span className="uplaod-icon">
                        <ImageIcon />
                      </span>
                    </label>
                  ) : (
                    <label htmlFor="upload" className="upload-image-box">
                      Image
                      <span className="cloud-icon">
                        <CloudUploadIcon />
                      </span>
                    </label>
                  )}

                  <input
                    type="file"
                    id="upload"
                    accept="image/png, image/jpeg , image/jpg , image/svg+xml,image/svg
                    image/gif,
                    image/bmp,"
                    onChange={(event) =>
                      handleImageUpload(event.target.files[0])
                    }
                  />
                </div>
              </div>
              {!!error?.image && (
                <div className="text-danger">{error?.image}</div>
              )}
            </div>
          </div>
          <div>
            <div className="editor-section">
              <h6>Text</h6>
              <GlobalEditor
                editorRef={editorRef}
                textValue={addPopup.detailedText}
                handleEditorChange={(content) =>
                  handleChange({
                    target: { name: "detailedText", value: content },
                  })
                }
              />{" "}
            </div>
            {error.detailedText && (
              <div className="text-danger">*Text is required</div>
            )}
          </div>
        </>
      ) : (
        <div className="ms-2">
          <p>Are you sure you want to delete this?</p>
        </div>
      )}
      <div className="auto-btn filter-section btn-wrapper">
        {data && show === "Edit" ? (
          <ButtonWrapper
            text={`${"Update"}`}
            loading={loading}
            handleClick={handleSubmit}
          />
        ) : show === "Delete" ? (
          <ButtonWrapper
            text={`${"Delete"}`}
            loading={loading}
            handleClick={handleDelete}
          />
        ) : (
          <ButtonWrapper
            text={`${"Submit"}`}
            loading={loading}
            handleClick={handleSubmit}
          />
        )}{" "}
      </div>
    </div>
  );
};
