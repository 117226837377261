import Layout from "../layout/layout";
import "./payment.css";
import ToggleSwitch from "../global/toggleSwitch";
import ButtonWrapper from "../global/button";
import { useEffect, useState } from "react";
import AddPaymentPopup from "./addPayment";
import AddProviderPopup from "./addProvider";
import AddChannelPopup from "./addChannel";
import AddGroupPopup from "./addGroup";
import AddKeyPopup from "./addKeyPopup";
import HorizontalTabs from "../global/horizontalTab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GlobalTable from "../global/globalTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import EditPaymentMethod from "./editPaymentMethod";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  getAllPaymentChannels,
  getAllPaymentGroups,
  getAllPaymentMethods,
  getAllPaymentProviders,
  getTransactionData,
} from "../../utils/Apis";
import { useMyContect } from "../../context/myContext";
import { Modal } from "react-bootstrap";

const PaymentMethods = () => {
  const [addPaymentType, setAddPaymentType] = useState("");
  const { setData, setIsLoading } = useMyContect();
  const tabs = ["Configurations", "Providers", "Channels", "Groups"];
  const [activeTab, setActiveTab] = useState("Configurations");
  const handleTabs = (value) => setActiveTab(value);
  const [recall, setRecall] = useState(0);

  const handlePaymentType = (value) => {
    console.log(value, activeTab);
    if (value) {
      setAddPaymentType(value);
    } else {
      setAddPaymentType("");
      setData(null);
      setIsLoading(null);
    }
  };
  const renderTable = () => {
    switch (activeTab) {
      case "Configurations":
        return (
          <ConfigurationTable
            setAddPaymentType={setAddPaymentType}
            addPaymentType={addPaymentType}
            activeTab={activeTab}
            recall={recall}
          />
        );
      case "Providers":
        return (
          <ProviderTable
            setAddPaymentType={setAddPaymentType}
            addPaymentType={addPaymentType}
            activeTab={activeTab}
            recall={recall}
          />
        );
      case "Channels":
        return (
          <ChannelsTable
            setAddPaymentType={setAddPaymentType}
            addPaymentType={addPaymentType}
            activeTab={activeTab}
            recall={recall}
          />
        );
      case "Groups":
        return (
          <GroupTable
            setAddPaymentType={setAddPaymentType}
            addPaymentType={addPaymentType}
            recall={recall}
          />
        );
      default:
        return null;
    }
  };
  //  Api calling here for the transaction
  const handleGetTransactionData = async () => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
        };
        const res = await getTransactionData(config);
        console.log("Response is getting", res);
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  useEffect(() => {
    handleGetTransactionData();
  }, []);

  return (
    <Layout title="Payment Method">
      <div className="payment-method-page payment-first">
        <div className="tabs-section notification-tabs commision-popup">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            <ButtonWrapper
              text={`Add ${
                activeTab === "Configurations"
                  ? "Payment"
                  : activeTab === "Groups"
                  ? " "
                  : activeTab
              }`}
              icon={
                <span className="add-icon">
                  <AddBoxIcon />
                </span>
              }
              clValue={"send-notificaton"}
              handleClick={() => handlePaymentType("Create")}
            />
          </div>
          {renderTable()}
        </div>
      </div>
      <AddPaymentPopup
        show={activeTab === "Configurations" && !!addPaymentType}
        addPaymentType={addPaymentType}
        handleClose={handlePaymentType}
        recall={recall}
        setRecall={setRecall}
      />
      <AddProviderPopup
        show={activeTab === "Providers" && !!addPaymentType}
        addPaymentType={addPaymentType}
        handleClose={handlePaymentType}
        setRecall={setRecall}
        recall={recall}
      />
      <AddChannelPopup
        show={activeTab === "Channels" && !!addPaymentType}
        addPaymentType={addPaymentType}
        handleClose={handlePaymentType}
        setRecall={setRecall}
        recall={recall}
      />
      <AddGroupPopup
        show={activeTab === "Groups" && !!addPaymentType}
        addPaymentType={addPaymentType}
        handleClose={handlePaymentType}
        setRecall={setRecall}
        recall={recall}
      />
      <AddKeyPopup
        show={activeTab === "Add Data Keys" && !!addPaymentType}
        addPaymentType={addPaymentType}
        handleClose={handlePaymentType}
      />
    </Layout>
  );
};
export default PaymentMethods;

const ConfigurationTable = ({ recall, setAddPaymentType }) => {
  const [columnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      width: 50,
      cellStyle: { color: "grey", left: "15px" },
    },
    {
      headerName: "Name",
      field: "name",
      width: 100,
      cellStyle: { color: "#4caf50", "text-align": "center" },
    },
    {
      headerName: "Provider",
      field: "paymentProvider",
      width: 100,
      cellStyle: { color: "#4caf50", "text-align": "center" },
    },
    {
      headerName: "Channel",
      field: "paymentChannel",
      width: 100,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Type",
      field: "paymentMethodType",
      width: "120px",
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Enabled",
      field: "enabled",
      width: 100,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Deposit Active",
      field: "depEnabled",
      width: 150,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Withdraw Enable",
      field: "withEnabled",
      width: 150,
      cellStyle: { "text-align": "center" },
    },
    // {
    //   headerName: "KYC",
    //   field:"",
    //   width: 100,
    //   cellStyle: { cellStyle: { "text-align": "center" } },
    // },
    {
      headerName: "Limited Groups",
      field: "userGroup",
      width: "120px",
      cellStyle: { "text-align": "center" },
    },

    {
      headerName: "Small Image",
      field: "smallImage",
      width: 150,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Large Image",
      field: "largeImage",
      width: 150,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "",
      field: "",
      cellRenderer: () => <Actions setAddPaymentType={setAddPaymentType} />,
    },
  ]);

  const [rowData, setRowData] = useState([
    {
      ID: "Metabet777",
      Name: "15492520	",
      Provider: "anitest",
      Channel: "anil",
      Mode: "singh",
      Type: "anielchishvili@gmail.com",
      Enabled: "4848494949494	",
      Pin: "1",
      "Deposit Active": "123",
      "Withdraw Enable": "6.17 USD",
      KYC: "6.17 USD",
      "Limited Groups": "Not Verified",
      Password: "05-01-2024 08:26:22",
      "Auth Type": "12/12/12",
    },
    {
      ID: "Metabet777",
      Name: "15492520	",
      Provider: "anitest",
      Channel: "anil",
      Mode: "singh",
      Type: "anielchishvili@gmail.com",
      Enabled: "4848494949494	",
      Pin: "1",
      "Deposit Active": "123",
      "Withdraw Enable": "6.17 USD",
      KYC: "6.17 USD",
      "Limited Groups": "Not Verified",
      Password: "05-01-2024 08:26:22",
      "Auth Type": "12/12/12",
    },
    {
      ID: "Metabet777",
      Name: "15492520	",
      Provider: "anitest",
      Channel: "anil",
      Mode: "singh",
      Type: "anielchishvili@gmail.com",
      Enabled: "4848494949494	",
      Pin: "1",
      "Deposit Active": "123",
      "Withdraw Enable": "6.17 USD",
      KYC: "6.17 USD",
      "Limited Groups": "Not Verified",
      Password: "05-01-2024 08:26:22",
      "Auth Type": "12/12/12",
    },
  ]);

  const handleGetPaymentMethodData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        const res = await getAllPaymentMethods(config);
        console.log("res", res.data.data);
        if (res) {
          if (res && res.data && res.data.data) {
            setRowData(res.data?.data);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  useEffect(() => {
    handleGetPaymentMethodData(1, 20);
  }, [recall]);
  return (
    <div className="deposit-table config-table">
      <div className="top-area">
        <h6>Payment Methods</h6>
        <div className="toggle-switch">
          <p>Active</p>
          <ToggleSwitch />
        </div>
      </div>

      <GlobalTable
        columnDefs={columnDefs.map((colDef) =>
          colDef.field === "smallImage" || colDef.field === "largeImage"
            ? { ...colDef, cellRenderer: RenderImage }
            : colDef
        )}
        rowData={rowData}
      />
    </div>
  );
};

const ProviderTable = ({ recall, setAddPaymentType }) => {
  const [columnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      width: 50,
      cellStyle: { color: "grey", left: "15px" },
    },
    {
      headerName: "Name",
      field: "name",
      width: 100,
      cellStyle: { color: "#4caf50", "text-align": "center" },
    },
    {
      headerName: "Enabled",
      field: "enabled",
      width: 100,
      cellStyle: { "text-align": "center" },
    },

    {
      headerName: "Action",
      cellRenderer: () => <Actions setAddPaymentType={setAddPaymentType} />,
    },
  ]);

  const [rowData, setRowData] = useState([]);

  const handleGetPaymentProviderData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        const res = await getAllPaymentProviders(config);
        console.log("res", res.data.data);
        if (res) {
          if (res && res.data && res.data.data) {
            setRowData(res.data?.data);
            // setSiteTextData(transformedData);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  useEffect(() => {
    handleGetPaymentProviderData(1, 20);
  }, [recall]);
  return (
    <div className="deposit-table config-table">
      <div className="top-area">
        <h6>Providers</h6>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

const ChannelsTable = ({ recall, setAddPaymentType }) => {
  const [columnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      width: 50,
      cellStyle: { color: "grey", left: "15px" },
    },
    {
      headerName: "Name",
      field: "name",
      width: 100,
      cellStyle: { color: "#4caf50", "text-align": "center" },
    },
    {
      headerName: "Enabled",
      field: "enabled",
      width: 100,
      cellStyle: { "text-align": "center" },
    },

    {
      headerName: "Action",
      cellStyle: { "text-align": "center" },
      // field:"",
      cellRenderer: () => <Actions setAddPaymentType={setAddPaymentType} />,
    },
  ]);

  const [rowData, setRowData] = useState([]);

  const handleGetPaymentChannelData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        const res = await getAllPaymentChannels(config);
        console.log("data", res);
        if (res) {
          if (res && res.data && res.data.data) {
            setRowData(res.data?.data);
            // setSiteTextData(transformedData);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  useEffect(() => {
    handleGetPaymentChannelData(1, 20);
  }, [recall]);
  return (
    <div className="deposit-table config-table">
      <div className="top-area">
        <h6>Providers</h6>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

const GroupTable = ({ recall, setAddPaymentType }) => {
  const [columnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      width: 150,
      cellStyle: { color: "grey", left: "15px" },
    },
    {
      headerName: "Name",
      field: "name",
      width: 100,
      cellStyle: { color: "#4caf50", "text-align": "center" },
    },
    {
      headerName: "Enabled",
      field: "enabled",
      width: 100,
      cellStyle: { "text-align": "center" },
    },

    {
      headerName: "Small Image",
      field: "smallImage",
      width: 150,
      cellStyle: { "text-align": "center" },
    },
    {
      headerName: "Large Image",
      field: "largeImage",
      width: 150,
      cellStyle: { "text-align": "center" },
    },

    {
      headerName: "Action",
      cellStyle: { "text-align": "center" },
      // field:"",
      cellRenderer: () => <Actions setAddPaymentType={setAddPaymentType} />,
    },
  ]);

  const [rowData, setRowData] = useState([]);

  const handleGetPaymentGroupData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        const res = await getAllPaymentGroups(config);
        if (res) {
          if (res && res.data && res.data.data) {
            setRowData(res.data?.data);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    }
  };

  useEffect(() => {
    handleGetPaymentGroupData(1, 20);
  }, [recall]);
  return (
    <div className="deposit-table config-table">
      <div className="top-area">
        <h6>Group</h6>
      </div>

      <GlobalTable
        columnDefs={columnDefs.map((columnDefs) =>
          columnDefs.field === "smallImage" || columnDefs.field === "largeImage"
            ? {
                ...columnDefs,
                cellRenderer: RenderImage,
              }
            : columnDefs
        )}
        rowData={rowData}
      />
    </div>
  );
};

const Actions = ({ setAddPaymentType }) => {
  const [flag, setFlag] = useState(false);
  const handlePoppup = (value) => {
    setFlag(!flag);
    setAddPaymentType(value);
  };

  return (
    <>
      <div className="actions-outer">
        <span className="action" onClick={() => handlePoppup("Edit")}>
          <EditIcon />
        </span>
        <span className="action ms-2" onClick={() => handlePoppup("Delete")}>
          <DeleteIcon />
        </span>
      </div>
      {/* <EditPaymentMethod show={flag} handleClose={handlePoppup} /> */}
    </>
  );
};

const RenderImage = (params) => {
  const [show, setShow] = useState(false);

  console.log("params", params)
  const handleClick = () => {
    setShow(!show);
  };

  const handleDownload = () => {
    const splitArr = params?.value.split("/");
    const fileName = splitArr[splitArr.length - 1];
    fetch(process.env.REACT_APP_S3URL + params?.value)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setShow(false);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      <img
        className="table-image"
        role="button"
        onClick={handleClick}
        src={process.env.REACT_APP_S3URL +params.value}
        alt="Social Icon"
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        centered
        // className="notification-popup add-payment-popup add-commission-popup"
      >
        <Modal.Body>
          {/* {console.log("image value>>>>", params?.value)} */}
          <img
            className="image-preview"
            src={process.env.REACT_APP_S3URL+params?.value}
            alt="Social Icon"
          />
          <div className="text-center mt-2">
            {/* Add a download button */}
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                handleDownload();
              }}
            >
              Download <CloudDownloadIcon />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
