// import { useState } from "react";
import Layout from "../../layout/layout.jsx";
// import DatePicker from "../../global/datePicker.jsx";
import Filter from "./filter.jsx";
// import CountryTable from "./countryTable.jsx";
import BonusTable from "./bonusTable.jsx";
// import FirstDepositTable from "./firstDepositTable.jsx";
import "./bonus.css"
const BonusReport = () => {
  return (
    <Layout title="BONUS REPORT">
      <div className="notification-page notification-popup  users-page cashdesk-page report-pages">
        <div className="cashdesk-section ">
          <Filter />
          <BonusTable />
        </div>
      </div>
    </Layout>
  );
};
export default BonusReport;
