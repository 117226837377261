import { Button } from "react-bootstrap";
import Layout from "../layout/layout";
import DatePicker from "../global/datePicker";
import NotificationTable, { TemplateTable } from "./notificationTable";
import SendNotification from "./sendNotificationPopup";
import { useState } from "react";
import AddNotificationPopup from "./addNotificationPopup";
import HorizontalTabs from "../global/horizontalTab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import "./notification.css";
const tabs = ["Notifications", "Notification Templates"];

const tabs1 = [
  "Today",
  "Yesterday",
  "Past Week",
  "Current Month",
  "Last Month",
];

const Notifications = () => {
  const [sendNotification, setSendNotification] = useState("");
  const [activeTab, setActiveTab] = useState("Notifications");
  const [activeTab1, setActiveTab1] = useState("Today");
  const [filter, setFilter] = useState({
    fromDate: "",
    endDate: "",
    templateId: "",
    userId: "",
  })
  const [notificationFilter, setNotificationFilter] = useState('')

  const handleNotification = (value) => {
    if (value !== "") {
      setSendNotification(value);
    } else {
      setSendNotification("");
    }
  };

  const handleTabs = (value) => {
    // console.log('value', value);
    setActiveTab(value);
  };

  const handleTabs1 = async (value) => {
    // console.log('value1', value);

    setActiveTab1(value);
  };
  const [refreshTemp, setRefreshTemp] = useState('')
  const [tempData, setTempData] = useState('')
  const [refreshNoti, setRefreshNoti] = useState('')
  const [notiData, setNotiData] = useState('')
  // console.log(sendNotification);
  return (
    <Layout title="Notifications">
      <div className="notification-page">
        <div className="tabs-section notification-tabs notification-filter-grid">
          <div className="horizontal-tabs notification-first">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            {activeTab === "Notifications" ? (
              <Button
                className="send-notificaton"
                onClick={() => {setNotiData(''); handleNotification("sendNotification")}}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Send Notifications
              </Button>
            ) : (
              <Button
                className="send-notificaton"
                onClick={() => { setTempData(''); handleNotification("addNotification"); }}
              >
                <span className="add-icon">
                  <AddBoxIcon />
                </span>{" "}
                Add Notification Template
              </Button>
            )}
          </div>
          {activeTab === "Notifications" &&
            <div>
              <div className="horizontal-tabs">
                <HorizontalTabs
                  activeTab={activeTab1}
                  handleTabs={handleTabs1}
                  tabs={tabs1}
                />
                <div className="date-filter">
                  <DatePicker
                    data={filter}
                    setData={setFilter}
                    handleClick={() => { setNotificationFilter(Math.random()) }}
                  />
                </div>
              </div>
            </div>
          }
        </div>
        {activeTab === "Notifications" ?
          <NotificationTable
            activeTab1={activeTab1}
            isClick={notificationFilter}
            filteritem={filter}
            refreshNoti={refreshNoti}
            setNotiData={item => {
              setNotiData(item)
              handleNotification("sendNotification");
            }}
          />
          : <TemplateTable
            refreshTemp={refreshTemp}
            setTempData={item => {
              setTempData(item)
              handleNotification("addNotification");
            }}
          />
        }
      </div>
      <SendNotification
        show={sendNotification === "sendNotification"}
        handleClose={handleNotification}
        setRefreshNoti={setRefreshNoti}
        notiData={notiData}
      />
      <AddNotificationPopup
        tempData={tempData}
        handleClose={handleNotification}
        setRefreshTemp={setRefreshTemp}
        show={sendNotification === "addNotification"}
      />
    </Layout>
  );
};
export default Notifications;
