import React from "react";

const BonusStepHeader = () => {
  return (
    <div className="steps-chain">
      <div className="step active step-first">
        <span className="number-holder">
          <span className="number">1</span>{" "}
        </span>
        <span className="step-title">Bonus Template</span>
      </div>
      <div className="step">
        <span className="number-holder">
          <span className="number">2</span>{" "}
        </span>
        <span className="step-title">Bonus Activator</span>
      </div>
      <div className="step ">
        <span className="number-holder">
          <span className="number">3</span>{" "}
        </span>
        <span className="step-title">Wager Item</span>
      </div>
      <div className="step">
        <span className="number-holder">
          <span className="number">4</span>{" "}
        </span>
        <span className="step-title">Wager Currency</span>
      </div>
      <div className="step wager-game-item">
        <span className="number-holder">
          <span className="number">5</span>{" "}
        </span>
        <span className="step-title">Wager Game Item</span>
      </div>
      <div className="step conditions last-step">
        <span className="number-holder">
          <span className="number">6</span>
        </span>
        <span className="step-title">Conditions</span>
      </div>
    </div>
  );
};

export default BonusStepHeader;
