import React from "react";
import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";
import FilterInput from "../../global/filterInput";

const Filter = () => {
  const currencyOptions = [
    { id: 1, value: "AED" },
    { id: 2, value: "AFN" },
    { id: 3, value: "ALL" },
    { id: 4, value: "AMD" },
    { id: 5, value: "ANG" },
    { id: 6, value: "AOA" },
    { id: 7, value: "ARS" },
    { id: 8, value: "AUD" },
  ];

  return (
    <div className="voucher-filter">
      <div className="filter-section filter-pd">
        <FilterInput label="User ID" name="UserID"/>
        <SelectInput defaultOption="User Group" listArray={currencyOptions} />
        <SelectInput defaultOption="COUNTRY" listArray={currencyOptions} />
        <SelectInput
          defaultOption="Payment Provider"
          listArray={currencyOptions}
        />
        <SelectInput
          defaultOption="Payment Channel"
          listArray={currencyOptions}
        />
        <SelectInput
          defaultOption="Default Currency"
          listArray={currencyOptions}
        />
        <FilterInput label="Choose Currency" name="Choose Currency" flag={true}/>
        <ButtonWrapper text="Search" clValue="search-filter-btn" />
      </div>
    </div>
  );
};

export default Filter;
