import React from "react";
import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import FilterDatePicker from "../global/filterDatePicker";

const AddCommissionStage = ({ show, handleClose }) => {
  const currencyOptions = [
    {
      id: 1,
      value: "AED",
    },
  ];

  const renderFloatingLabel = (label, controlId, name,disabled) => (
    <FloatingLabel controlId={controlId} label={label}>
      <Form.Control
        type="text"
        placeholder="name@example.com"
        autoComplete="off"
        name={name}
        disabled={disabled}
      />
    </FloatingLabel>
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="notification-popup add-payment-popup add-commission-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Commission Stage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          <div className="row-one">
            <SelectInput defaultOption="Global" listArray={currencyOptions} />
            {renderFloatingLabel("", "floatingInput", "username",true)}
          </div>
          <div className="row-one">
            {renderFloatingLabel("Level Name", "floatingInput", "username")}
            <FilterDatePicker label={"NGR From"} name={"NGRFrom"} />
            <FilterDatePicker label={"NGR To"} name={"NGRTo"} />
            {renderFloatingLabel(
              "Commission Increase",
              "floatingInput",
              "username"
            )}
          </div>
          <div className="payment-btn">
            <ButtonWrapper text="Add" />
            <ButtonWrapper text="Clear All" clValue="clear-btn" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCommissionStage;
