import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import { Form } from "react-bootstrap";
const Filter = ({ activeTab, bannerPage, handleSearch, handleSelectChange }) => {
  let Currencyoptions = [
    {
      id: 1,
      value: "AED",
    },
    {
      id: 1,
      value: "AFN",
    },
    {
      id: 1,
      value: "ALL",
    },
    {
      id: 1,
      value: "AMD",
    },
    {
      id: 1,
      value: "ANG",
    },
    {
      id: 1,
      value: "AOA",
    },
    {
      id: 1,
      value: "ARS",
    },
    {
      id: 1,
      value: "AUD",
    },
  ];

  return (
    <div
      className={`datepicker filter-Tab filter-section filter-tab-pb ${
        activeTab === "Notifications" ? "" : "active-tab-noti"
      }`}>
      <SelectInput defaultOption={"Choose Lang"} listArray={Currencyoptions} />
      <Form.Select
        aria-label="Default select example"
        className="form-floating"
        onChange={(e) => handleSelectChange(e)}
        >
        <option disabled selected>
          Choose a banner
        </option>
        {Object.keys(bannerPage).map((el) => (
          <optgroup label={el}>
            {bannerPage[el].map((elem) => (
              <option value={elem.pageName} key={elem.pageName}>
                {elem.pageName}
              </option>
            ))}
          </optgroup>
        ))}
      </Form.Select>
      <ButtonWrapper handleClick={handleSearch} text="Search" />
    </div>
  );
};
export default Filter;
