import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const BonusTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Bonus", width:"100px"},
    { field: "Bonus Template ID", width:"150px" },
    { field: "Creator Name", width:"150px" },
    { field: "Activator Type", width:"150px" },
    { field: "Activator Name", width:"150px" },
    { field: "Activator User Name	", width:"150px" },
    { field: "Activation Count", width:"150px"},
    { field: "User ID", width:"150px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "User Name",width:"100px" },
    { field: "Affiliate Name",width:"100px" },
    { field: "Email",width:"100px" },
    { field: "Bonus Amount", width:"150px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "Current Rollover",width:"200px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" }, 
    { field: "Realization Rollover",width:"150px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "Activation Amount",width:"150px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "NGR",width:"100px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "Balance",width:"150px", sortable:true, sortingOrder: ["asc", "desc"],
    sort: "desc" },
    { field: "Status",width:"100px", },
    { field: "Create Date",width:"100px", },
    { field: "Active Till",width:"100px", },
    { field: "Last Activation",width:"150px", },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Bonus Report</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs}/>
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default BonusTable;
