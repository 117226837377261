import { useState } from "react";
import GlobalTable from '../../global/globalTable';

const StatisticsTable = () => {
    const [rowData] = useState([]);
  
    const [columnDefs] = useState([
      { field: "ID" },
      { field: "Game" },
      { field: "Currency" },
      { field: "Bet Amount" },
      { field: "Win Amount" },
      { field: "Profit" },
      { field: "Bet Count" },
      { field: "Win Count" },
      { field: "User Count" },
      { field: "Probability" },
    ]);
  
    return (
      <div className="deposit-table">
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default StatisticsTable