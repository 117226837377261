import { useState } from "react";
import GlobalTable from "../../global/globalTable";
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const PaymentTable = () => {
  const [rowData] = useState([]);
  const [columnDefs] = useState([
    { field: "Payment Channel	" },
    { field: "Payment Provider" },
    { field: "Total Deposit" },
    { field: "Deposit Count" },
    { field: "Depositing Users" },
    { field: "FTD	" },
    { field: "Chargeback Amount" },
    { field: "Chargeback Count" },
    { field: "Refund" },
    { field: "Total Withdraw" },
    { field: "Withdraw Count" },
    { field: "Withdrawing Users" },
    { field: "FTW" },
    { field: "Difference" },
    { field: "Currency" },
  ]);
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Payment Statistics</h6>
        <div className="right-area">
          <ExportExcel />
          <SelectInput listArray={options} defaultOption="20" />
          <TableColumns tableData={columnDefs} />
        </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );
};

export default PaymentTable;
