import { FloatingLabel, Form, Modal } from "react-bootstrap";
import SelectInput from "../global/selectInput";
import ButtonWrapper from "../global/button";
import UploadImage from "../global/uploadImage";
import SwitchBox from "../global/switchBox";

const BonusPopup = ({ show, handleClose, activeTab }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className={`notification-popup add-notification-popup banner-page1 bonus-pop ${
        activeTab === "Freespins" ? " " : "bonuses-popup"
      }  ${activeTab === "Promotions" ? "sitePopupWidth" : " "}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {activeTab === "Free Spin Bonus" && "Add Free Spin Promo"}
          {activeTab === "Freespins" && "Add Manual Bonus"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-section">
          {activeTab === "Free Spin Bonus" && <FreeSpinsPopup />}
          {activeTab === "Freespins" && <AddMenualBonus />}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BonusPopup;

const FreeSpinsPopup = () => {
  let options = [
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];
  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup">
      <div className="popup-filter ">
        <FloatingLabel controlId="floatingInput" label="Name">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Amount">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Start Date">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="To Date">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Usability Hours">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
      </div>
      <div className="popup-filter spin_promo_text ">
        <FloatingLabel controlId="floatingInput" label="Promo Code">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <SwitchBox label={"Single Use"} />
        <SwitchBox label={"Active"} />
        <SwitchBox label={"CURRENCY_CONTROL_ENABLED"} />
        <FloatingLabel controlId="floatingInput" label="Max Activation Count">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <SelectInput listArray={options} defaultOption="Preferred Bet Mode" />
      </div>
      <div className="popup-filter spin_promo_text ">
        <SwitchBox label={"Is Signup Promo"} />
      </div>
      <div className="auto-btn">
        <ButtonWrapper text="Create" />
      </div>
    </div>
  );
};

const AddMenualBonus = () => {
  return (
    <div className="automaticaly-content manual-content add-payment-popup notification-popup MenualBonuspopup">
      <div className="popup-filter1 filter-section ">
        <FloatingLabel controlId="floatingInput" label="Name">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Amount">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <div className="form-floating">
          <UploadImage label={"Choose File"} />
        </div>
      </div>
      <div className="popup-filter1 filter-section ">
        <FloatingLabel controlId="floatingInput" label="Start Date">
          <Form.Control
            type="date"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="To Date">
          <Form.Control
            type="date"
            placeholder="name@example.com"
            autoComplete="off"
            name="username"
          />
        </FloatingLabel>
        <div className="form-floating">
          <SwitchBox label={"Active"} />
        </div>
      </div>
      <ManualBonusBottom />
      <div className="auto-btn">
        <ButtonWrapper text="Create" />
      </div>
    </div>
  );
};

const ManualBonusBottom = () => {
  return (
    <>
      <div className="container manual_bonus pt-3">
        <div className="d-flex justify-content-between">
          <div className="manual_bonus_left">
            <p>Vendors</p>
            <FloatingLabel controlId="floatingInput" label="Search">
              <Form.Control
                type="text"
                placeholder="name@example.com"
                autoComplete="off"
                name="username"
              />
            </FloatingLabel>
            <ul className="manual_bonus_ul">
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
              <li>
                <p className="m-0 p-0">sadsdf</p>
                <i className="las la-plus-circle"></i>
              </li>
            </ul>
          </div>
          <div className="manual_bonus_left">
            {" "}
            <p>Freespin Add Game Templates</p>
            <FloatingLabel controlId="floatingInput" label="Search">
              <Form.Control
                type="text"
                placeholder="name@example.com"
                autoComplete="off"
                name="username"
              />
            </FloatingLabel>
          </div>
        </div>
      </div>
    </>
  );
};
