import React from "react";
import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";
import SwitchBox from "../../global/switchBox";
import FilterInput from "../../global/filterInput";

const Filter = () => {
  const currencyOptions = [
    { id: 1, value: "AED" },
    { id: 2, value: "AFN" },
    { id: 3, value: "ALL" },
    { id: 4, value: "AMD" },
    { id: 5, value: "ANG" },
    { id: 6, value: "AOA" },
    { id: 7, value: "ARS" },
    { id: 8, value: "AUD" },
  ];

  return (
    <div className="voucher-filter">
      <div className="filter-section notification-popup mb-filter">

        <FilterInput label="User ID" name="UserID" />
        <SelectInput defaultOption="User Group" listArray={currencyOptions} />

        <FilterInput label="Affiliated ID" name="AffiliatedID"/>
        <SelectInput defaultOption="Status" listArray={currencyOptions} />
        <SelectInput defaultOption="test-bonus" listArray={currencyOptions} />
      </div>
      <div className="filter-section notification-popup">
        <SelectInput
          defaultOption="Per User Bonus Activation"
          listArray={currencyOptions}
        />
          <SwitchBox label="Omit Canceled" />
      
          <SwitchBox label="Omit Paused" />

        <ButtonWrapper text="Search" clValue="search-filter-btn" />
      </div>
    </div>
  );
};

export default Filter;
