import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Login from "./components/login/login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Dashboard from "./components/dashboard";
import Users from "./components/users";
import Notifications from "./components/notifications/notifications";
import Orders from "./components/orders/order";
import PaymentMethods from "./components/payment/paymentMethod";
import Voucher from "./components/voucher/voucher";
import PaymentRestrction from "./components/paymentRestriction/paymentRestriction";
import SystemTransaction from "./components/systemTransaction/systemTransaction";
import AffiliateList from "./components/affiliate/affiliateList";
import AffiliateRequests from "./components/affiliate/affiliateRequests";
import MediaTemplates from "./components/affiliate/mediaTemplates";
import RegisterAffiliate from "./components/affiliate/registerAffiliate";
import CommissionStages from "./components/affiliate/commissionStages";
import AffiliateReport from "./components/affiliate/affiliateReport";
import AffiliateDeposit from "./components/affiliate/affiliateDeposit";
import AffiliateUserReport from "./components/affiliate/affiliateUserReport";
import AffiliateMonthlyPayment from "./components/affiliate/affiliateMonthlyPayment";
import UserGroup from "./components/riskControl/userGroup/userGroup";
import BetLimits from "./components/riskControl/betPayments/betLimits";
import Transaction from "./components/riskControl/transaction/transaction";
import KycVerification from "./components/riskControl/kycVerification/kycVerification";
import KycForm from "./components/riskControl/kycForm/kycForm";
import LoginLog from "./components/riskControl/loginLog/loginLog";
import GeneralStatistics from "./components/report/genrealStatistics/generalStatistics";
import VendorStatistics from "./components/report/vendorStatistics/vendorStatistics";
import UserStatistics from "./components/report/userStatistics/userStatistics";
import UserGameStatistics from "./components/report/userGame/userStatistics";
import GameStatistics from "./components/report/game/gameStatistics";
import Registration from "./components/report/registration/registration";
import Authorization from "./components/report/authorization/authorization";
import Cashdesk from "./components/report/cashdesk/cashdesk";
import TotalDeposit from "./components/report/totalDeposit/totalDeposit";
import AdminIPInfo from "./components/report/adminIPInfo/adminIPInfo";
import PerformanceStatics from "./components/report/performanceStatistics/performanceStatistics";
import FirstDeposit from "./components/report/firstDeposit/firstDeposit";
import CountryStatistics from "./components/report/countryStatistics/countryStatistics";
import PaymentStatistics from "./components/report/paymentStatistics/paymentStatistics";
import BonusReport from "./components/report/bonusReport/bonusReport";
import BonusTemplateReport from "./components/report/BonusTemplateReport/BonusTemplateReport";
import ActivityStatistics from "./components/report/activityStatistics/activityStatistics";
import TopUserReport from "./components/report/topUserReport/topUserReport";
import BrandBalance from "./components/report/brandBalnce/brandBalance";
import UserDailyBalance from "./components/report/UserDailyBalance/userDailyBalance";
import AdminComment from "./components/report/adminComment/adminComment";
import SiteContent from "./components/contents/siteContent/siteContent";
import SiteText from "./components/contents/siteText/siteText";
import Banners from "./components/contents/banners/banners";
import ContentPopups from "./components/contents/contentPopups/contentPopups";
import MainPage from "./components/contents/mainPage/mainPage";
import Socials from "./components/contents/social/social";
import PushNotification from "./components/contents/pushNotification/pushNotification";
import Scripts from "./components/contents/scriptPage/scripts";
import GeneralContent from "./components/contents/generalContent/generalContent";
import Translation from "./components/contents/translation/translation";
import Comments from "./components/Comments/comments";
import Bonus from "./components/bonus/bonus";
import CreateBonus from "./components/bonus/createBonus";
import Profile from "./components/profile/profile";
import PrivateRoute from "./components/privateRoutes/PrivateRotes";
import { getLoginDetails } from "./utils/Apis";
import { useMyContect } from "./context/myContext";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import AdminSiteContent from "./components/superAdminManagement/adminSiteContent";
import AddSiteContentBox from "./components/superAdminManagement/addSiteContentPage";

function App() {
  const { setUser } = useMyContect();
  const token = localStorage.getItem("accToken") || "";
  const getLoginAdminDetails = async () => {
    try {
      const res = await getLoginDetails();
      if (res?.data) {
        setUser(res?.data);
      }
      else {
        localStorage.clear();
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    if (token) {
      getLoginAdminDetails();
    }
    // eslint-disable-next-line
  }, [token]);

  return (
    <div className="App" id="app">
      <Router>
        <Toaster />
        <Routes >
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoute token={token} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/orders" element={<Orders />} />

            {/* **********PAyment Routes************* */}
            <Route path="/payment-methods" element={<PaymentMethods />} />
            <Route path="/voucher" element={<Voucher />} />
            <Route
              path="/payment-restriction"
              element={<PaymentRestrction />}
            />

            <Route path="/system-transaction" element={<SystemTransaction />} />

            <Route path="/transaction" element={<Transaction />} />
            <Route path="/affiliate-list" element={<AffiliateList />} />
            <Route path="/affiliate-requests" element={<AffiliateRequests />} />
            <Route path="/media-templates" element={<MediaTemplates />} />
            <Route
              path="/register-affiliates"
              element={<RegisterAffiliate />}
            />
            <Route path="/commission-stages" element={<CommissionStages />} />
            <Route path="/kyc-Form" element={<KycForm />} />
            <Route path="/login-log" element={<LoginLog />} />
            <Route path="/kycVerification" element={<KycVerification />} />
            <Route path="/affiliate-report" element={<AffiliateReport />} />
            <Route path="/affiliate-deposit" element={<AffiliateDeposit />} />
            <Route
              path="/affiliate-user-report"
              element={<AffiliateUserReport />}
            />
            <Route
              path="/monthly-payment"
              element={<AffiliateMonthlyPayment />}
            />
            <Route path="/user-group" element={<UserGroup />} />
            <Route path="/bet-limit" element={<BetLimits />} />

            {/* ***************Report routes********** */}
            <Route path="/general-statistics" element={<GeneralStatistics />} />
            <Route
              path="/performance-statistics"
              element={<PerformanceStatics />}
            />
            <Route
              path="/activityStatistics"
              element={<ActivityStatistics />}
            />
            <Route path="/paymentStatistics" element={<PaymentStatistics />} />
            <Route path="/bonusReport" element={<BonusReport />} />
            <Route path="/topUserReport" element={<TopUserReport />} />
            <Route path="/siteContent" element={<SiteContent />} />
            <Route
              path="/BonusTemplateReport"
              element={<BonusTemplateReport />}
            />
            <Route path="/createBonus" element={<CreateBonus />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/translation" element={<Translation />} />
            <Route path="/comments" element={<Comments />} />
            <Route path="/scripts" element={<Scripts />} />
            <Route path="/generalContent" element={<GeneralContent />} />
            <Route path="/mainPage" element={<MainPage />} />
            <Route path="/social" element={<Socials />} />
            <Route path="/pushNotification" element={<PushNotification />} />
            <Route path="/banners" element={<Banners />} />
            <Route path="/contentPopup" element={<ContentPopups />} />
            <Route path="/siteText" element={<SiteText />} />
            <Route path="/brandBalance" element={<BrandBalance />} />
            <Route path="/userDailyBalance" element={<UserDailyBalance />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/adminComment" element={<AdminComment />} />
            <Route path="/firstDeposit" element={<FirstDeposit />} />
            <Route path="/countryStatistics" element={<CountryStatistics />} />
            <Route path="/vendor-statistics" element={<VendorStatistics />} />
            <Route path="/user-statistics" element={<UserStatistics />} />

            <Route
              path="/usergame-statistics"
              element={<UserGameStatistics />}
            />
            <Route path="/game-statistics" element={<GameStatistics />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/authorization" element={<Authorization />} />
            <Route path="/cashdesk" element={<Cashdesk />} />
            <Route path="/total-deposit" element={<TotalDeposit />} />
            <Route path="/ip-info" element={<AdminIPInfo />} />
            <Route path="/addSiteContent" element={<AdminSiteContent />} />

            <Route path="/create-site" element={<AddSiteContentBox />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
