import { useState } from "react";
import GlobalTable from '../../global/globalTable';
import ExportExcel from "../../global/exportExcel";
import SelectInput from "../../global/selectInput";
import TableColumns from "../../global/tableColumns";

const FirstDepositTable = () => {
    const [rowData] = useState([]);
    const [columnDefs] = useState([
      { field: "User ID", width:"120px",sortable:true, sortingOrder: ["asc", "desc"],
      sort: "desc"},
      { field: "User Name",width:"100px" },
      { field: "Email",width:"100px" },
      { field: "Local Amount",width:"150px" },
      { field: "Brand Amount",width:"150px" },
      { field: "System Amount",width:"150px" },
      { field: "Payment Provider" ,width:"150px"},
      { field: "Parent Affiliate" ,width:"150px"},
      { field: "Payment Channel" ,width:"150px"},
      { field: "Transaction ID" ,width:"150px"},
      { field: "Deposit Date" ,width:"150px"},
      { field: "Registration Date" ,width:"150px"},
      { field: "Registration IP",width:"150px" },

    ]);
    let options = [
  
        {
          id: 1,
          value: 50,
        },
        {
          id: 1,
          value: 100,
        },
      ];
    return (
      <div className="deposit-table">
      <div className="top-area">
         <h6>First Depositor Statistics</h6>
       <div className="right-area">
       <ExportExcel />
        <SelectInput listArray={options} defaultOption="20" />
        <TableColumns tableData={columnDefs}/>
       </div>
      </div>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
    )
  }

  export default FirstDepositTable