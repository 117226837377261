import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";

const Filter = ({ activeTab }) => {
  const currencyOptions = [{ id: 1, value: "AED" }];

  const renderSelectInput = (label, name) => (
    <SelectInput defaultOption={label} listArray={currencyOptions} />
  );

  const renderFloatingLabelInput = (label, name, disabled = false) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type="text"
        placeholder="name@example.com"
        autoComplete="off"
        name={name}
        disabled={disabled}
      />
    </FloatingLabel>
  );

  return (
    <div>
      <div className="filter-section mb-filter">
        {renderSelectInput("Type")}
        {renderSelectInput("Vendors")}
        {renderFloatingLabelInput("Game ID", "gameID")}
        {renderFloatingLabelInput("Affiliate ID", "affiliateID")}
        {renderFloatingLabelInput("User ID", "userID")}
        {renderSelectInput("User Group")}
      </div>
      <div className="filter-section ">
        {renderSelectInput("Default Currency")}
        {renderFloatingLabelInput("Choose Currency", "chooseCurrency", true)}
        <ButtonWrapper text="Search" />
        <ButtonWrapper text="Clear All" clValue="clear-btn" />
      </div>
    </div>
  );
};

export default Filter;
