import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import { getAllVouchers } from "../../utils/Apis";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";

const VoucherTable = ({ recall, filter }) => {
  const [rowData, setRowData] = useState([]);
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState("");
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "ID",
      width: 100,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellStyle: { "text-align": "left" },
    },
    {
      field: "couponcode",
      headerName: "Code",
      width: 150,
      // cellStyle: { "text-align": "left" },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      // cellStyle: { "text-align": "center"  },
      // type: 'center'
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 150,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "creatorName",
      headerName: "Creator",
      width: 250,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "createdTime",
      headerName: "Create Time",
      width: 150,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "expireDate",
      headerName: "Expiration Date",
      width: 150,
      // cellStyle: { "text-align": "center" },
      // type:"center"
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 150,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "creatorId",
      headerName: "User Id",
      width: 100,
      // cellStyle: { "text-align": "center" },
    },
    {
      field: "status",
      headerName: "Active",
      width: 150,
      // cellStyle: { "text-align": "center" },
    },
  ]);
  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
  ];

  const handleGetPaymentProviderData = async (page, limit) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            limit: limit,
          },
          body: filter,
        };
        setRowData([]);
        setLoading(true);
        const res = await getAllVouchers(config);

        if (res) {
          setLoading(false);
          if (res && res.data && res.data.data) {
            const transformedData = res.data?.data.map((item) => ({
              ...item,
              status: item.status === "Active" ? "Yes" : "No",
              days: item?.days ? item?.days : "---",
              creatorId: item?.creatorId ? item?.creatorId : "---",
              brand: item?.brand ? item?.brand : "---",
              creatorName: item?.creatorName ? item?.creatorName : "---",
              createdAt: item?.createdAt ? item?.createdAt : "---",
              expireDate: item?.expireDate ? item?.expireDate : "---",
              createdTime: item?.createdTime ? item?.createdTime : "---",
              currency: item?.currency ? item?.currency : "---",
              amount: item?.amount ? item?.amount : "---",
              couponcode: item?.couponcode ? item?.couponcode : "---",
              id: item?.id ? item?.id : "---",
            }));
            setPages(res.data.pages);
            setRowData(transformedData);
          }
        }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };
  useEffect(() => {
    handleGetPaymentProviderData(page, limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recall, page, limit, isLoading]);

  const handleTableCheckBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Vouchers</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            selectedValue={limit}
            handleSelectOption={handleLimit}
          />
          <TableColumns
            tableData={tableData}
            checkedBox={handleTableCheckBox}
          />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
export default VoucherTable;
