import { FloatingLabel, Form } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SwitchBox from "../../global/switchBox";
import ExportExcel from "../../global/exportExcel";
import ToggleSwitch from "../../global/toggleSwitch";
import SelectInput from "../../global/selectInput";

const Filter = ({ activeTab }) => {
  const currencyOptions = [
    { id: 1, value: "AED" },
    { id: 2, value: "AFN" },
    { id: 3, value: "ALL" },
    { id: 4, value: "AMD" },
    { id: 5, value: "ANG" },
    { id: 6, value: "AOA" },
    { id: 7, value: "ARS" },
    { id: 8, value: "AUD" },
  ];
  return (
    <div className="voucher-filter translation-filter">
      {activeTab === "Add" && (
        <div className="filter-section notification-popup filter-pd justify-content-center filter-section-left ">
          <FloatingLabel
            controlId="floatingInput"
            label="Add Translation Group"
          >
            <Form.Control
              type="text"
              placeholder=""
              autoComplete="off"
              name="userid"
            />
          </FloatingLabel>

          <ButtonWrapper text={"Search"} clValue="search-filter-btn" />

          <FloatingLabel controlId="floatingInput" label="Add Translation Key">
            <Form.Control
              type="text"
              placeholder=""
              autoComplete="off"
              name="userid"
            />
          </FloatingLabel>

          <ButtonWrapper text={"Search"} clValue="search-filter-btn" />
        </div>
      )}
      <div className="filter-section notification-popup filter-pd switchbox-para">
        <FloatingLabel controlId="floatingInput" label="Search Phrase" className="ml-0">
          <Form.Control
            type="text"
            placeholder=""
            autoComplete="off"
            name="userid"
          />
        </FloatingLabel>

        <SwitchBox label={"Include Keys"} />


        <SwitchBox label={"Include Translations"} />
   
      {/* </div> */}
      {/* <div className="filter-section notification-popup"> */}
        <div className="switch-box switch-with-select">
          <span>Show Not Translations</span>
          <div className="switch-select">
            <SelectInput defaultOption={"All"} listArray={currencyOptions} />
            <ToggleSwitch />
          </div>
        </div>
        <div className="filter-end">
          <ExportExcel />
        </div>
      </div>
    </div>
  );
};
export default Filter;
