import Layout from "../layout/layout";
import BoxComponent from "./boxComponent";
import WinnerLosers from "./winnerLoser";
import DepositWithdraw from "./depositWithdraw";
import ChartComponent from "./chart";
import GameStats from "./gameStats";
import DatePicker from "../global/datePicker";
import { useEffect, useState } from "react";
import HorizontalTabs from "../global/horizontalTab";
import "./index.css";
import { useMyContect } from "../../context/myContext";
import SelectInput from "../global/selectInput";
import { Button, Modal } from "react-bootstrap";
import { generateDomainToken, getAllDomainName } from "../../utils/Apis";

const tabs = ["Today", "Yesterday", "Past Week", "Current Month", "Last Month"];

const Dashboard = () => {
  const { isLogin, setIsLogin, setBrand, isLoading, setIsLoading } =
    useMyContect();
  const [activeTab, setActiveTab] = useState("Current Month");
  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState("");
  const [error, setError] = useState(false);

  const handleTabs = (value) => {
    setActiveTab(value);
    setIsLoading(false);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
  };

  const getDomainName = async () => {
    const response = await getAllDomainName();
    // console.log("res", response);
    if (response?.data?.status === 200) {
      console.log(response.data?.domianData);
      const transformData = response?.data?.domianData.map((item, index) => ({
        value: item.allDomain,
        id: 0,
      }));
      setDomains(transformData);
    }
  };

  const handleClick = async () => {
    if (!domain) {
      setError(true);
      return;
    }

    localStorage.setItem("brand", domain);
    setBrand(domain);
    setIsLogin(false);
    const response = await generateDomainToken(domain);
    setIsLoading(false);
    if (response?.status === 200) {
      localStorage.setItem("secretDomainKey", response.tokenValue);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("brand")) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(true);
    }

    getDomainName();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout title="Dashboard">
      <div className="dashboard-page">
        <div className="tabs-section">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />

            <div className="date-filter">
              <DatePicker />
            </div>
          </div>
          <div className="top-dashboard-section top-dashboard-pd">
            <BoxComponent isLoading={isLoading} />
            <ChartComponent isLoading={isLoading} />
          </div>
          <WinnerLosers isLoading={isLoading} />
          <div className="bottom-dashboard-section">
            <DepositWithdraw isLoading={isLoading} />
            <GameStats isLoading={isLoading} />
          </div>
        </div>
      </div>
      <Modal
        show={isLogin || !localStorage.getItem("brand")}
        // onHide={handleClose}
        centered
        className={`notification-popup add-payment-popup add-group-limit-popup add-global-limit-popup`}
        >
        <h2 className="text-center m-2 p-2">Please Choose A Brand</h2>
        <Modal.Body  className="initial-modal">
          <SelectInput
            isRequired={true}
            defaultOption={"Select Brand"}
            listArray={domains}
            name="selectDomain"
            selectedValue={domain}
            handleSelectOption={(e) => {
              setDomain(e.target.value);
              // setIsLoading(false)
            }}
          />
          {error && <div className="text-danger">Please Select The brand</div>}
          <div className="d-flex justify-content-center">
          <Button
            className="btn btn-green align-center text-center m-2 "
            onClick={handleClick}
          >
            Ok
          </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};
export default Dashboard;
