
import GlobalTable from "../global/globalTable";
import { useState } from "react";

const DepositWithdraw = () => {
  const [rowData] = useState([
  ]);
  const [columnDefs] = useState([
    { field: "Provider",width:"110px" },
    { field: "Channel",width:"110px"  },
    { field: "Deposit Amount",width:"150px"  },
    { field: "Deposit Count",width:"120px"  },
    { field: "Withdraw Amount",width:"150px"  },
    { field: "Withdraw Count",width:"150px"  },
  ]);

  return (
    <div className="deposit-table deposit-widthdraw-table">
      <h2>Deposit/Withdraw</h2>
      <GlobalTable columnDefs={columnDefs} rowData={rowData} />
    </div>
  );


};
export default DepositWithdraw;
