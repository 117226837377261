import { useEffect, useState } from "react";
import GlobalTable from "../../global/globalTable";
import { getAllPopups } from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import Pagination from "../../../utils/Pagination";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Modal } from "react-bootstrap";

const PopupsTable = ({ columnDefs, recall }) => {
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const pageSize = 10;
  const handleFetch = async (page) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setRowData([]);
        setLoading(true);
        const res = await getAllPopups(config);
        if (res) {
          if (res && res.data && res.data.data) {
            const transformedData = res.data.data.map((item) => ({
              id: item.id,
              language: item.language,
              intervalhours: item.intervalhours,
              image: item.image,
              detailedText: item.detailedText,
            }));
            setLoading(false);
            setRowData(transformedData);
            setPages(res?.data?.pages);
          }
        }
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch(page);
  }, [page, isLoading, recall]);
  return (
    <div className="deposit-table">
      {loading || isLoading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs.map((colDef) =>
              colDef.field === "image"
                ? { ...colDef, cellRenderer: RenderImage }
                : colDef
            )}
            rowData={rowData}
            pageCss={"aghader"}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
const RenderImage = (params) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const handleDownload = () => {
    const splitArr = params?.value.split("/");
    const fileName = splitArr[splitArr.length - 1];
    fetch(params?.value)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      <img
        className="table-image"
        role="button"
        onClick={handleClick}
        src={params.value}
        alt="Social Icon"
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        centered
        // className="notification-popup add-payment-popup add-commission-popup"
      >
        <Modal.Body>
          {/* {console.log("image value>>>>", params?.value)} */}
          <img
            className="image-preview"
            src={params?.value}
            alt="Social Icon"
          />
          <div className="text-center mt-2">
            {/* Add a download button */}
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                handleDownload();
              }}
            >
              Download <CloudDownloadIcon />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PopupsTable;
