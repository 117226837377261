import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { navItemsArr } from "../../utils/util";
import GlobalInput from "../global/input";
import "./navbar.css";
import { useMyContect } from "../../context/myContext";

const Navbar = () => {
  const { user  } = useMyContect();

  const [showDropdown, setShowDropdown] = useState(
    localStorage.getItem("dropdown")
  );

  const handleDropdown = (value) => {
    localStorage.setItem("dropdown", value);
    if (value === showDropdown) {
      setShowDropdown("");
    } else if (value) {
      setShowDropdown(value);
    }
  };
  let activeUrl = window.location.pathname;

  useEffect(() => {
    // This will scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // You can also listen to the 'scroll' event and save the scroll position in localStorage
    const handleScroll = () => {
      localStorage.setItem("scrollPosition", window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const filteredMenuItems = navItemsArr
    .map((item) => {
      if (item.text === "Admin Management") {
        return user?.role === "superAdmin" ? item : null;
      }
      return item;
    })
    .filter(Boolean);

  return (
    <div className="sidebar">
      <div className="logo">Jackbit</div>
      <div className="menu-item">
        <div className="search-area">
          <GlobalInput
            placeholder={"Search..."}
            value="Metabet777"
            type={"text"}
          />
          <i className="las la-angle-down"></i>
        </div>
        <ul>
          {filteredMenuItems?.map((el, i) => {
            return (
              <React.Fragment key={i}>
                {el?.subMenu ? (
                  <li
                    className={`sub ${
                      showDropdown === el?.text ? "open-list" : ""
                    }`}
                  >
                    <div
                      className="submenu-item"
                      onClick={() => handleDropdown(el?.text)}
                      role="presentation"
                    >
                      {el?.icon} {el?.text}
                      <div className="down-arrow">
                        {showDropdown === el?.text ?
                        <i className="las la-angle-up"></i>:
                        <i className="las la-angle-down"></i>}
                      </div>
                    </div>
                    <ul>
                      {el.subMenu.map((ele, i) => (
                        <Link
                          to={ele?.url}
                          className={`${
                            activeUrl === ele?.url ? "active-item" : ""
                          }`}
                          key={i}
                        >
                          <li>
                            <i className="las la-circle"></i> {ele?.text}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <Link
                    to={el?.url}
                    className={`${activeUrl === el?.url ? "active-item" : ""}`}
                  >
                    <li>
                      {el?.icon} {el?.text}
                    </li>
                  </Link>
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default Navbar;
