import { Modal } from "react-bootstrap";
import ButtonWrapper from "../../global/button";
import SelectInput from "../../global/selectInput";
import SwitchBox from "../../global/switchBox";
import FilterInput from "../../global/filterInput";

const AddNewQuestionnairePopup = ({ show, handleClose }) => {
  let list = [
    {
      id: 1,
      value: "AED",
    },
  ];
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className={`notification-popup kyc-popup add-payment-popup`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Questionnaire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row-one">
          <FilterInput label="Questionnaire Name" name={"QuestionnaireName"}/>
          <FilterInput label="order" name={"order"}/>
     
          <SelectInput
            defaultOption={"Automatically Verifiable"}
            listArray={list}
          />
          <SwitchBox label={"Mandatory"} />
        </div>
 
        <div className="filter-section register-affiliate-btn justify-content-end">
          <ButtonWrapper text={"Add"} clValue="search-filter-btn" />
          <ButtonWrapper text="Clear All" clValue="clear-btn" />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddNewQuestionnairePopup;
