import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import moment from "moment-timezone";
const FilterDatePicker = ({ label, name, value, handleDateChange }) => {
  const [startDate, setStartDate] = useState();
  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };
  const formatDateTime = (dateString) => {
    // console.log("dateString", dateString);

    // const dateObject = new Date(dateString);
    // console.log("dateObject", dateObject);
    // const formattedLocaleDate = dateObject.toLocaleDateString();
    // console.log("formattedLocaleDate", formattedLocaleDate);

    const momentDate = moment(dateString).format("MM/DD/YYYY");
    console.log("momentDate", momentDate);
    // const mformattedDate = momentDate.format("L");
    // console.log("formattedDate With moment", mformattedDate);

    return momentDate;
  };
  useEffect(() => {
    if (value) {
      const formattedDateTime = formatDateTime(value);
      console.log(formattedDateTime);
      setStartDate(formattedDateTime);
    } else {
      setStartDate("");
    }
  }, [value]);
  const handleChange = (data) => {
    console.log(data);
    const formattedDateTime = formatDateTime(data);
    setStartDate(formattedDateTime);
    handleDateChange(name, formattedDateTime);
  };
  const range = (start, end, step) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };
  const getYear = (date) => date.getFullYear();
  const years = range(1990, getYear(new Date()) + 1, 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getMonth = (date) => date.getMonth();
  return (
    <div className="form-floating filter-date-picker">
      <DatePicker
        // className="form-control datePickerInput"
        popperClassName="some-custom-class"
        // popperPlacement="top-end"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [5, 10],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              display: "flex",
              justifyContent: "space-between",
            }}
            className="datepicker-filter header-datepicker"
          >
            <button
              className="arrow-btn"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <ArrowLeftIcon />
            </button>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              className="month-select"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              className="month-select"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              className="arrow-btn"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <ArrowRightIcon />
            </button>
          </div>
        )}
        // showTimeSelect
        onChange={handleChange}
        timeClassName={handleColor}
        customInput={
          <FloatingLabel controlId="floatingInput" label={label}>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              autoComplete="off"
              name={name}
              defaultValue={value ? startDate : ""}
              readOnly
              // value={value ? startDate : ""}
            />
          </FloatingLabel>
        }
      />
    </div>
  );
};

export default FilterDatePicker;
