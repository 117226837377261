import Layout from "../../layout/layout";
import DatePicker from "../../global/datePicker";
import { useState } from "react";
import Filter from "./filter";
import StatisticsTable from "./statisticsTable";
import HorizontalTabs from "../../global/horizontalTab";

const tabs = ["Today", "Yesterday", "Past Week", "Current Month", "Last Month"];

const UserGameStatistics = () => {
  const [activeTab, setActiveTab] = useState("Today");

  const handleTabs = (value) => {
    setActiveTab(value);
  };
  return (
    <Layout title="USER GAME STATISTICS">
      <div className="notification-page  report-pages">
        <div className="tabs-section notification-tabs">
          <div className="horizontal-tabs">
            <HorizontalTabs
              activeTab={activeTab}
              handleTabs={handleTabs}
              tabs={tabs}
            />
            <div className="date-filter">
            <DatePicker />
            </div>
          </div>
          <Filter />
        </div>
        <StatisticsTable />
      </div>
    </Layout>
  );
};
export default UserGameStatistics;
