import Layout from "../layout/layout";
import DepositTable from "./depositTable";
import "./order.css";
import WithdrawTable from "./withdrawTable";

const Orders = () => {
  return (
    <Layout title="Orders">
      <div className="users-page order-page">
        <div className="deposit-sectiom">
          <DepositTable />
        </div>
        <div className="withdraw-section">
          <WithdrawTable />
        </div>
      </div>
    </Layout>
  );
};
export default Orders;
