import { useEffect, useState } from "react";
import GlobalTable from "../../global/globalTable";
import ToggleSwitch from "../../global/toggleSwitch";
import {
  getAllNews,
  getAllPromotions,
  getAllPromotionsClass,
  getTestimonials,
} from "../../../utils/Apis";
import { useMyContect } from "../../../context/myContext";
import Pagination from "../../../utils/Pagination";
import { Modal } from "react-bootstrap";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const SiteContenttable = ({ activeTab, columnDefs, setSitepopup, recall }) => {
  const [rowData, setRowData] = useState([]);
  const { isLoading } = useMyContect();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState("");
  const pageSize = 10;
  const handleFetch = async (page) => {
    try {
      const token = localStorage.getItem("secretDomainKey");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
        };
        setLoading(true);
        if (activeTab === "Testimonials") {
          const res = await getTestimonials(config);
          console.log(res.data.data);
          if (res) {
            if (res && res.data && res.data.data) {
              const transformedData = res.data.data.map((item) => ({
                id: item.id || "---",
                Position: item.position || "---",
                "Testimonial Name": item.testimonialsName || "---",
                Language: item.language || "---",
                Company: item.company || "---",
                "Testimonial Text": item.testimonialsText || "---",
              }));
              setRowData(transformedData);
              setPages(res?.data?.pages);
            }
          }
        } else if (activeTab === "News") {
          setRowData([]);
          const res = await getAllNews(config);
          if (res) {
            if (res && res.data && res.data.data) {
              const transformedData = res.data.data.map((item) => ({
                id: item.id,
                Title: item.title,
                Slug: item.slug,
                "Seo Title": item.seoTitle,
                "Meta Description": item.metaDescription,
                "Small Image": item.smallImage,
                "Large Image": item.largeImage,
                "Short Description": item.shortDescription,
                "Detailed Text": item.detailedText,
              }));
              setRowData(transformedData);
              setPages(res?.data?.pages);
            }
          }
        } else if (activeTab === "Promotions") {
          setRowData([]);
          const res = await getAllPromotions(config);
          if (res) {
            if (res && res.data && res.data.data) {
              const transformedData = res.data.data.map((item) => ({
                id: item.id,
                language: item.language,
                Class: item.class,
                position: item.position,
                name: item.name,
                "Small Image": item.smallImage,
                "Large Image": item.largeImage,
                bannerAlt: item.bannerAlt,
                title: item.title,
                fullTitle: item.fullTitle,
                detailedText: item.detailedText,
              }));
              setRowData(transformedData);
              setPages(res?.data?.pages);
            }
          }
        } else if (activeTab === "Create Promotions Class") {
          setRowData([]);
          const res = await getAllPromotionsClass(config);

          if (res) {
            if (res && res.data && res.data.data) {
              const transformedData = res.data.data.map((item) => ({
                id: item.id,
                name: item.name,
              }));
              console.log(transformedData);
              setRowData(transformedData);
              setPages(res?.data?.pages);
            }
          }
        }
        setLoading(false);
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch(page);
    // eslint-disable-next-line
  }, [page, isLoading, activeTab, recall]);

  return (
    <div className="deposit-table config-table">
      <div className="top-area">
        <h6>
          {activeTab === "Create Promotions Class"
            ? "Promotions Class"
            : activeTab}
        </h6>
        {activeTab === "Create Promotions Class" ? (
          " "
        ) : (
          <div className="toggle-switch">
            <p>Active</p>
            <ToggleSwitch />
          </div>
        )}
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable
            columnDefs={columnDefs.map((colDef) =>
              colDef.field === "Small Image" || colDef.field === "Large Image"
                ? { ...colDef, cellRenderer: RenderImage }
                : colDef
            )}
            rowData={rowData}
            pageCss={"aghader"}
            setSitepopup={setSitepopup}
          />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};

const RenderImage = (params) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const handleDownload = () => {
    const splitArr = params?.value.split("/");
    const fileName = splitArr[splitArr.length - 1];
    fetch(params?.value)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setShow(false);
      })
      .catch((error) => console.error("Error downloading image:", error));
  };

  return (
    <>
      <img
        className="table-image"
        role="button"
        onClick={handleClick}
        src={params.value}
        alt="Social Icon"
      />
      <Modal
        show={show}
        onHide={() => setShow(!show)}
        centered
        // className="notification-popup add-payment-popup add-commission-popup"
      >
        <Modal.Body>
          {/* {console.log("image value>>>>", params?.value)} */}
          <img
            className="image-preview"
            src={params?.value}
            alt="Social Icon"
          />
          <div className="text-center mt-2">
            {/* Add a download button */}
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                handleDownload();
              }}
            >
              Download <CloudDownloadIcon />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SiteContenttable;
