import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import SelectInput from "../../global/selectInput";
import ButtonWrapper from "../../global/button";

const Filter = ({ activeTab }) => {
  const CurrencyOptions = [{ id: 1, value: "AED" }];

  const renderInput = (label, name, disable) => (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type="text"
        placeholder="Please input here"
        autoComplete="off"
        name={name}
        disabled={disable}
      />
    </FloatingLabel>
  );

  return (
    <div
      className={`datepicker filter-Tab ${
        activeTab === "Notifications" ? "" : "active-tab-noti"
      }`}
    >
      <div className="filter-section filter-pd">
        <SelectInput defaultOption="Type" listArray={CurrencyOptions} />
        {renderInput("User ID", "userId", false)}
        <SelectInput defaultOption="User Group" listArray={CurrencyOptions} />
        <SelectInput defaultOption="Country" listArray={CurrencyOptions} />
        {renderInput("Affiliate ID", "affiliateID", false)}
        <SelectInput
          defaultOption="Default Currency"
          listArray={CurrencyOptions}
        />
        {renderInput("Choose Currency", "chooseCurrency", true)}
        <ButtonWrapper text="filter" />
      </div>
    </div>
  );
};

export default Filter;
